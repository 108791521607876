import {ApolloError, ServerError} from "@apollo/client";
import _ from "lodash";
import {createContext, useEffect, useState} from "react";
import {
  Manifest,
  ManifestFilter,
  ManifestSortInput,
  SearchableSortDirection,
  useSearchManifestsQuery
} from "../../generated/graphql";
import {Constants} from "../common/Constants";
import {GraphQLErrors} from "@apollo/client/errors";

type ManifestDataProviderProps = {
  children: JSX.Element[] | JSX.Element;
  filter?: ManifestFilter;
  sort?: ManifestSortInput;
};

type ManifestDataState = {
  data: Manifest[] | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  total: number;
  page: number;
  pageSize: number;
  pageCount: number;
  setPage(page: number): void;
  setPageSize?: (pageSize: number) => void;
  hasBadData: boolean;
};

const initialManifestDataState = {
  data: undefined,
  loading: true,
  error: undefined,
  total: 0,
  page: 0,
  pageSize: Constants.DEFAULT_MANIFEST_PAGE_SIZE,
  pageCount: 1,
  setPage: () => {
    /*NOOP*/
  },
  hasBadData: false
};

export const getArrayOfErrorIndexes = (errors: GraphQLErrors | undefined) => {
  return errors
    ? errors.map((error) => {
        return Number(error.path?.at(2));
      })
    : [];
};

export const filterNonErrManifests = (manifests: Manifest[], indexes: number[]) => {
  if (indexes.length === 0) return manifests;
  return manifests.filter((_, index) => !indexes.includes(index));
};

const ManifestDataContext = createContext<ManifestDataState>(initialManifestDataState);

const ManifestDataProvider = ({children, filter, sort}: ManifestDataProviderProps) => {
  const [manifestData, setManifestData] = useState<Manifest[]>([]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(Constants.DEFAULT_MANIFEST_PAGE_SIZE);
  const [totalRows, setTotalRows] = useState(0);
  const [manifestsLoading, setManifestsLoading] = useState(false);

  const [manifestSort, setManifestSort] = useState<ManifestSortInput | undefined>(sort);
  const [manifestFilter, setManifestFilter] = useState<ManifestFilter | undefined>(filter);
  const [hasBadData, setHasBadData] = useState(false);
  const [state, setState] = useState<ManifestDataState>({
    ...initialManifestDataState
  });

  const manifestResult = useSearchManifestsQuery({
    variables: {
      filter: filter,
      sort: [
        manifestSort ?? {},
        {
          field: "manifestDate",
          direction: SearchableSortDirection.Asc
        },
        {
          field: "manifestDriverId",
          direction: SearchableSortDirection.Asc
        }
      ],
      offset: page * pageSize,
      limit: pageSize
    },
    fetchPolicy: "network-only",
    pollInterval: Constants.MANIFEST_POLLING_INTERVAL,
    errorPolicy: "all"
  });

  useEffect(() => {
    if (JSON.stringify(filter) !== JSON.stringify(manifestFilter)) {
      setManifestFilter(filter);
      setPage(0);
      setTotalRows(0);
    }
  }, [filter, manifestFilter]);

  useEffect(() => {
    if (JSON.stringify(sort) !== JSON.stringify(manifestSort)) {
      setManifestSort(sort);
      setPage(0);
      setTotalRows(0);
    }
  }, [sort, manifestSort]);

  useEffect(() => {
    if (page + 1 > Math.ceil(totalRows / pageSize)) {
      setPage(0);
    }
  }, [page, pageSize, totalRows]);

  useEffect(() => {
    setManifestsLoading(manifestResult.loading);
    if (manifestResult.loading) return;

    if (manifestResult.data?.searchManifests) {
      console.debug("[DEBUG] - ManifestDataContext loaded", manifestResult.data);
      const hasBadData =
        manifestResult.error?.graphQLErrors.some((error) =>
          Constants.REGEX.BAD_DATA_RESPONSE_REGEX.test(error.message)
        ) ?? false;
      setHasBadData(hasBadData);

      let newManifestData;
      if (hasBadData) {
        newManifestData = filterNonErrManifests(
          manifestResult.data.searchManifests.items as Manifest[],
          getArrayOfErrorIndexes(manifestResult.error?.graphQLErrors)
        );
      } else {
        newManifestData = manifestResult.data.searchManifests.items as Manifest[];
      }

      if (!_.isEqual(manifestData, newManifestData)) {
        setManifestData(newManifestData);
      }

      const total = manifestResult.data.searchManifests.total ?? 0;
      if (totalRows !== total) {
        setTotalRows(total);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manifestResult.data, manifestResult.loading]);

  useEffect(() => {
    if (manifestResult.error) {
      console.error("[ERROR] - ManifestDataContext", {
        message: manifestResult.error.message,
        hasData: manifestResult.data !== undefined,
        networkStatus: manifestResult.networkStatus,
        raw: JSON.stringify(manifestResult.error)
      });

      //This will rety at the ApolloLink level
      if (manifestResult.error.networkError && (manifestResult.error.networkError as ServerError).statusCode !== 401) {
        setState({
          ...initialManifestDataState,
          loading: manifestResult.loading,
          error: manifestResult.error
        });
      }
    }
  }, [manifestResult.data, manifestResult.error, manifestResult.loading, manifestResult.networkStatus]);

  useEffect(() => {
    const updatedState = {
      ...initialManifestDataState,
      loading: manifestsLoading,
      data: manifestData,
      page: page,
      pageSize: pageSize,
      pageCount: Math.ceil(totalRows / pageSize),
      total: totalRows,
      setPage: setPage,
      setPageSize: setPageSize,
      hasBadData: hasBadData
    };
    console.debug("[DEBUG] - ManifestDataContext updated", updatedState);
    setState(updatedState);
  }, [manifestData, totalRows, pageSize, manifestsLoading, page, hasBadData]);

  return <ManifestDataContext.Provider value={state}>{children}</ManifestDataContext.Provider>;
};

export default ManifestDataProvider;
export {ManifestDataContext};
export type {ManifestDataState};
