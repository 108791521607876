import {ApolloError} from "@apollo/client";
import {AgGridReact} from "@ag-grid-community/react";
import {RefObject, useEffect} from "react";

export const useGridRefreshOnInterval = (
  soure: string,
  gridRef: RefObject<AgGridReact>,
  gridReady: boolean,
  datasourceFailed: ApolloError | undefined,
  intervalMs?: number
) => {
  useEffect(() => {
    const refreshInterval = intervalMs ? intervalMs : 15000;
    const interval = setInterval(() => {
      if (gridReady && gridRef.current?.api.getSelectedRows().length === 0) {
        if (!datasourceFailed) {
          console.debug(`${soure} Grid - Refresh on interval`);
          gridRef.current?.api.refreshServerSide();
        }
      } else {
        console.log(`${soure} Grid - Selected rows. Not refreshing on interval`);
      }
    }, refreshInterval);
    return () => {
      console.debug(`clear interval ${soure}`);
      clearInterval(interval);
    };
  }, [datasourceFailed, gridReady, gridRef, intervalMs, soure]);
};
