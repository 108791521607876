import styled from "@emotion/styled";
import React from "react";
import backgroundImg from "../assets/background.png";

const InitContent = () => {
  return (
    <Container>
      <img alt="background-img" className="background-img" src={backgroundImg} draggable={false} />
      <div className="text-content">
        <b>Global Find</b>
        <p>
          You can search for a manifest by typing in the route number, driver number, or job number. It will find
          manifests that match across all stations.
        </p>
      </div>
    </Container>
  );
};

export default InitContent;

const Container = styled.div`
  position: relative;
  padding: 0 16px;
  height: 220px;

  .background-img {
    width: 100%;
  }
  .text-content {
    margin-top: -40px;
    text-align: center;

    b {
      font-size: 14px;
    }

    p {
      margin-top: 8px;
      font-size: 13px;
      color: #9ca3af;
    }
  }
`;
