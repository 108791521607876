import {Button, Intent} from "@blueprintjs/core";
import {DateRange, DateRangeShortcut} from "@blueprintjs/datetime";
import {DateInput2, DateRangeInput2} from "@blueprintjs/datetime2";
import styled from "@emotion/styled";
import {IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterParams} from "@ag-grid-community/core";
import {addDays, endOfDay, startOfDay, subDays} from "date-fns";
import {format, toDate} from "date-fns-tz";
import {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from "react";
import {isValidDateTime} from "../../utils/DateUtils";
import {getArrayFromField} from "../../utils/General";
import {getStopDateTimeByType, StopDateTimeType} from "./cell-renderers/TimeCellRenderer";

const getDefaultShortcuts = () => {
  const today = new Date();
  return [
    {label: "Today", dateRange: [startOfDay(today), endOfDay(today)]},
    {label: "Yesterday", dateRange: [startOfDay(subDays(today, 1)), endOfDay(subDays(today, 1))]},
    {label: "Last 7 days", dateRange: [startOfDay(subDays(today, 6)), endOfDay(today)]},
    {label: "Last 14 days", dateRange: [startOfDay(subDays(today, 13)), endOfDay(today)]},
    {label: "Tomorrow", dateRange: [startOfDay(addDays(today, 1)), endOfDay(addDays(today, 1))]},
    {label: "Next 2 days", dateRange: [startOfDay(addDays(today, 1)), endOfDay(addDays(today, 2))]},
    {label: "Next 7 days", dateRange: [startOfDay(addDays(today, 1)), endOfDay(addDays(today, 7))]},
    {label: "Next 14 days", dateRange: [startOfDay(addDays(today, 1)), endOfDay(addDays(today, 14))]}
  ] as DateRangeShortcut[];
};

const DefaultShortcutsTitles = getDefaultShortcuts().map((item) => item.label);

export const getDateRangeByTitle = (title: string) => {
  return getDefaultShortcuts().find((item) => item.label === title)?.dateRange as DateRange;
};

const Title = styled.div`
  font-weight: bold;
  padding: 0px 0px 10px 5px;
`;

const Footer = styled.div`
  padding-top: 10px;
`;

const DateInputContainer = styled.div``;

type DateFilterType = "single" | "range";
// eslint-disable-next-line react/display-name
export default forwardRef((props: IFilterParams, ref) => {
  const [init, setInit] = useState<boolean>(false);
  const [dateValue, setDateValue] = useState<string>("");
  const [range, setRange] = useState<DateRange>([null, null]);
  const [selectedShortcut, setSelectedShortcut] = useState<string | undefined>(undefined);
  const [shortcuts, setShortcuts] = useState<DateRangeShortcut[]>([]);

  const [afterGuiAttachedParams, setAfterGuiAttachedParams] = useState<IAfterGuiAttachedParams>();

  const dateRangeShortcuts: DateRangeShortcut[] = props.colDef.filterParams.dateRangeShortcuts;

  const isClientSideRangeFilter = props.colDef.filterParams.isClientSideRangeFilter;

  const getShortcuts = useCallback(() => {
    if (dateRangeShortcuts) {
      return dateRangeShortcuts;
    }
    return getDefaultShortcuts();
  }, [dateRangeShortcuts]);

  const getFilterType = () => {
    return props.colDef.filterParams.type as DateFilterType;
  };

  const getTypeOfDateTime = (): StopDateTimeType => {
    return props.colDef.filterParams.typeOfDateTime;
  };

  const onChange = (event: any) => {
    if (getFilterType() === "single") {
      setDateValue(event);
    } else {
      setRange([startOfDay(event[0]), endOfDay(event[1])]);
    }
  };

  const onApply = () => {
    if (getFilterType() === "range" && !getArrayFromField(range).every(isValidDateTime)) {
      return;
    }
    props.filterChangedCallback();
    afterGuiAttachedParams?.hidePopup?.();
  };

  const onClear = () => {
    if (getFilterType() === "single") {
      setDateValue("");
    } else {
      setSelectedShortcut(undefined);
      setRange([null, null]);
    }
    afterGuiAttachedParams?.hidePopup?.();
  };

  const handleShortcutsClicked = useCallback((e) => {
    if (DefaultShortcutsTitles.includes(e?.target?.innerText)) {
      setSelectedShortcut(e.target.innerText);
    }
  }, []);

  const handleDayClicked = useCallback(() => {
    setSelectedShortcut(undefined);
  }, []);

  const handlePopoverOpening = useCallback(() => {
    setShortcuts(getShortcuts());
  }, [getShortcuts]);

  const handlePopoverOpened = useCallback(
    (container: HTMLElement) => {
      const shorcutEls = container.querySelectorAll(".bp4-menu-item");
      shorcutEls.forEach((node) => {
        if ((node as HTMLAnchorElement).innerText === selectedShortcut) {
          node.classList.add("bp4-active", "bp4-selected");
        } else {
          node.classList.remove("bp4-active", "bp4-selected");
        }
      });
    },
    [selectedShortcut]
  );

  useImperativeHandle(ref, () => {
    setInit(true);
    return {
      doesFilterPass(params: IDoesFilterPassParams) {
        if (isClientSideRangeFilter && getFilterType() === "range") {
          const stopTime = getStopDateTimeByType(params.data, getTypeOfDateTime());
          if (!stopTime) return false;
          return range[0]!.getTime() <= stopTime.getTime() && stopTime.getTime() <= range[1]!.getTime();
        } else {
          return getFilterType() === "single" ? dateValue !== null : !range?.includes(null);
        }
      },

      isFilterActive() {
        return getFilterType() === "single" ? dateValue !== "" : !range?.includes(null);
      },

      getModel() {
        if (!this.isFilterActive()) {
          return null;
        }
        const model = {filterType: "date"};
        if (isClientSideRangeFilter && getFilterType() === "range") {
          if (selectedShortcut) {
            const shortcutRange = getDateRangeByTitle(selectedShortcut);
            return {
              ...model,
              dateFrom: shortcutRange?.[0] ? format(shortcutRange[0], "yyyy-MM-dd 00:00:00") : null,
              dateTo: shortcutRange?.[1] ? format(shortcutRange[1], "yyyy-MM-dd 23:59:59") : null,
              type: "inRange",
              value: selectedShortcut
            };
          }
          const dateFrom = range?.[0] ? format(range[0], "yyyy-MM-dd 00:00:00") : null;
          const dateTo = range?.[1] ? format(range[1], "yyyy-MM-dd 23:59:59") : null;
          return {
            ...model,
            dateFrom,
            dateTo,
            type: "inRange"
          };
        } else {
          if (getFilterType() === "single") {
            return {...model, type: "equals", value: dateValue};
          } else if (getFilterType() === "range") {
            if (selectedShortcut) {
              const shortcutRange = getDateRangeByTitle(selectedShortcut);
              return {
                ...model,
                type: "inRange",
                filter: shortcutRange?.[0],
                filterTo: shortcutRange?.[1],
                value: selectedShortcut
              };
            }
            return {...model, type: "inRange", filter: range?.[0], filterTo: range?.[1]};
          }
        }
      },
      setModel(model: any) {
        if (model?.type === "equals") {
          setDateValue(model.value);
        } else if (model?.type === "inRange") {
          if (model?.value && DefaultShortcutsTitles.includes(model.value)) {
            const shortcutRange = getDateRangeByTitle(model.value);
            setSelectedShortcut(model.value);
            setRange([shortcutRange[0], shortcutRange[1]]);
          } else if (model.filter && model.filterTo) {
            setRange([new Date(model.filter), new Date(model.filterTo)]);
          } else if (model.dateFrom && model.dateTo) {
            setRange([new Date(model.dateFrom), new Date(model.dateTo)]);
          } else {
            setRange(model.value);
          }
        } else {
          setDateValue("");
          setRange([null, null]);
        }
      },

      afterGuiAttached(params: IAfterGuiAttachedParams) {
        setAfterGuiAttachedParams(params);
      }
    };
  });

  const formatDate = (date: Date) => format(date, "MM/dd/yyy");
  const parseDate = (str: string) => toDate(str);

  const getDateComponent = () => {
    const commonProps = {
      className: "ag-custom-component-popup",
      fill: true,
      highlightCurrentDay: true,
      closeOnSelection: true,
      singleMonthOnly: true
    };
    if (getFilterType() === "single") {
      return (
        <DateInput2
          {...commonProps}
          value={dateValue}
          onChange={onChange}
          formatDate={formatDate}
          parseDate={parseDate}
        />
      );
    } else {
      return (
        <DateRangeInput2
          {...commonProps}
          value={range}
          onChange={onChange}
          formatDate={formatDate}
          parseDate={parseDate}
          shortcuts={shortcuts}
          allowSingleDayRange
          dayPickerProps={{
            onDayClick: handleDayClicked
          }}
          popoverProps={{onOpening: handlePopoverOpening, onOpened: handlePopoverOpened}}
        />
      );
    }
  };

  useEffect(() => {
    if (init && dateValue === "" && range?.every((x: Date | null) => x === null)) {
      props.filterChangedCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateValue, range]);

  return (
    <div style={{padding: 10, width: 250}} data-testid={"date-filter"}>
      <Title>{getFilterType() === "single" ? "Date" : "Date Range"}</Title>
      <DateInputContainer onClick={handleShortcutsClicked}>{getDateComponent()}</DateInputContainer>
      <Footer>
        <Button text={"Apply"} style={{marginRight: "5px"}} intent={Intent.PRIMARY} onClick={onApply} />
        <Button text={"Reset"} style={{marginRight: "5px"}} onClick={onClear} />
      </Footer>
    </div>
  );
});
