import {getTimeStampValue, hasQueuedStops, isCompleted, isOnSite} from "../../services/ManifestStopService";
import React, {
  CSSProperties,
  MouseEvent,
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import {Manifest, ManifestStop} from "../../generated/graphql";
import {Tag, Text} from "@blueprintjs/core";
import {useAppContext} from "../../ApplicationContext";
import {renderStopTypeIcon} from "./StopTypeIcons";
import {NewServiceBadge} from "../job/ServiceBadge";
import styled from "@emotion/styled";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import vehicleIconResolver from "../vehicles/VehicleIconResolver";
import {Tooltip2} from "@blueprintjs/popover2";
import {hasPendingActions, pendingActionsByManifestDriverId} from "../../utils/PendingAssignment";
import {LocationMarker} from "../map/LocationMarker";
import {ManifestVisibilityState, useMapVisibilityContext} from "../map/MapVisibilityContext";
import {CardType, JobAssignment} from "../../views/JobAssignmentView";
import {ColorRank, DispatchRulesDataContext, DispatchRulesDataState} from "../common/DispatchRulesDataProvider";
import DriverToolTipContent from "./DriverToolTip";
import {IndicatorColors} from "../settings/ColorizedIndicators/ColorizedIndicators";
import {UserColor} from "../settings/ColorizedIndicators/types/indicatorLogicType";
import StopCountIndicatorV3 from "./StopCountIndicatorV3";
import DriverQualV3 from "./DriverQualV3";
import {StopStatusIconV3} from "./StatusIconV3";
import {getDriverCode, getDriverName} from "../map/driver/DriverTitle";
import {getAddressText, isSmallCard} from "../../utils/General";
import {IConfigurationFormValues} from "./card-configuration/ManifestCardConfiguration";
import {
  AdditionalStopInformationValue,
  HoverOnStopsValues,
  StopLocationValue,
  TopStopInformationValue
} from "./card-configuration/ManifestConfigurationTypes";
import {hexToRgb} from "@mui/material";
import {PreferenceContext, extractSimplePref} from "../../providers/PreferenceProvider";
import {EmptyValueStrings, TenantPreferences} from "../common/Constants";
import {TExtraManifest} from "./details/ManifestDetailsReducer";

//Types
type ManifestCardDriverRowProps = {
  manifest: Manifest;
  handleDrillDownManifest(e: MouseEvent, openedManifest: Manifest): void;
  onLocationMarkerClick(manifest: Manifest): void;
  stopsColor: StopColor[];
  isSelected: boolean;
  disabled?: boolean;
  configurationState: IConfigurationFormValues;
  cardType: CardType;
};

type StopInfoItemProps = {
  index?: number;
  stop: ManifestStop;
  color: string;
  disabled: boolean;
  configurationState: IConfigurationFormValues;
  cardType: CardType;
};

export type StopColor = {
  color: string;
  jobStopID?: number;
};

type TDriverInfo = {
  configurationState: IConfigurationFormValues;
  manifest: Manifest;
  disabled: boolean;
  handleDrillDownManifest: (e: MouseEvent, openedManifest: Manifest) => void;
};

//Utils
export const hasTooManyStops = (manifest: Manifest | TExtraManifest | undefined) => {
  return (manifest?.stopCount ?? 0) > 500;
};

export const getHighestRankedColor = (colors: StopColor[], colorRank: ColorRank[]) => {
  const sortedColors = colors
    .filter((color) => color.color)
    .sort((a, b) => {
      const colorA = colorRank.find((rank: ColorRank) => rank.color === a.color);
      const colorB = colorRank.find((rank: ColorRank) => rank.color === b.color);

      return colorA && colorB ? colorA.rank - colorB.rank : 0;
    });

  return sortedColors[0]?.color;
};

const renderDriverLeftInfo = ({configurationState, manifest, handleDrillDownManifest, disabled}: TDriverInfo) => {
  const {driverName, driverCodeAndName, driverCode, driverCapabilities} = configurationState;

  if (driverCodeAndName || (driverName && driverCode && driverCapabilities)) {
    const text =
      getDriverCode(manifest.driver) + (getDriverCode(manifest.driver) ? " - " : "") + getDriverName(manifest.driver);
    return (
      <DriverLeftContent
        title=""
        ellipsize
        data-testid={"driver-left-info"}
        onClick={(e) => handleDrillDownManifest(e, manifest)}
      >
        {disabled ? text : <a href="/.">{text}</a>}
      </DriverLeftContent>
    );
  }

  if (driverCode && driverCapabilities && !driverName) {
    return (
      <DriverLeftContent
        title=""
        ellipsize
        data-testid={"driver-left-info"}
        onClick={(e) => handleDrillDownManifest(e, manifest)}
      >
        {disabled ? getDriverCode(manifest.driver) : <a href="/.">{getDriverCode(manifest.driver)}</a>}
      </DriverLeftContent>
    );
  }

  if (driverName) {
    return (
      <DriverLeftContent
        title=""
        ellipsize
        className={disabled ? "bp4-disabled" : ""}
        data-testid={"driver-left-info"}
        onClick={(e) => handleDrillDownManifest(e, manifest)}
      >
        {disabled ? getDriverName(manifest.driver) : <a href="/.">{getDriverName(manifest.driver)}</a>}
      </DriverLeftContent>
    );
  }

  return "";
};

const renderDriverRightInfo = ({configurationState, manifest, handleDrillDownManifest, disabled}: TDriverInfo) => {
  const {driverCode, driverCapabilities} = configurationState;

  if (driverCapabilities) {
    return (
      <>
        <DriverQualV3 qualifications={manifest.driver.qualifications} />
        {(manifest.driver.qualifications ?? []).length > 0 && <Divider height={12} />}
      </>
    );
  }

  if (driverCode) {
    return (
      <>
        <Tooltip2
          placement="bottom"
          popoverClassName="manifest-card-header-popover"
          content={getDriverCode(manifest.driver)}
        >
          <DriverRightContent
            className={disabled ? "bp4-disabled" : ""}
            ellipsize
            style={{maxWidth: "80px"}}
            title=""
            data-testid={"driver-right-info"}
            onClick={(e) => handleDrillDownManifest(e, manifest)}
          >
            {disabled ? getDriverCode(manifest.driver) : <a href="/.">{getDriverCode(manifest.driver)}</a>}
          </DriverRightContent>
        </Tooltip2>
        {getDriverCode(manifest.driver) && <Divider height={12} />}
      </>
    );
  }

  return "";
};

// Components
export const ManifestCardHeader = ({
  manifest,
  handleDrillDownManifest,
  onLocationMarkerClick,
  stopsColor,
  isSelected,
  configurationState,
  disabled = false,
  cardType
}: ManifestCardDriverRowProps) => {
  const {tenantPreferences} = useContext(PreferenceContext);
  const {state: mapVisibilityState} = useMapVisibilityContext();
  const manifestVisibility =
    mapVisibilityState.manifests.get(manifest.manifestDriverId) ??
    new ManifestVisibilityState(manifest.manifestDriverId, manifest.driver.driverId);
  const [indicatorColor, setIndicatorColor] = useState("");
  const jobAssignmentContext = useContext(JobAssignment);
  const ruleContextState = useContext<DispatchRulesDataState>(DispatchRulesDataContext);
  const manifestPendingActions = pendingActionsByManifestDriverId(
    manifest.manifestDriverId,
    jobAssignmentContext?.pendingActions ?? []
  );
  const over500Stops = hasTooManyStops(manifest);

  const queuedStops = useMemo(() => {
    return hasQueuedStops(manifest.stops);
  }, [manifest]);

  const showStatusIcon = useMemo(() => {
    if (isSmallCard(cardType)) {
      return over500Stops;
    }
    return true;
  }, [cardType, over500Stops]);

  const getDriverHoverConfigs = useMemo(() => {
    return configurationState ? configurationState.hoverDriverConfig.map((item) => item.value) : undefined;
  }, [configurationState]);

  useEffect(() => {
    if (!ruleContextState.loading && ruleContextState.colorRank) {
      setIndicatorColor(getHighestRankedColor(stopsColor, ruleContextState.colorRank));
    }
  }, [ruleContextState, stopsColor, manifest]);

  return (
    <HeaderContainer disabled={disabled}>
      <LeftSideHeader>
        <StopCountIndicatorV3 stops={manifest?.stops} indicatorColor={indicatorColor} cardType={cardType} />
        <Tooltip2
          placement="bottom"
          popoverClassName="manifest-card-header-popover"
          content={<DriverToolTipContent manifest={manifest} driverHoverConfigs={getDriverHoverConfigs} />}
          disabled={getDriverHoverConfigs?.length === 0}
        >
          {renderDriverLeftInfo({manifest, configurationState, handleDrillDownManifest, disabled})}
        </Tooltip2>
      </LeftSideHeader>
      <RightSideHeader>
        {renderDriverRightInfo({manifest, configurationState, handleDrillDownManifest, disabled})}
        {showStatusIcon && (
          <>
            <StopStatusIconV3
              pending={hasPendingActions(manifestPendingActions)}
              over500Stops={over500Stops}
              queuedStops={queuedStops}
              disabled={disabled}
            />
            <Divider height={12} />
          </>
        )}
        <FontAwesomeIcon
          data-testid={"vehicle-icon"}
          size={"lg"}
          color={isSelected ? "#436BF8" : "#4C505E"}
          icon={vehicleIconResolver(manifest.vehicleType.description, tenantPreferences)}
          title={manifest.vehicleType.description as string}
        />
        <Divider height={12} />
        <LocationMarkerContainer
          data-testid={`map-icon-manifest-${manifest.manifestDriverId}`}
          onClick={(e) => {
            e.stopPropagation();
            if (!disabled) {
              onLocationMarkerClick(manifest);
            }
          }}
        >
          <LocationMarker
            isVisible={manifestVisibility.isVisible()}
            color={disabled ? "rgba(95, 107, 124, 0.6)" : manifestVisibility.routeColor}
            error={manifestVisibility.error}
          />
        </LocationMarkerContainer>
      </RightSideHeader>
    </HeaderContainer>
  );
};

const StopTime = ({
  children,
  color,
  textColor,
  isTimeFormat24hr
}: {
  children: any;
  color: string;
  textColor: string;
  isTimeFormat24hr: boolean;
}) => {
  const styles: CSSProperties = {
    position: "relative",
    backgroundColor: `rgba(${hexToRgb(color).substring(4, hexToRgb(color).length - 1)}, 0.3)`,
    color: color || textColor,
    width: "100%",
    height: "20px",
    borderRadius: "4px",
    fontSize: "12px",
    lineHeight: "20px",
    fontWeight: 500,
    padding: "0 4px",
    letterSpacing: isTimeFormat24hr ? "0.5px" : "-0.4px",
    textAlign: "center"
  };
  return (
    <div className="stop-time" style={styles}>
      {children}
    </div>
  );
};

export const StopAddressHoverContent = ({
  stop,
  getStopHoverConfigs,
  additionalContents
}: {
  stop: ManifestStop;
  getStopHoverConfigs: string[] | undefined;
  additionalContents?: string[];
}) => {
  const customer = stop.order.customer.name;
  const service = stop.order.service;
  const stopName = stop.name;
  const stopAddress = getAddressText({stop});
  const stopPhone = stop.phone;
  const stopSee = stop.see;
  const stopNote = stop.note;
  const stopRoom = stop.room;
  const stopDescription = stop.order.description;
  const jobRouteNumber = stop.job.routeNumber;
  const orderAlias = stop.order.alias;
  const orderAuth = stop.order.auth;
  const orderOtherReference = stop.order.otherReference;
  const orderInvoiceReference = stop.order.invoiceReference;
  const jobNumber = stop.job.jobNumber;
  //Function
  const renderStopPieceOrWeight = useCallback(() => {
    return `${stop.job.pieces ?? EmptyValueStrings.notProvided} / ${stop.job.weight ?? EmptyValueStrings.notProvided}`;
  }, [stop.job.pieces, stop.job.weight]);

  const renderCallerAndPhone = useCallback(() => {
    return `${stop.order.caller ?? EmptyValueStrings.notProvided} & ${
      stop.order.callerPhone ?? EmptyValueStrings.notProvided
    }`;
  }, [stop.order.caller, stop.order.callerPhone]);

  const renderCODStatusAndAmount = useCallback(() => {
    return `${stop.order.codStatus ?? EmptyValueStrings.notProvided} & ${
      stop.order.codAmount ?? EmptyValueStrings.notProvided
    }`;
  }, [stop.order.codAmount, stop.order.codStatus]);

  const renderHoverContent = useMemo(() => {
    const renderHover = new Map<string, {content: string | number}>();
    const mapCardConfigs = (key: string, content: string | number | undefined | null) => {
      renderHover.set(key, {content: content ?? EmptyValueStrings.notProvided});
    };
    getStopHoverConfigs?.forEach((config) => {
      switch (config) {
        case HoverOnStopsValues.CUSTOMER:
          mapCardConfigs(HoverOnStopsValues.CUSTOMER, customer);
          break;
        case HoverOnStopsValues.STOP_ADDRESS:
          mapCardConfigs(HoverOnStopsValues.STOP_ADDRESS, stopAddress);
          break;
        case HoverOnStopsValues.STOP_NAME:
          mapCardConfigs(HoverOnStopsValues.STOP_NAME, stopName);
          break;
        case HoverOnStopsValues.STOP_PHONE:
          mapCardConfigs(HoverOnStopsValues.STOP_PHONE, stopPhone);
          break;
        case HoverOnStopsValues.STOP_SEE:
          mapCardConfigs(HoverOnStopsValues.STOP_SEE, stopSee);
          break;
        case HoverOnStopsValues.STOP_NOTES:
          mapCardConfigs(HoverOnStopsValues.STOP_NOTES, stopNote);
          break;
        case HoverOnStopsValues.SERVICE:
          mapCardConfigs(HoverOnStopsValues.SERVICE, service);
          break;
        case HoverOnStopsValues.DESCRIPTION:
          mapCardConfigs(HoverOnStopsValues.DESCRIPTION, stopDescription);
          break;
        case HoverOnStopsValues.PIECES_OR_WEIGHT:
          mapCardConfigs(HoverOnStopsValues.PIECES_OR_WEIGHT, renderStopPieceOrWeight());
          break;
        case HoverOnStopsValues.ROUTE_NUMBER:
          mapCardConfigs(HoverOnStopsValues.ROUTE_NUMBER, jobRouteNumber);
          break;
        case HoverOnStopsValues.ALIAS:
          mapCardConfigs(HoverOnStopsValues.ALIAS, orderAlias);
          break;
        case HoverOnStopsValues.STOP_ROOM:
          mapCardConfigs(HoverOnStopsValues.STOP_ROOM, stopRoom);
          break;
        case HoverOnStopsValues.CALLER_AND_PHONE:
          mapCardConfigs(HoverOnStopsValues.CALLER_AND_PHONE, renderCallerAndPhone());
          break;
        case HoverOnStopsValues.AUTH:
          mapCardConfigs(HoverOnStopsValues.AUTH, orderAuth);
          break;
        case HoverOnStopsValues.COD_STATUS_AND_AMOUNT:
          mapCardConfigs(HoverOnStopsValues.COD_STATUS_AND_AMOUNT, renderCODStatusAndAmount());
          break;
        case HoverOnStopsValues.OTHER_REFERENCE:
          mapCardConfigs(HoverOnStopsValues.OTHER_REFERENCE, orderOtherReference);
          break;
        case HoverOnStopsValues.INVOICE_REFERENCE:
          mapCardConfigs(HoverOnStopsValues.INVOICE_REFERENCE, orderInvoiceReference);
          break;
        case HoverOnStopsValues.JOB_NUMBER:
          mapCardConfigs(HoverOnStopsValues.JOB_NUMBER, jobNumber);
          break;
      }
    });
    return renderHover;
  }, [
    customer,
    getStopHoverConfigs,
    jobNumber,
    jobRouteNumber,
    orderAlias,
    orderAuth,
    orderInvoiceReference,
    orderOtherReference,
    renderCODStatusAndAmount,
    renderCallerAndPhone,
    renderStopPieceOrWeight,
    service,
    stopAddress,
    stopDescription,
    stopName,
    stopNote,
    stopPhone,
    stopRoom,
    stopSee
  ]);

  const renderHoverElement = useCallback(() => {
    const arrayElement = Array.from(renderHoverContent);
    return arrayElement.map(([key, value]) => (
      <Text key={key}>
        {key}: {value.content}
      </Text>
    ));
  }, [renderHoverContent]);

  return (
    <StopAdressHoverContainer>
      {additionalContents?.map((c) => (
        <Text className="primary" key={c}>
          {c}
        </Text>
      ))}
      {getStopHoverConfigs ? (
        renderHoverElement()
      ) : (
        <>
          <Text className="primary">{stopAddress}</Text>
          <Text>Customer: {stop.order.customer.name}</Text>
          {stop.see && <Text>See: {stop.see}</Text>}
          <Text>Phone: {stop.phone}</Text>
          <Text>Route #: {stop.job.routeNumber}</Text>
        </>
      )}
    </StopAdressHoverContainer>
  );
};

const StopAddress = ({
  stop,
  textColor,
  configurationState,
  cardType
}: {
  stop: ManifestStop;
  textColor: string;
  configurationState: IConfigurationFormValues;
  cardType: CardType;
}) => {
  const [additionalContents, setAdditionalContents] = useState<string[]>([]);
  const address = getAddressText({stop, stopLocationState: configurationState.stopLocation});
  let additionalInfo;

  switch (configurationState.additionalStopLocation) {
    case AdditionalStopInformationValue.CustomerName: {
      additionalInfo = stop.order.customer.name ?? "";
      break;
    }
    case AdditionalStopInformationValue.PiecesCount: {
      additionalInfo = stop.job.pieces?.toString() + " pcs ";
      break;
    }
    case AdditionalStopInformationValue.RouteNumber: {
      additionalInfo = stop.job.routeNumber ? "R# " + stop.job.routeNumber : "";
      break;
    }
    case AdditionalStopInformationValue.StopName: {
      additionalInfo = stop.name ?? "";
      break;
    }
    default:
  }

  const getStopHoverConfigs = useMemo(() => {
    return configurationState ? configurationState.hoverStopsConfig.map((item) => item.value) : undefined;
  }, [configurationState]);

  const checkEllipsisActive: MouseEventHandler<HTMLElement> = (e: MouseEvent) => {
    const additionalContents: string[] = [];
    const childNodes = (e.currentTarget as HTMLElement).childNodes as NodeListOf<HTMLElement>;
    childNodes.forEach((node) => {
      if (node.offsetWidth < node.scrollWidth && node.textContent)
        additionalContents.push(node.textContent.replaceAll("|", "").trim());
    });
    setAdditionalContents(additionalContents);
  };

  return (
    <AddressContainer textColor={textColor}>
      <Tooltip2
        matchTargetWidth
        popoverClassName="manifest-card-address-popover"
        content={
          <StopAddressHoverContent
            stop={stop}
            getStopHoverConfigs={getStopHoverConfigs}
            additionalContents={additionalContents}
          />
        }
        position="bottom"
        disabled={getStopHoverConfigs?.length === 0}
      >
        <AdditionalTextContainer
          data-testid="stop-item"
          additionalInfo={additionalInfo}
          onMouseEnter={checkEllipsisActive}
        >
          <AddressText data-testid="stop-address">{address}</AddressText>
          {additionalInfo && (
            <Text ellipsize title="" data-testid="additional-infor">
              <span style={{fontSize: "14px", fontStyle: "normal", margin: "0 1px"}}>|</span>
              <AdditionalInfoText>{additionalInfo}</AdditionalInfoText>
            </Text>
          )}
        </AdditionalTextContainer>
      </Tooltip2>
      {!isSmallCard(cardType) && configurationState.stopLocation === StopLocationValue.FullAddressAndZone && (
        <Zone>{stop.dispatchZone}</Zone>
      )}
    </AddressContainer>
  );
};

const StopService = ({stop, isFirstStopEnabled}: {stop: ManifestStop; isFirstStopEnabled: boolean}) => {
  if (isFirstStopEnabled) {
    return (
      <CustomTag
        style={{
          fontSize: "12px",
          padding: "0 3px"
        }}
        minimal
        intent="success"
      >
        Stop 1
      </CustomTag>
    );
  }
  if (isOnSite(stop)) {
    return (
      <CustomTag minimal intent="success">
        ON SITE
      </CustomTag>
    );
  }
  if (isCompleted(stop)) {
    return (
      <Tooltip2 popoverClassName="manifest-card-service-popover" position="bottom" content="Completed">
        <IconContainer>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.5 5L6.49042 11.0096L3.50522 8.02439"
              stroke="#A9A9A9"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </IconContainer>
      </Tooltip2>
    );
  }

  return (
    <Tooltip2 popoverClassName="manifest-card-service-popover" content={stop.order.service as string} position="bottom">
      <NewServiceBadge service={stop.order.service as string} ellipsize />
    </Tooltip2>
  );
};

const getStopItemStyles = ({
  stop,
  configurationState,
  color,
  disabled
}: {
  stop: ManifestStop;
  configurationState: IConfigurationFormValues;
  color: string;
  disabled: boolean;
}) => {
  const isCompletedStop = isCompleted(stop);
  const isFirstStopEnabled = configurationState.topStopInformation === TopStopInformationValue.FirstStopInManifest;
  const isOnSiteStop = isOnSite(stop);

  return isFirstStopEnabled
    ? {
        time: isCompletedStop || disabled ? "#A9A9A9" : color ? "#FFFFFF" : "#000000",
        address: isCompletedStop || disabled ? "#A9A9A9" : "#000000",
        stopType: isCompletedStop || disabled ? "#A9A9A9" : ""
      }
    : {
        time: isCompletedStop || disabled ? "#A9A9A9" : color ? "#FFFFFF" : "#000000",
        address: isOnSiteStop || isCompletedStop || disabled ? "#A9A9A9" : "#000000",
        stopType: isOnSiteStop || isCompletedStop || disabled ? "#A9A9A9" : ""
      };
};

export const StopInfoItem = ({index, stop, color, disabled, configurationState, cardType}: StopInfoItemProps) => {
  const {appState} = useAppContext();
  const isCompletedStop = isCompleted(stop);
  const {tenantPreferences} = useContext(PreferenceContext);
  const timezoneOfJobStop = extractSimplePref(tenantPreferences, TenantPreferences.timezoneOfJobStop, false)
    .value as boolean;

  const isFirstStopAndFirstStopEnabled = useMemo(
    () => index === 0 && configurationState.topStopInformation === TopStopInformationValue.FirstStopInManifest,
    [configurationState.topStopInformation, index]
  );

  const styles = getStopItemStyles({stop, color, configurationState, disabled});
  const timeValue = getTimeStampValue(
    stop,
    timezoneOfJobStop,
    isSmallCard(cardType) ? true : appState.isTimeFormat24hr
  );

  return (
    <StopInfoItemContainer className={isSmallCard(cardType) ? "small-card" : ""}>
      <StopTime
        color={color && !isCompletedStop ? IndicatorColors[color as UserColor].hex : ""}
        textColor={styles.time}
        isTimeFormat24hr={appState.isTimeFormat24hr}
      >
        {timeValue}
        {timezoneOfJobStop && timeValue !== EmptyValueStrings.notAvailable && (
          <TimeZoneTag>{stop.timeZone?.charAt(0).toUpperCase()}</TimeZoneTag>
        )}
      </StopTime>
      <StopAddress stop={stop} textColor={styles.address} configurationState={configurationState} cardType={cardType} />
      <StopTypeIcon>{renderStopTypeIcon(stop.stopType, styles.stopType)}</StopTypeIcon>
      <StopService isFirstStopEnabled={isFirstStopAndFirstStopEnabled} stop={stop} />
    </StopInfoItemContainer>
  );
};

//Styles
const HeaderContainer = styled.div<{disabled: boolean}>`
  display: grid;
  grid-template-columns: minmax(0, auto) 1fr;
  align-items: center;
  gap: 4px;
  padding: 8px 8px 4px 8px;
  width: 100%;
  height: 100%;
  border-bottom: solid#cfd4df 1px;

  & a {
    font-size: 12px;
    font-weight: 500;
    color: ${(props) => (props.disabled ? "rgba(95, 107, 124, 0.6)" : "#406AFF")};
  }
`;

const LeftSideHeader = styled.div`
  display: grid;
  grid-template-columns: min-content minmax(0, 1fr);
  gap: 4px;
  align-items: center;
`;

const RightSideHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 7px;
`;

const Divider = styled.div<{height?: number}>`
  height: ${(props) => (props.height ? props.height + "px" : "20px")};
  width: 1px;
  border-radius: 0.5px;
  background-color: #cfd4df;
`;

const StopInfoItemContainer = styled.div`
  height: 20px;
  font-size: 12px;
  display: grid;
  grid-template-columns: 56px auto 16px 38px;
  gap: 0 4px;
  align-items: center;
`;

const AddressContainer = styled.div<{textColor: string}>`
  color: ${(props) => props.textColor};
  line-height: 20px;
  font-weight: 400;
  letter-spacing: -0.5px;
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
`;

const AddressText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AdditionalInfoText = styled(Text)`
  display: inline;
  color: #797979;
  font-style: italic;
`;

const Zone = styled.div`
  white-space: nowrap;
`;

const StopAdressHoverContainer = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  color: #797979;
  font-weight: 400;
  .primary {
    font-size: 12px;
    color: #161616;
    margin-bottom: 5px;
  }
`;

const StopTypeIcon = styled.span`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LocationMarkerContainer = styled.div``;

const DriverRightContent = styled(Text)`
  font-weight: 400;
`;

const DriverLeftContent = styled(Text)`
  font-weight: 400;
`;

const CustomTag = styled(Tag)`
  color: rgba(50, 164, 103, 1);
  font-size: 10px;
  background-color: rgba(50, 164, 103, 0.18);
  padding: 0 2px;
  letter-spacing: -0.5px;
  border-radius: 4px;
  width: fit-content;
  height: 16px;
  font-weight: 500;
`;

const AdditionalTextContainer = styled.div<{additionalInfo: string | undefined}>`
  display: grid;
  grid-template-columns: ${(props) => (props.additionalInfo ? "auto minmax(60px,1fr)" : "1fr")};
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TimeZoneTag = styled.span`
  position: absolute;
  font-size: 8px;
  top: -4px;
`;
