import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {duotone, solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import styled from "@emotion/styled";
import {Tooltip2} from "@blueprintjs/popover2";

type NewStopStatusIconProps = {
  pending?: boolean;
  over500Stops?: boolean;
  queuedStops?: boolean;
  disabled?: boolean;
};
export const TooManyStopsWarningMessage =
  "The system is unable to process manifests with over 500 stops. To resolve, please navigate to the manifest within the CMS application.";

export const StopStatusIconV3 = ({
  pending = false,
  over500Stops = false,
  queuedStops = false,
  disabled = false
}: NewStopStatusIconProps) => {
  const renderIcon = () => {
    if (over500Stops) {
      return (
        <Tooltip2
          content={TooManyStopsWarningMessage}
          popoverClassName="manifest-warning-popover"
          position={"bottom-right"}
        >
          <WarningIcon />
        </Tooltip2>
      );
    }
    if (queuedStops) {
      return (
        <FontAwesomeIcon
          data-testid={"hourglass-icon"}
          size={"1x"}
          color={disabled ? "rgba(95, 107, 124, 0.6)" : "#4C505E"}
          icon={solid("hourglass-start")}
        />
      );
    }
    return null;
  };
  return (
    <NewStatusIcon>
      {pending ? (
        <FontAwesomeIcon
          data-testid={"spinner-icon"}
          icon={duotone("spinner")}
          size={"xs"}
          spinPulse
          color={disabled ? "rgba(95, 107, 124, 0.6)" : "#4C505E"}
        />
      ) : (
        renderIcon()
      )}
    </NewStatusIcon>
  );
};

const NewStatusIcon = styled.div``;

export const WarningIcon = ({color = "#FA545E", size = 15}: {color?: string; size?: number}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1820_2943)">
        <path
          d="M7.4999 0.9375C7.91591 0.9375 8.2997 1.15723 8.51064 1.51758L14.8388 12.2988C15.0526 12.6621 15.0526 13.1104 14.8446 13.4736C14.6366 13.8369 14.247 14.0625 13.828 14.0625H1.17177C0.752825 14.0625 0.363176 13.8369 0.155169 13.4736C-0.0528392 13.1104 -0.0499095 12.6592 0.161028 12.2988L6.48915 1.51758C6.70009 1.15723 7.08388 0.9375 7.4999 0.9375ZM7.4999 4.6875C7.11025 4.6875 6.79677 5.00098 6.79677 5.39062V8.67188C6.79677 9.06152 7.11025 9.375 7.4999 9.375C7.88954 9.375 8.20302 9.06152 8.20302 8.67188V5.39062C8.20302 5.00098 7.88954 4.6875 7.4999 4.6875ZM8.4374 11.25C8.4374 11.0014 8.33862 10.7629 8.16281 10.5871C7.98699 10.4113 7.74854 10.3125 7.4999 10.3125C7.25125 10.3125 7.0128 10.4113 6.83698 10.5871C6.66117 10.7629 6.5624 11.0014 6.5624 11.25C6.5624 11.4986 6.66117 11.7371 6.83698 11.9129C7.0128 12.0887 7.25125 12.1875 7.4999 12.1875C7.74854 12.1875 7.98699 12.0887 8.16281 11.9129C8.33862 11.7371 8.4374 11.4986 8.4374 11.25Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1820_2943">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
