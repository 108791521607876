import {ApolloClient, ApolloError, ApolloQueryResult, ServerError, gql} from "@apollo/client";
import {
  IServerSideDatasource,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest,
  SortModelItem
} from "@ag-grid-community/core";

import {
  SearchableSortDirection,
  SearchStopFilter,
  Stop,
  StopSortInput,
  StopTextFields
} from "../../../generated/graphql";
import {Constants} from "../../common/Constants";
import {buildQuery, makeQueryStringFilter} from "../../../utils/QueryUtils";
import {createCondition} from "../../../utils/FilterUtils";
import {AgStop} from "./Grid";
import {ManifestStatusTypes} from "../../settings/ColorizedIndicators/types/manifestAttributes";

type Decorator = (params: IServerSideGetRowsParams, results: Stop[], res: ApolloQueryResult<any>) => Promise<void>;

type CreateStopsServerSideDatasourceProps = {
  client: ApolloClient<any>;
  defaultFields?: string[];
  excludeFields?: string[];
  onDatasourceFail?(error: ApolloError | undefined): void;
  decorateResults: Decorator[];
  tokenExpiry: Date | undefined;
};

const CustomOperatorFields = new Map<string | undefined, string>([
  ["job.jobNumber", "matchPhrasePrefix"],
  ["address", "matchPhrasePrefix"],
  ["name", "matchPhrasePrefix"],
  ["city", "matchPhrasePrefix"],
  ["note", "matchPhrasePrefix"],
  ["podName", "matchPhrasePrefix"],
  ["driver.name", "matchPhrasePrefix"],
  ["order.customer.notes", "matchPhrasePrefix"],
  ["order.customer.name", "matchPhrasePrefix"],
  ["order.notes", "matchPhrasePrefix"],
  ["order.caller", "matchPhrasePrefix"],
  ["order.callerPhone", "matchPhrasePrefix"],
  ["order.auth", "matchPhrasePrefix"],
  ["order.alias", "matchPhrasePrefix"]
]);
const MappingFilterFields = new Map<string, string>([["order.customer.name", "order.customer.customerId"]]);
const StopSortDefault = [
  {field: "manifest.manifestDriverId", direction: "asc"},
  {field: "manifestSequence", direction: "asc"}
] as StopSortInput[];

export const createStopsServerSideDatasource = ({
  client,
  defaultFields,
  excludeFields,
  onDatasourceFail,
  decorateResults,
  tokenExpiry
}: CreateStopsServerSideDatasourceProps): IServerSideDatasource => {
  return {
    getRows: function (params: IServerSideGetRowsParams) {
      const {startRow, filterModel, sortModel}: IServerSideGetRowsRequest = params.request;
      const colIdToFields: {[key: string]: string[]} = params.columnApi
        .getAllDisplayedColumns()
        .filter((x) => !excludeFields?.includes(x.getId()))
        .reduce((map, col) => {
          const colId = col.getColId();
          map[colId] = col.getColDef().field?.split(",") || [colId];
          return map;
        }, {} as {[key: string]: string[]});

      const {globalQuery, globalFilter} = params.context;

      let filter: SearchStopFilter = {
        stop: {
          and: [{manifest_manifestDriverId: {gte: 0}}, ...buildStopsFilter(filterModel)],
          or: [
            {manifest_manifestStatus: {match: ManifestStatusTypes.Active}},
            {manifest_manifestStatus: {match: ManifestStatusTypes.Future}},
            {manifest_manifestStatus: {match: ManifestStatusTypes.Inactive}}
          ]
        }
      };

      if (globalFilter) {
        filter = {
          stop: {and: [globalFilter, {...filter?.stop}]}
        };
      }

      if (globalQuery) {
        filter.queryString = makeQueryStringFilter(globalQuery, [
          "job.jobNumber",
          "job.routeNumber",
          "job.site.code",
          "name",
          "address",
          "city",
          "state",
          "dispatchZone",
          "note",
          "podName",
          "driver.name",
          "order.customer.notes",
          "order.customer.name",
          "order.caller",
          "order.callerPhone",
          "order.alias",
          "order.auth",
          "order.orderId",
          "order.notes"
        ]);
      }
      console.debug("StopFilter:", filter);

      let sort = [...buildSort(sortModel, colIdToFields)];

      const isSortingByManifestDriverId = sort.some((item) => item.field === StopSortDefault[0].field);
      const isSortingByManifestSequence = sort.some((item) => item.field === StopSortDefault[1].field);

      if (!isSortingByManifestDriverId && !isSortingByManifestSequence) {
        sort = sort.concat(StopSortDefault);
      }
      if (isSortingByManifestDriverId) {
        sort = sort.concat(StopSortDefault[1]);
      }
      if (isSortingByManifestSequence) {
        sort = sort.concat(StopSortDefault[0]);
      }

      console.debug(`StopSort: ${JSON.stringify(sort)}`);

      let visibleColumnIds: string[] = Object.values(colIdToFields).flatMap((x) => x);
      if (defaultFields) {
        visibleColumnIds = visibleColumnIds.concat(defaultFields);
      }

      const gqlCols = buildQuery(visibleColumnIds);
      console.debug(`GQL Columns: ${gqlCols}`);

      const query = gql(/* GRAPHQL */ `
                    query SearchStops($filter: SearchStopFilter, $sort: [StopSortInput], $offset: Int, $limit: Int) {
                        searchStops(filter: $filter, sort: $sort, offset: $offset, limit: $limit) {
                            items {
                                ${gqlCols}
                            }
                            nextToken,
                            total
                        }
                    }
                `);

      let retry = 0;

      const getData = async () => {
        try {
          const res = await client.query({
            query: query,
            fetchPolicy: "network-only",
            variables: {
              offset: startRow,
              limit: params.api.paginationGetPageSize(),
              filter: filter,
              sort: sort
            }
          });

          const serverSideResults = (res.data.searchStops.items as Stop[]).map((j) => {
            return {...j} as AgStop;
          });

          if (decorateResults) {
            for await (const decorator of decorateResults) {
              await decorator(params, serverSideResults, res);
            }
          }
          params.success({
            rowData: serverSideResults,
            rowCount: res.data.searchStops.total
          });
          retry = 0;
        } catch (error) {
          handleError(error as ApolloError);
        }
      };

      const handleError = (err: ApolloError) => {
        const netError = err.networkError as ServerError;
        if (netError && netError.statusCode === 401) {
          //This will retry at the ApolloLink level
          console.debug("Cancel loading with a fake success");
          params.success({rowData: [], rowCount: 0});
          return;
        }
        //Refetch if type error
        if (err.name === "TypeError") {
          console.debug("Retrying by TypeError:", err);
          getData();
          return;
        }
        if (retry === Constants.MAX_NUMBER_OF_RETRY) {
          console.error("Stops grid error", {
            error: err,
            raw: JSON.stringify(err),
            tokenExpiry: tokenExpiry,
            clientDateTime: new Date()
          });
          onDatasourceFail?.(err);
          params.fail();
          return;
        }
        retry++;
        console.debug(`Retrying ${retry} by error:`, err);
        getData();
      };
      onDatasourceFail?.(undefined);
      getData();
    }
  };
};

const buildSort = (sortModel: SortModelItem[], colIdToFields: {[key: string]: string[]}) => {
  const newSortModel = [...sortModel];
  const newColIdToFields = {...colIdToFields};
  const addressSortIndex = newSortModel.findIndex((item) => item.colId === "stop.address");
  const filterAndSortByColorsIndex = newSortModel.findIndex((item) => item.colId === "indicatorColor");
  if (addressSortIndex !== -1) {
    newSortModel.splice(addressSortIndex, 0, {
      colId: "stop.zip",
      sort: newSortModel[addressSortIndex].sort
    });
    newColIdToFields["stop.zip"] = ["stop.zip"];
  }
  if (filterAndSortByColorsIndex !== -1) {
    newSortModel.splice(filterAndSortByColorsIndex, 1);
  }
  return newSortModel
    .filter((sm) => newColIdToFields[sm.colId] !== undefined)
    .map((sm) => {
      let sortKey: string;
      const textFields = StopTextFields;
      const sortInput = {} as StopSortInput;

      sortKey = sm.colId;
      const textFieldKey = sortKey.replaceAll(".", "_");

      if ((Object.values(textFields) as string[]).includes(textFieldKey)) {
        sortKey += ".keyword";
      }
      sortInput.field = sortKey;
      sortInput.direction = sm.sort === "asc" ? SearchableSortDirection.Asc : SearchableSortDirection.Desc;
      return sortInput;
    });
};

const buildStopsFilter = (filterModel: any): any[] => {
  const filters: any[] = [];
  if (filterModel) {
    Object.keys(filterModel)
      .filter((x) => x !== "indicatorColor")
      .forEach(function (columnKey) {
        let key = columnKey;
        if (MappingFilterFields.has(columnKey)) {
          key = MappingFilterFields.get(columnKey)!;
        }
        const condition = createCondition(
          key.replaceAll(".", "_"),
          filterModel[columnKey],
          columnKey,
          CustomOperatorFields
        );
        filters.push(condition);
      });
  }
  return filters;
};
