import {Intent, Tag, Text} from "@blueprintjs/core";
import React, {CSSProperties, useContext} from "react";
import {AuthContext} from "../../AuthProvider";
import {ICellRendererParams} from "@ag-grid-community/core";
import {ManifestStop} from "../../../generated/graphql";
import {navigateToCmsOrder} from "../../../services/CmsService";
import styled from "@emotion/styled";
import {PreferenceContext} from "../../../providers/PreferenceProvider";

const ManifestJobBadge = styled(Tag)`
  border-radius: 4px;
  background-color: transparent;
`;

const LinkText = styled(Text)<{color?: string; fontSize?: number}>`
  opacity: 1;
  font-family: Roboto;
  font-weight: 500;
  font-size: ${(props) => props.fontSize ?? "12"}px;
  color: ${(props) => props.color ?? "#1f2937"};
`;

interface ManifestJobCellRendererParams extends ICellRendererParams<ManifestStop, string> {
  jobColoringIndex: Map<string, number>;
  minimal?: boolean;
  fontSize?: number;
  color?: string;
}

export const ManifestJobColorPalette: CSSProperties[] = [
  {backgroundColor: "#C996FD"},
  {backgroundColor: "#62EAA9"},
  {backgroundColor: "#E4D03D"},
  {backgroundColor: "#FF9495"},
  {backgroundColor: "rgba(119,179,211,0.5)"},
  {backgroundColor: "#FFAC75"},
  {backgroundColor: "rgba(235,57,235,0.5)"},
  {backgroundColor: "#7CC254"},
  {backgroundColor: "rgba(255,207,224,0.5)"},
  {backgroundColor: "#FFC328"},
  {backgroundColor: "rgba(239,64,66,0.5)"},
  {backgroundColor: "rgba(113,255,216,0.5)"},
  {backgroundColor: "rgba(228,208,61,0.5)"},
  {backgroundColor: "rgba(64,66,179,0.5)"},
  {backgroundColor: "#2AB3FD"},
  {backgroundColor: "rgba(255,172,117,0.5)"},
  {backgroundColor: "#EB39EB"},
  {backgroundColor: "rgba(124,194,84,0.5)"},
  {backgroundColor: "#42A673"},
  {backgroundColor: "#8CFF64"}
];

const MinimalStyles: CSSProperties = {
  padding: "2px 0"
};

export const JobNumberCellRenderer = (props: ManifestJobCellRendererParams) => {
  const {fontSize, color, minimal, data, jobColoringIndex, node, value, api} = props;

  const authState = useContext(AuthContext);
  const {tenantPreferences} = useContext(PreferenceContext);

  const backgroundColorIndex = jobColoringIndex?.get(data!.job.jobNumber);
  let styling: CSSProperties = {
    backgroundColor: "transparent"
  };

  if (backgroundColorIndex !== undefined && backgroundColorIndex < ManifestJobColorPalette.length) {
    styling = {...styling, ...ManifestJobColorPalette[backgroundColorIndex]};
  }

  if (minimal) {
    styling = {...styling, ...MinimalStyles};
  }

  return (
    <ManifestJobBadge
      style={styling}
      data-testid={`manifest-job-stop-number-button-${node.id}`}
      intent={Intent.PRIMARY}
      interactive={true}
      minimal={true}
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        api.showLoadingOverlay();
        navigateToCmsOrder(authState.tenant!, authState.token!, data!.order.orderId, tenantPreferences).finally(() =>
          api.hideOverlay()
        );
      }}
    >
      <LinkText fontSize={fontSize} color={color} title={`Navigate to Order ${data!.order.orderId}`} ellipsize>
        {value}
      </LinkText>
    </ManifestJobBadge>
  );
};
