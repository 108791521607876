import {Dispatch, useCallback, useContext, useEffect, useReducer, useRef} from "react";
import {JobAssignmentActions, JobAssignmentAsyncActions, JobAssignmentState} from "../../common/JobAssignmentReducer";
import {JobAssignmentViewState, JobViewActions} from "../../../views/JobAssignmentViewReducer";
import ManifestDetailsV2 from "./ManifestDetailsV2";
import {
  Driver,
  ManifestAttributeFilter,
  ManifestFilter,
  useGetEtaQuery,
  useSearchDriversLazyQuery,
  useSearchManifestsQuery
} from "../../../generated/graphql";
import {Address} from "../../../domain/geocode-types";
import {ManifestFilterState} from "../ManifestFilterState";
import {DispatchStationDataContext} from "../../common/DispatchStationDataProvider";
import {makeQueryStringFilter} from "../../../utils/QueryUtils";
import ManifestDetailsManifestSelector from "./ManifestDetailsManifestSelector";
import manifestDetailsReducer, {ETAStop, ManifestDetailsState, TExtraManifest} from "./ManifestDetailsReducer";
import {ManifestDetailsChangeContext} from "./ManifestDetailsChangeEventProvider";
import {CardType} from "../../../views/JobAssignmentView";
import {FeatureFlagContext} from "../../../providers/FeatureFlagProvider";
import {cloneDeep} from "lodash";

type ManifestDetailsContainerProps = {
  manifestDriverId: number;
  onClose(): void;
  assignmentViewState: JobAssignmentViewState;
  assignmentViewStateDispatch: Dispatch<JobViewActions>;
  jobAssignmentState: JobAssignmentState;
  jobAssignmentDispatch: Dispatch<JobAssignmentActions | JobAssignmentAsyncActions>;
  onChangedView(type: CardType): void;
  showMap?: boolean;
  isTestingEnvironment?: boolean;
};

type DetailViews = "NONE" | "MAP" | "ASSIGNDRIVER";

const InitialManifestDetailsState: ManifestDetailsState = {
  disabledManifestIds: [],
  selectedManifest: undefined,
  selectedManifestDriver: undefined,
  detailsViewType: "NONE"
};

const ManifestDetailsContainer = ({
  manifestDriverId,
  onClose,
  assignmentViewState,
  assignmentViewStateDispatch,
  jobAssignmentState,
  jobAssignmentDispatch,
  onChangedView,
  showMap = true,
  isTestingEnvironment = false
}: ManifestDetailsContainerProps) => {
  const {etas: isETAEnabled} = useContext(FeatureFlagContext);
  const [getDriver] = useSearchDriversLazyQuery({});

  const [manifestDetailsState, manifestDetailsDispatch] = useReducer(manifestDetailsReducer, {
    ...InitialManifestDetailsState,
    detailsViewType: showMap ? "MAP" : "NONE",
    manifestId: Number(manifestDriverId),
    assignDriverManifestFilterState: assignmentViewState.manifestFilters
      ? assignmentViewState.manifestFilters.copy()
      : new ManifestFilterState()
  });

  const dispatchStationState = useContext(DispatchStationDataContext);
  const dataChangedState = useContext(ManifestDetailsChangeContext);
  const lastReFetch = useRef(0);

  const {
    data: manifestData,
    error: manifestError,
    loading: manifestLoading,
    refetch: refetchManifest
  } = useSearchManifestsQuery({
    variables: {
      filter: {
        manifest: {
          manifestDriverId: {
            eq: Number(manifestDriverId)
          }
        }
      }
    },
    fetchPolicy: "network-only"
  });

  const {
    data: ETAData,
    error: ETAError,
    loading: ETALoading,
    refetch: refetchETA
  } = useGetEtaQuery({
    variables: {etaRequest: {manifestDriverId: manifestDriverId}},
    skip: !isETAEnabled,
    fetchPolicy: "network-only"
  });

  const handleCloseManifestDetail = useCallback(() => {
    manifestDetailsDispatch({type: "CLEAR_ALL_STATES"});
    onClose();
  }, [onClose]);

  const handleETAData = useCallback(
    (newManifestData: TExtraManifest) => {
      console.debug("handling ETA data");
      if (ETAError) {
        console.error("Error fetch ETA of manifest", JSON.stringify(ETAError));
        return;
      }
      if (!ETALoading && ETAData?.getETA?.items) {
        ETAData?.getETA?.items.forEach((item) => {
          const updateStops: ETAStop[] = [...cloneDeep(newManifestData.stops ?? [])];
          const updatedStopIndex = updateStops.findIndex((s) => s.jobStopId === item.jobStopId) ?? -1;
          if (updatedStopIndex >= 0) {
            const updateStop: ETAStop = {
              ...updateStops[updatedStopIndex],
              eta: item.eta,
              etaGeneratedDateTime: item.generatedDateTime
            };
            updateStops[updatedStopIndex] = updateStop;
          }
          newManifestData.stops = updateStops;
        });
      }
    },
    [ETAData?.getETA?.items, ETAError, ETALoading]
  );

  useEffect(() => {
    if (
      dataChangedState &&
      dataChangedState.updated &&
      dataChangedState.entityId === manifestDriverId.toString() &&
      dataChangedState.updated > lastReFetch.current
    ) {
      lastReFetch.current = dataChangedState.updated + 500; //500 ms debounce - 2 events are coming in, we only need to refetch once
      refetchManifest();
      if (isETAEnabled) {
        refetchETA();
      }
    }
  }, [dataChangedState, isETAEnabled, manifestDriverId, refetchETA, refetchManifest]);

  useEffect(() => {
    const result = {
      manifest: {
        and: [] as ManifestAttributeFilter[]
      }
    } as ManifestFilter;

    result.manifest?.and?.push({
      or: [
        {manifestStatus: {match: "A"}},
        {manifestStatus: {match: "Q"}},
        {manifestStatus: {match: "I"}},
        {manifestStatus: {match: "F"}}
      ]
    });

    if (dispatchStationState.hasSelectedDispatchStations()) {
      result.manifest?.and?.push({
        or: Array.from(dispatchStationState.selectedDispatchStations.values()).flatMap((ds) => {
          return Array.from(ds.driverGroups).map((dg) => {
            return {
              dispatchGroup_dispatchGroupId: {eq: dg}
            } as ManifestAttributeFilter;
          });
        })
      });
    }

    manifestDetailsState.assignDriverManifestFilterState?.appendToFilter(result);

    if (manifestDetailsState.assignDrvierManifestQuery) {
      result.queryString = makeQueryStringFilter(manifestDetailsState.assignDrvierManifestQuery, [
        "driver.name",
        "driver.code",
        "manifestDriverId",
        "stops.job.jobNumber",
        "stops.job.routeNumber"
      ]);
    }

    console.debug("ManifestFilter: ", result);

    manifestDetailsDispatch({type: "SET_ASSIGNDRIVER_MANIFEST_FILTER", payload: result});
  }, [
    dispatchStationState,
    manifestDetailsState.assignDriverManifestFilterState,
    manifestDetailsState.assignDrvierManifestQuery
  ]);

  useEffect(() => {
    if (manifestError) {
      console.error("Error loading manifest", JSON.stringify(manifestError));
      return;
    }
    if (!manifestLoading && manifestData?.searchManifests?.items[0]) {
      const newManifestData = cloneDeep(manifestData?.searchManifests?.items[0]) as TExtraManifest;
      if (isETAEnabled) {
        handleETAData(newManifestData);
      }
      manifestDetailsDispatch({
        type: "SET_SELECTED_MANIFEST",
        payload: newManifestData
      });
    }
  }, [handleETAData, isETAEnabled, manifestData, manifestError, manifestLoading]);

  useEffect(() => {
    const loadDriver = async () => {
      const getDriverResult = await getDriver({
        variables: {
          filter: {
            driverId: {
              eq: manifestDetailsState.selectedManifest?.driver?.driverId as number
            }
          }
        }
      });

      if (getDriverResult.data?.searchDrivers?.items) {
        const driver = getDriverResult.data?.searchDrivers?.items[0] as Driver;
        manifestDetailsDispatch({
          type: "SET_SELECTED_MANIFEST_DRIVER",
          payload: driver
        });

        if (driver?.address && driver?.city && driver?.state && driver?.zip) {
          const homeAddress = new Address(driver?.address, driver?.city, driver?.state, driver?.zip);
          console.debug("Setting driver home address:", homeAddress);
          manifestDetailsDispatch({
            type: "SET_MANIFEST_DRIVER_HOME_ADDRESS",
            payload: homeAddress
          });
        }
      }
    };

    if (manifestDetailsState.selectedManifest) loadDriver().then(() => console.debug("Driver loaded"));
  }, [getDriver, manifestDetailsState.selectedManifest?.driver?.driverId, manifestDetailsState.selectedManifest]);

  return (
    <ManifestDetailsV2
      manifestDriverId={manifestDetailsState.manifestId ?? 0}
      manifestDetailsState={manifestDetailsState}
      manifestDetailsDispatch={manifestDetailsDispatch}
      jobAssignmentDispatch={jobAssignmentDispatch}
      jobAssignmentState={jobAssignmentState}
      assignmentViewState={assignmentViewState}
      assignmentViewStateDispatch={assignmentViewStateDispatch}
      onClose={handleCloseManifestDetail}
      detailViewType={manifestDetailsState.detailsViewType}
      isTestingEnvironment={isTestingEnvironment}
      assignmentManifestList={
        <ManifestDetailsManifestSelector
          highlightQualifiedDrivers={false}
          jobAssignmentState={jobAssignmentState}
          jobAssignmentDispatch={jobAssignmentDispatch}
          jobAssignmentViewState={assignmentViewState}
          jobAssignmentViewStateDispatch={assignmentViewStateDispatch}
          manifestDetailsDispatch={manifestDetailsDispatch}
          manifestDetailsState={manifestDetailsState}
          onChangedView={onChangedView}
        />
      }
    />
  );
};

export default ManifestDetailsContainer;
export type {ManifestDetailsContainerProps, DetailViews};
