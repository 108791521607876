import React, {Dispatch, useCallback, useContext, useState} from "react";
import styled from "@emotion/styled";
import {Button, Dialog, InputGroup, Spinner, Text} from "@blueprintjs/core";
import InfiniteScroll from "react-infinite-scroll-component";
import {debounce} from "lodash";
import {
  Job,
  Manifest,
  MultiMatchOperator,
  MultiMatchType,
  SearchableSortDirection,
  useAssignJobsToManifestMutation,
  useSearchManifestsLazyQuery
} from "../../generated/graphql";
import InitContent from "./components/InitContent";
import ManifestItem, {TOnAssigedJobFunction} from "./components/ManifestItem";
import {getArrayFromField} from "../../utils/General";
import {useGetJob} from "../hooks/useGetJob";
import {Constants} from "../common/Constants";
import {JobAssignmentActions, JobAssignmentAsyncActions, JobAssignmentState} from "../common/JobAssignmentReducer";
import {AppContext} from "../../ApplicationContext";
import {useAuthState} from "../AuthProvider";
import {activePendingActions} from "../../utils/PendingAssignment";

interface IFindModalProps {
  assignmentStateDispatch: Dispatch<JobAssignmentActions | JobAssignmentAsyncActions>;
  assignmentState: JobAssignmentState;
}

export enum JobErrorMessage {
  ErrorOccurred = "An error occurred. Please try again later!",
  JobNumberNotExist = "This Job number does not exist",
  AlreadyAssigned = "This Job is already assigned to Driver",
  AlreadySummitted = "This Job is already summitted",
  RequireInput = "Please enter a Job number"
}

const GlobalFind = ({assignmentState, assignmentStateDispatch}: IFindModalProps) => {
  const {dispatch} = useContext(AppContext);
  const authState = useAuthState();
  const [initiallizing, setInitiallizing] = useState(true);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [manifests, setManifests] = useState<Manifest[]>([]);
  const [totalManifests, setTotalManifests] = useState(0);
  const [selectedManifestId, setSelectedManifestId] = useState<number | undefined>(undefined);

  const [searchManifest, {refetch}] = useSearchManifestsLazyQuery({
    fetchPolicy: "network-only"
  });
  const [assignJobsToManifest] = useAssignJobsToManifestMutation();

  const {getJob} = useGetJob();

  const onClose = useCallback(() => {
    dispatch({type: "SetIsGlobalFindOpen", payload: false});
  }, [dispatch]);

  const onAssigedJob: TOnAssigedJobFunction = useCallback(
    async (manifest, jobNumber) => {
      const {data, errors} = await getJob(jobNumber);

      if (errors) {
        return {success: false, message: JobErrorMessage.ErrorOccurred};
      }
      if (data?.searchJobs?.items.length > 0) {
        const foundedJob = data.searchJobs.items[0] as Job;
        const pendingAction = activePendingActions(assignmentState.pendingActions).find((x) => {
          return x.jobIds.includes(foundedJob.jobId);
        });
        if (pendingAction) {
          return {success: false, message: JobErrorMessage.AlreadySummitted};
        }
        if (Constants.UNASSIGNED_JOB_STATUSES.includes(foundedJob.jobStatus)) {
          assignmentStateDispatch({
            type: "AssignJobsToManifest",
            payload: {
              isFromGlobalFind: true,
              assignFunction: assignJobsToManifest,
              authState: authState,
              selectedJobs: [foundedJob],
              selectedManifest: manifest
            }
          });
          return {success: true, message: "Success"};
        } else {
          return {success: false, message: `${JobErrorMessage.AlreadyAssigned} ${foundedJob.driver?.driverCode}`};
        }
      }
      return {success: false, message: JobErrorMessage.JobNumberNotExist};
    },
    [assignJobsToManifest, assignmentState.pendingActions, assignmentStateDispatch, authState, getJob]
  );

  const onSelectItem = useCallback((manifestDriverId: number) => {
    setSelectedManifestId(manifestDriverId);
  }, []);

  const onSearchManifest = useCallback(
    (keyword: string, offset?: number) => {
      searchManifest({
        variables: {
          filter: {
            multiMatch: {
              fields: ["driver.code", "stops.job.jobNumber", "stops.job.routeNumber"],
              query: keyword,
              operator: MultiMatchOperator.Or,
              type: MultiMatchType.Phrase
            },
            manifest: {
              or: [
                {manifestStatus: {match: "A"}},
                {manifestStatus: {match: "Q"}},
                {manifestStatus: {match: "I"}},
                {manifestStatus: {match: "F"}}
              ]
            }
          },
          limit: 10,
          offset: offset,
          sort: [
            {
              field: "driver.code.keyword",
              direction: SearchableSortDirection.Asc
            },
            {
              field: "manifestDate",
              direction: SearchableSortDirection.Asc
            },
            {
              field: "manifestDriverId",
              direction: SearchableSortDirection.Asc
            }
          ]
        },
        fetchPolicy: "network-only",
        onCompleted(data) {
          setManifests((prev) => [...prev, ...getArrayFromField(data.searchManifests?.items)] as Manifest[]);
          setLoading(false);
          setTotalManifests(data.searchManifests?.total ?? 0);
        },
        onError() {
          setTimeout(() => {
            console.debug("refetching");
            refetch();
          }, 1500);
        }
      });
    },
    [refetch, searchManifest]
  );

  const onLoadMore = useCallback(() => {
    onSearchManifest(query, manifests.length);
  }, [manifests.length, onSearchManifest, query]);

  const handleQueryChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const queryString = event.target.value;
      setQuery(queryString);
      setManifests([]);
      setSelectedManifestId(undefined);
      if (queryString.length > 0) {
        setInitiallizing(false);
        setLoading(true);
        onSearchManifest(queryString);
      }
      if (queryString.length === 0) {
        setInitiallizing(true);
      }
    },
    [onSearchManifest]
  );

  const renderBody = useCallback(() => {
    if (loading) {
      return (
        <CenterDiv>
          <Spinner size={25} />
        </CenterDiv>
      );
    }

    if (initiallizing) {
      return <InitContent />;
    }

    if (manifests.length === 0) {
      return (
        <CenterDiv>
          <NotFoundText data-testid="not-found-text">
            We couldn’t find any manifests with a matching job number, driver number, or route number.
          </NotFoundText>
        </CenterDiv>
      );
    }

    return (
      <InfiniteScroll
        next={onLoadMore}
        dataLength={manifests.length}
        hasMore={manifests.length < totalManifests}
        scrollableTarget={"option-infinity-scroll-list"}
        loader={""}
      >
        <List>
          {manifests.map((item) => {
            return (
              <ManifestItem
                key={item.manifestDriverId}
                item={item}
                isSelected={item.manifestDriverId === selectedManifestId}
                onSelect={onSelectItem}
                onAssigedJob={onAssigedJob}
              />
            );
          })}
        </List>
      </InfiniteScroll>
    );
  }, [initiallizing, loading, manifests, onAssigedJob, onLoadMore, onSelectItem, selectedManifestId, totalManifests]);

  return (
    <StyledDialog isOpen={true} onClose={onClose} canOutsideClickClose={false} portalClassName="global-find-portal">
      <Header data-testid="global-find-header">
        <StyledInput
          placeholder="Search..."
          onChange={debounce(handleQueryChange, 500)}
          data-testid="search-manifest-input"
          autoFocus
        />
        <Button className="close-button" data-testid="close-button" icon="cross" minimal onClick={onClose} />
      </Header>
      <Body id={"option-infinity-scroll-list"} data-testid="global-find-body">
        {renderBody()}
      </Body>
    </StyledDialog>
  );
};

export default GlobalFind;

const StyledDialog = styled(Dialog)`
  align-self: flex-start;
  margin-top: 25vh;
  width: 35vw;
  min-width: 600px;
  max-width: 770px;
  max-height: 355px;
  border-radius: 16px;
  background: #ffffff;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  overflow: hidden;
`;

const Header = styled.div`
  position: relative;
  border-bottom: solid 1px #e5e7eb;

  .close-button {
    position: absolute;
    right: 12px;
    top: 12px;
  }
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
`;

const StyledInput = styled(InputGroup)`
  flex: 1;

  .bp4-input {
    height: 52px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 0 48px 0 16px;
    border: none;
    box-shadow: none;
  }
`;

const List = styled.ul`
  list-style: none;
  text-overflow: ellipsis;
  padding: 0;
  margin: 0;
`;

const NotFoundText = styled(Text)`
  text-align: center;
  font-style: italic;
  color: #797979;
  padding: 30px 16px;
`;

const CenterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 220px;
`;
