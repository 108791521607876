import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {TooManyStopsWarningMessage} from "../../StatusIconV3";
import {Icon} from "@blueprintjs/core";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const InfoIcon = styled(FontAwesomeIcon)`
  color: #797979;
`;

const TextBox = styled.div`
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 132.19%;
  text-align: left;
  width: 326px;
  padding-left: 20px;
  color: #161616;
`;

interface ManifestDetailsNoRowsOverlayProps {
  hasTooManyStops: boolean;
}

const ManifestDetailsNoRowsOverlay = ({hasTooManyStops}: ManifestDetailsNoRowsOverlayProps) => {
  let content = (
    <>
      <InfoIcon icon={solid("circle-info")} size={"2xl"} />
      <TextBox data-testid="empty-manifest-content">
        This Driver Manifest is empty. Once you assign jobs to this manifest, it will reflect in this page.
      </TextBox>
    </>
  );
  if (hasTooManyStops) {
    content = (
      <>
        <Icon icon="warning-sign" color="#FA545E" size={52} />
        <TextBox data-testid="too-many-stops-manifest-content">{TooManyStopsWarningMessage}</TextBox>
      </>
    );
  }
  return <Container data-testid="no-rows-overlay">{content}</Container>;
};

export default ManifestDetailsNoRowsOverlay;
