import {QueryStringFilter} from "../generated/graphql";

export const makeQueryStringFilter = (query: string, fields: string[]) => {
  const escapedQuery = query.includes(" ") ? `"${query}"` : `*${query}*`;
  return {
    query: escapedQuery,
    fields: fields
  } as QueryStringFilter;
};

export const buildQuery = (cols: string[]) => {
  const set = (o: {[key: string]: any} = {}, a: string[]) => {
    const k = a.shift() as string;
    o[k] = a.length ? set(o[k], a) : null;
    return o;
  };

  const o = cols.reduce((o: object, a: string) => set(o, a.split(".")), {});

  return JSON.stringify(o)
    .replace(/"|:|null/g, "")
    .replace(/^\{/, "")
    .replace(/}$/, "");
};
