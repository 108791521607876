import {Button} from "@blueprintjs/core";
import styled from "@emotion/styled";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

interface IToolPanelProps {
  onToggleToolPanel: (key: string) => void;
}

const ToolPanel = ({onToggleToolPanel}: IToolPanelProps) => {
  return (
    <Container>
      <Button
        data-testid="toolpane-button"
        minimal
        title={"Columns"}
        icon={<FontAwesomeIcon style={{width: "16px", height: "14px"}} icon={solid("line-columns")} />}
        onClick={() => {
          onToggleToolPanel("columns");
        }}
      />
      <Button
        data-testid="toolpane-button"
        minimal
        title={"Settings"}
        icon={<FontAwesomeIcon style={{width: "16px", height: "15px"}} icon={solid("sliders")} />}
        onClick={() => {
          onToggleToolPanel("settings");
        }}
      />
    </Container>
  );
};
ToolPanel.displayName = "Toolpane";
export default ToolPanel;

const Container = styled.div`
  display: flex;
  gap: 4px;
`;
