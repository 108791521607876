import React, {Fragment, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, InputGroup} from "@blueprintjs/core";
import styled from "@emotion/styled";
import {Tooltip2} from "@blueprintjs/popover2";
import {debounce} from "lodash";
import {Constants} from "./Constants";

type SearchBarProps = {
  onSearch: (query: string) => void;
  idPrefix?: string;
  isConstrainedByFilters?: boolean;
  minimal?: boolean;
  minWidth?: string;
  width?: string;
  onOpenChanged?: React.Dispatch<React.SetStateAction<boolean>>;
};

const WarningIcon = styled(FontAwesomeIcon)`
  padding-right: 4px;
`;

const Tooltip = styled(Tooltip2)``;

type SearchInputGroupProps = {
  inError: boolean;
  width?: string;
  minWidth?: string;
};

const SearchInputGroup = styled(InputGroup)<SearchInputGroupProps>`
  .bp4-input {
    box-shadow: ${(props) =>
      props.inError
        ? "0 0 0 0 rgba(250, 84, 94, 0), 0 0 0 0 rgba(250, 84, 94, 0), inset 0 0 0 1px rgba(250, 84, 94, 0.2), inset 0 1px 1px rgba(250, 84, 94, 0.5)"
        : "0 0 0 0 rgba(45, 114, 210, 0), 0 0 0 0 rgba(45, 114, 210, 0), inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.5)"};
    border-radius: 6px;
    width: ${(props) => props.width ?? ""};
    min-width: ${(props) => props.minWidth ?? ""};
  }
`;

enum Messages {
  INVALID_CHARATERS = "Could not search: Invalid characters",
  LIMITED_CHARATER = "Search is limited to alpha-numerics, spaces, dashes, dots, and underscores",
  INVALID_SEARCH_LENGTH = "Search must contain at least 2 characters"
}

const queryMeetsLengthRequirement = (queryString: string) => {
  return queryString.length === 0 || queryString.length >= 2;
};

const Search = ({
  idPrefix = "",
  isConstrainedByFilters = false,
  onSearch,
  minWidth,
  width,
  minimal = false,
  onOpenChanged
}: SearchBarProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isSearchOpened, setIsSearchOpened] = useState(false);
  const [query, setQuery] = useState<string>("");
  const [showActiveFilters, setShowActiveFilters] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");

  const queryValidator = useCallback((queryString: string) => {
    if (Constants.REGEX.INVALID_SEARCH_CHARACTER_REGEX.test(queryString)) {
      setErrorMessage(Messages.INVALID_CHARATERS);
      return false;
    }
    if (!queryMeetsLengthRequirement(queryString)) {
      return false;
    }
    return true;
  }, []);

  const handleQueryChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const queryString = event.target.value;
    if (!queryMeetsLengthRequirement(queryString)) {
      setErrorMessage(Messages.INVALID_SEARCH_LENGTH);
    }
    setQuery(queryString);
  }, []);

  const debouncedChangeHandler = useMemo(() => debounce(handleQueryChange, 1000), [handleQueryChange]);

  const clearErrorMesage = useCallback(() => {
    setErrorMessage("");
  }, []);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        debouncedChangeHandler.flush();
        return;
      }
      if (Constants.REGEX.INVALID_SEARCH_CHARACTER_REGEX.test(event.key)) {
        event.preventDefault();
        setErrorMessage(Messages.LIMITED_CHARATER);
      } else if (!event.altKey && !event.shiftKey && !event.ctrlKey && !event.metaKey) {
        clearErrorMesage();
      }
    },
    [clearErrorMesage, debouncedChangeHandler]
  );

  const handleOpenSearch = () => {
    setIsSearchOpened(true);
    onOpenChanged?.(true);
  };

  const clearSearchInput = useCallback(() => {
    setIsSearchOpened(false);
    onOpenChanged?.(false);
    clearErrorMesage();
    debouncedChangeHandler.cancel();
    if (inputRef.current) inputRef.current.value = "";
    setQuery("");
  }, [clearErrorMesage, debouncedChangeHandler, onOpenChanged]);

  const onFocusSearchInput = useCallback(() => {
    setShowActiveFilters(isConstrainedByFilters);
  }, [isConstrainedByFilters]);

  const onBlurSearchInput = useCallback(() => {
    setShowActiveFilters(false);
  }, []);

  useEffect(() => {
    if (queryValidator(query)) {
      clearErrorMesage();
      onSearch(query);
    }
  }, [clearErrorMesage, onSearch, query, queryValidator]);

  const isOpen = useMemo(() => {
    return Boolean(errorMessage) || showActiveFilters;
  }, [errorMessage, showActiveFilters]);

  return (
    <Container>
      {minimal && !isSearchOpened ? (
        <Button
          minimal
          data-testid={`${idPrefix}-search-btn`}
          type={"button"}
          title={"Search"}
          onClick={handleOpenSearch}
          icon={<FontAwesomeIcon icon={solid("magnifying-glass")} />}
        />
      ) : (
        <Tooltip
          isOpen={isOpen}
          portalClassName={errorMessage ? `${idPrefix}-searchbar-error` : `${idPrefix}-searchbar-warning`}
          content={
            <>
              {showActiveFilters && !errorMessage && (
                <Fragment>
                  <WarningIcon color="#FF6B00" icon={solid("circle-exclamation")} />
                  <span>Search is constrained by filters</span>
                </Fragment>
              )}
              {errorMessage && (
                <Fragment>
                  <WarningIcon
                    data-testid={`${idPrefix}-search-error-msg`}
                    color="#FA545E"
                    icon={solid("triangle-exclamation")}
                  />
                  <span>{errorMessage}</span>
                </Fragment>
              )}
            </>
          }
          position="top"
        >
          <SearchInputGroup
            inputRef={inputRef}
            width={width}
            minWidth={minWidth}
            data-testid={`${idPrefix}-search-input`}
            autoFocus={minimal}
            inError={!!errorMessage}
            leftIcon={"search"}
            rightElement={
              query || minimal ? (
                <Button
                  minimal
                  data-testid={`${idPrefix}-search-close-btn`}
                  title={"Clear"}
                  onClick={clearSearchInput}
                  icon={"cross"}
                  hidden={true}
                />
              ) : (
                <></>
              )
            }
            onChange={debouncedChangeHandler}
            onKeyDown={handleKeyDown}
            onFocus={onFocusSearchInput}
            onBlur={onBlurSearchInput}
          />
        </Tooltip>
      )}
    </Container>
  );
};

export default Search;

const Container = styled.div`
  align-self: center;
`;
