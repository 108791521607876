import axios from "axios";
import {ManifestStop, Maybe, NestedJobStop, RouteOptimizationUnassignedDetail} from "../generated/graphql";
import {Constants, EmptyValueStrings} from "../components/common/Constants";
import {isArray} from "lodash";
import {DateTime} from "luxon";
import {addMinutes, formatISO, intervalToDuration} from "date-fns";
import {StopLocationValue} from "../components/manifest/card-configuration/ManifestConfigurationTypes";
import {CardType} from "../views/JobAssignmentView";
import {SortDirection} from "@ag-grid-community/core";
import {JobStopStatus} from "../services/ManifestStopService";

export const arrayDeleteElsePush = (
  arr: any[],
  element: any,
  predicate?: (value: any, index: number, obj: any[]) => unknown
) => {
  const index = predicate ? arr.findIndex(predicate) : arr.indexOf(element);
  if (index > -1) {
    return [...arr.slice(0, index), ...arr.slice(index + 1)];
  } else {
    return [...arr, element];
  }
};

/**
 * returns map equality
 * @param m1
 * @param m2
 */
export function mapCompare<K, V>(m1: Map<K, V>, m2: Map<K, V>): boolean {
  if (m1.size !== m2.size) return false;

  for (const [key, v] of m1) {
    if (!m2.has(key)) return false;

    const v2 = m2.get(key);
    if (!v2) return false;

    if (JSON.stringify(v) !== JSON.stringify(v2)) return false;
  }

  return true;
}
export const getLatLongFromString = (input: string) => {
  const [lat, long] = input.split(",").map(parseFloat);
  return {lat, long};
};
export const fetchAddress = async (lat: number, long: number, fn?: (address: string) => void) => {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
    );

    if (fn && response.data.results.length > 0) {
      fn(response.data.results[0].formatted_address);
    }
    return response.data.results[0].formatted_address;
  } catch (error) {
    console.error("Error fetching address:", error);
  }
};
export interface InvalidAddresses {
  nameOfLocation?: string;
  manifestSequence?: string;
  address: string | undefined | null;
}
export const filterInvalidStartEndLocation = async (startingLocation: string, endLocation: string | undefined) => {
  if (!startingLocation && !endLocation) return [];
  const invalidStartEndLocation: InvalidAddresses[] = [];
  const requestLocations: InvalidAddresses[] = [
    {
      nameOfLocation: "Starting Location",
      address: startingLocation
    },
    {
      nameOfLocation: "End Location",
      address: endLocation
    }
  ];
  for (const requestLocation of requestLocations) {
    const requestAddress = requestLocation.address;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      requestAddress ?? ""
    )}&key=${Constants.GOOGLE_API_KEY}`;
    try {
      const response = await axios.get(url);
      const data = response.data;
      if (data.status !== "OK") {
        invalidStartEndLocation.push(requestLocation);
      }
    } catch (error) {
      console.error("Error found in start or end location:", error);
    }
  }
  return invalidStartEndLocation;
};
export const filterInvalidAddresses = async (arrayOfAddress: any) => {
  if (!arrayOfAddress) return;
  const invalidAddresses = [];
  for (const {manifestSequence, address, city, state, zip} of arrayOfAddress) {
    const addressOfStop = `${address}, ${city}, ${state} ${zip}`;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      addressOfStop || ""
    )}&key=${Constants.GOOGLE_API_KEY}`;
    try {
      const response = await axios.get(url);
      const data = response.data;
      if (data.status !== "OK") {
        invalidAddresses.push({
          manifestSequence,
          address: addressOfStop
        });
      }
    } catch (error) {
      console.error("Error checking address:", error);
    }
  }
  return invalidAddresses;
};

export function groupDetailsByCode(
  detailsArray: RouteOptimizationUnassignedDetail[] | undefined
): RouteOptimizationUnassignedDetail[][] {
  const codeGroups: RouteOptimizationUnassignedDetail[][] = [];
  if (!detailsArray) return [];
  detailsArray.forEach((detail) => {
    const code = detail.code || 0;
    if (!codeGroups[code]) {
      codeGroups[code] = [];
    }

    codeGroups[code].push(detail);
  });

  return codeGroups.filter((group) => group.length > 0);
}

export const capitalizeWords = (str: string) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};
export const getArrayFromField = (value: any) => {
  if (!value) {
    return [];
  }
  return isArray(value) ? value : [value];
};

export const getOptionsFromArrayOfObjects = (array: any[], key: string, value: string) => {
  if (!array) return [];
  const options: {key: any; value: any}[] = [];
  array.forEach((item: any) => {
    options.push({key: item[key], value: item[value]});
  });
  return options;
};

export const getKeysFromArrayOfObjects = (array: any[], key: string) => {
  if (!array) return [];
  const keys: any[] = [];
  array.forEach((item: any) => {
    keys.push(item[key]);
  });
  return keys;
};

export interface findEarliestDateOutput {
  date: Date | undefined;
  dateFormat: string;
}

export const formatDate = (date: Date): string => {
  const monthNames = ["Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun.", "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."];

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return `${monthNames[monthIndex]} ${day}, ${year}`;
};

export function combineDateAndTime(timeDate: Date, dateDate: Date): Date {
  const hours = timeDate.getHours();
  const minutes = timeDate.getMinutes();
  const seconds = timeDate.getSeconds();
  const year = dateDate.getFullYear();
  const month = dateDate.getMonth();
  const day = dateDate.getDate();
  const combinedDateTime = new Date(year, month, day, hours, minutes, seconds);

  return combinedDateTime;
}

export async function getTimeZoneIdByAddress(address: string): Promise<string | undefined> {
  try {
    const geocodeResponse = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${
        Constants.GOOGLE_API_KEY
      }`
    );

    const results = geocodeResponse.data.results;
    if (results.length > 0) {
      const location = results[0].geometry.location;
      const latitude = location.lat;
      const longitude = location.lng;
      return await getTimeZoneIdByLatLng(latitude, longitude);
    }
  } catch (error) {
    console.error("Error getting location data.", error);
    return undefined;
  }
}

export async function getTimeZoneIdByLatLng(lat: number, lng: number): Promise<string | undefined> {
  try {
    const timeZoneResponse = await axios.get(
      `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${Date.now() / 1000}&key=${
        Constants.GOOGLE_API_KEY
      }`
    );

    if (timeZoneResponse.data.status === "OK") {
      return timeZoneResponse.data.timeZoneId;
    } else {
      return undefined;
    }
  } catch (error) {
    console.error("Error getting timezone data.", error);
    return undefined;
  }
}

export function timezoneToOffset(timezone: string | undefined): number | null {
  try {
    const dt = DateTime.now().setZone(timezone);
    const offsetMinutes = dt.offset;

    return offsetMinutes;
  } catch (error) {
    console.error(`Error converting timezone to offset: ${error}`);
    return null;
  }
}
export function convertISOStringWithOffset(
  isoString: string | null,
  offsetMinutes: number | null,
  revert = false
): Date | undefined {
  try {
    if (!isoString || offsetMinutes === null || offsetMinutes === undefined) return undefined;
    const date = new Date(isoString);
    const timezoneOffset = new Date().getTimezoneOffset();
    const adjustedOffset = revert ? -1 * (timezoneOffset + offsetMinutes) : timezoneOffset + offsetMinutes;
    return addMinutes(date, adjustedOffset);
  } catch (error) {
    console.error(`Error converting ISO string to date: ${error}`);
    return undefined;
  }
}

export const convertDateWithTimeZone = (
  date: Date | null | undefined,
  timezone: string,
  revert = false
): Date | undefined => {
  if (!date || isNaN(date.getDate())) return undefined;

  const dateToIOSstring = formatISO(date);
  const offset = timezoneToOffset(timezone);
  return revert
    ? convertISOStringWithOffset(dateToIOSstring, offset, true)
    : convertISOStringWithOffset(dateToIOSstring, offset);
};
export const isSameDate = (date1: Date | undefined, date2: Date | undefined) => {
  if (!date1 || !date2) return false;
  return date1?.toLocaleDateString() === date2?.toLocaleDateString();
};

export const arrayContainsArray = (superset: any[], subset: any[]) => {
  return subset.every(function (value) {
    return superset.indexOf(value) >= 0;
  });
};

type GetAddressText = {
  stop: ManifestStop | NestedJobStop;
  hasDispatchZone?: boolean;
  stopLocationState?: StopLocationValue;
};

export const getAddressText = ({stop, stopLocationState, hasDispatchZone}: GetAddressText) => {
  const arrayAddress = [];
  switch (stopLocationState) {
    case StopLocationValue.CityStateZone: {
      if (stop.city) arrayAddress.push(stop.city);
      if (stop.state) arrayAddress.push(stop.state);
      if (stop.dispatchZone) arrayAddress.push(stop.dispatchZone);
      break;
    }
    case StopLocationValue.ZoneOnly: {
      if (stop.dispatchZone) arrayAddress.push(stop.dispatchZone);
      break;
    }
    default: {
      if (stop.address) arrayAddress.push(stop.address);
      if (stop.city) arrayAddress.push(stop.city);
      if (stop.state) arrayAddress.push(stop.state);
    }
  }
  if (hasDispatchZone && stop.dispatchZone) arrayAddress.push(stop.dispatchZone);
  return arrayAddress.join(", ");
};

export function findObjectByCondition(array: any[], key: string, value: any) {
  for (const obj of array) {
    if (obj[key] === value) {
      return obj;
    }
  }
  return null;
}

export const isSmallCard = (cardType: CardType | undefined) => {
  if (cardType) {
    return [CardType.Compact, CardType.Minimal].includes(cardType);
  }
  return false;
};

export const shouldEllipsize = (text: string | null | undefined, size: number) => {
  if (!text) return false;
  return text.length > size;
};

export const getEllipsizeText = (text: string | undefined | null, size: number) => {
  if (!text) return EmptyValueStrings.unknown;
  return shouldEllipsize(text, size) ? text.substring(0, size - 3) + "..." : text;
};

export const addressSort = (a: string, b: string, direction?: SortDirection) => {
  const houseNumberRegex = /^(\d+)([A-Za-z]*)/;
  const houseNumberA = houseNumberRegex.exec(a) ?? [];
  const houseNumberB = houseNumberRegex.exec(b) ?? [];
  const directionValue = direction === "desc" ? -1 : 1;

  // compare number
  const numA = parseInt(houseNumberA[1]);
  const numB = parseInt(houseNumberB[1]);
  if (numA !== numB) {
    return (numA - numB) * directionValue;
  }
  //compare letter
  const alphaA = houseNumberA[2];
  const alphaB = houseNumberB[2];
  if (alphaA !== alphaB) {
    return alphaA.localeCompare(alphaB) * directionValue;
  }

  const restA = a.slice(houseNumberA[0]?.length);
  const restB = b.slice(houseNumberB[0]?.length);
  return restA.localeCompare(restB) * directionValue;
};

export const isHasUnsupportedStatus = (stops: Maybe<ManifestStop[]> | undefined) => {
  return stops?.some((stop) => {
    return !Object.values(JobStopStatus).includes(stop.jobStopStatus as any);
  });
};

export const millisecondsToDutation = (milliseconds: number | undefined) => {
  if (!milliseconds) return "0h 0m";
  const duration = intervalToDuration({start: 0, end: milliseconds});

  const {days, hours, minutes} = duration;

  return `${milliseconds < 0 ? "-" : ""}${days && days > 0 ? days + "d " : ""}${hours}h ${minutes}m`;
};

export const metersToMiles = (meters: number | undefined) => {
  if (!meters) return 0;
  return Number((meters * Constants.MeterToMileRatio).toFixed(2));
};
