import {gql, useApolloClient} from "@apollo/client";
import {JobFilter, JobSortInput, SearchableSortDirection} from "../../generated/graphql";
import {useCallback} from "react";

export const query = gql(/* GRAPHQL */ `
                    query SearchJobs($filter: JobFilter, $sort: [JobSortInput], $offset: Int, $limit: Int) {
                        searchJobs(filter: $filter, sort: $sort, offset: $offset, limit: $limit) {
                            items {
                                jobId
                                jobNumber
                                jobStatus
                                driver{
                                  driverCode
                                }
                                order{
                                  orderId
                                }
                            }
                            nextToken,
                            total
                        }
                    }
                `);
const jobSort: JobSortInput[] = [{field: "jobNumber", direction: SearchableSortDirection.Asc}];

export const useGetJob = () => {
  const apolloClient = useApolloClient();

  const getJob = useCallback(
    async (jobNumber: string) => {
      const jobFilter: JobFilter = {job: {jobNumber: {match: jobNumber}}};
      console.debug(jobNumber);
      const res = await apolloClient.query({
        query: query,
        fetchPolicy: "network-only",
        variables: {
          offset: 0,
          limit: 100,
          filter: jobFilter,
          sort: jobSort
        }
      });
      return res;
    },
    [apolloClient]
  );

  return {getJob};
};
