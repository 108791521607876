import {
  Alignment,
  Button,
  Classes,
  Icon,
  InputGroup,
  Intent,
  Menu,
  MenuItem,
  Navbar,
  Position
} from "@blueprintjs/core";
import {AppToaster} from "../utils/toaster";
import {AuthContext} from "./AuthProvider";
import {Constants, UserPreferences} from "./common/Constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {createAvatarComponent, GoogleSource, GravatarSource, IconSource, ValueSource} from "react-avatar";
import {Popover2} from "@blueprintjs/popover2";
import {switchTenants, Tenant, useTenantService} from "../services/TenantService";
import {env} from "../config";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {useHistory} from "react-router-dom";
import {useOktaAuth} from "@okta/okta-react";
import {Fragment, useCallback, useContext, useEffect, useMemo, useState} from "react";
import Scrollbars from "react-custom-scrollbars-2";
import {FeatureFlagContext} from "../providers/FeatureFlagProvider";
import useInterval, {IntervalFunction} from "./hooks/useInterval";
import {
  DispatchStationGroup,
  ModelDispatchStationGroupFilterInput,
  useSearchDispatchStationGroupsQuery,
  useSetUserPrefMutation
} from "../generated/graphql";
import {OptionType} from "./common/types/optionType";
import FilterableMultiSelect from "./common/FilterableMultiSelect";
import {andSearchWithWildcards} from "./settings/ColorizedIndicators/RuleGenerator.service";
import DispatchStation from "./common/types/DispatchStation";
import {DispatchStationDataContext} from "./common/DispatchStationDataProvider";
import {createJsonPref, extractJsonPref, PreferenceContext} from "../providers/PreferenceProvider";
import axios from "axios";
import {AppContext} from "../ApplicationContext";
import styled from "@emotion/styled";
import {useMapVisibilityContext} from "./map/MapVisibilityContext";

type AppProps = {
  authLogout(): Promise<void>;
  authLogin(): Promise<void>;
  refresh(): void;
  mainLoading(loading: boolean): void;
};

const CustomAvatar = createAvatarComponent({
  sources: [GravatarSource, ValueSource, IconSource, GoogleSource]
});

const determineLoadedVersion = async (): Promise<string> => {
  const versionRegex = /src="\/static\/js\/main\.(.*)\.js"/;
  return axios
    .get("/")
    .then(async (response) => response.data)
    .catch((reason) => {
      console.warn("Unable to determine version.", reason);
      return "";
    })
    .then((htmlText) => {
      const regexResult = versionRegex.exec(htmlText);
      if (regexResult && regexResult.length >= 2) {
        console.debug(`Returning loaded version ${regexResult[1]}`);
        return regexResult[1];
      } else {
        console.debug("no loaded version found.  Localhost?");
        return "";
      }
    });
};
const DispatchNavBar = ({authLogout, refresh, mainLoading}: AppProps) => {
  const history = useHistory();
  const {oktaAuth, authState} = useOktaAuth();
  const {user, token, tenantId, isAdmin, isSuperUser, tenants, isAuthenticated} = useContext(AuthContext);
  const {dispatch: mapVisibilityDispatch} = useMapVisibilityContext();
  const {error, loading, data} = useTenantService({url: "/list"});
  const [tenantFilter, setTenantFilter] = useState<string>();
  const {
    driverMessaging: messagingEnabled,
    globalFind: globalFindEnabled,
    allAssignedStop: allAssignedStopEnabled
  } = useContext(FeatureFlagContext);
  const dispatchStationState = useContext(DispatchStationDataContext);
  const {appState, dispatch} = useContext(AppContext);
  const {selectedDriverChannelId, selectedDriverUnreadCount, totalUnreadChannels} = appState;

  const [initialVersion, setInitialVersion] = useState("");
  const [currentVersion, setCurrentVersion] = useState("");
  const [updatePrompt, setUpdatePrompt] = useState(false);
  const [dispatchStations, setDispatchStations] = useState<Map<string, DispatchStation>>(
    new Map<string, DispatchStation>()
  );
  const [dispatchStationOptions, setDispatchStationOptions] = useState<OptionType[]>([]);
  const [selectedDispatchStations, setSelectedDispatchStations] = useState<Map<string, OptionType>>(
    new Map<string, OptionType>()
  );
  const [dispatchStationQuery, setDispatchStationQuery] = useState<string>("");
  const [dispatchStationFilter, setDispatchStationFilter] = useState<
    ModelDispatchStationGroupFilterInput | undefined
  >();
  const [setUserPref] = useSetUserPrefMutation();
  const {userPreferences} = useContext(PreferenceContext);
  const [initalRender, setInitalRender] = useState(true);

  const dispatchStationGroupsResults = useSearchDispatchStationGroupsQuery({
    variables: {
      filter: dispatchStationFilter ?? {},
      limit: Constants.DISPATCH_STATION_LIMIT
    },
    skip: !isAuthenticated
  });

  useEffect(() => {
    if (dispatchStationQuery?.trim()) {
      console.debug("Dispatch Station query changed.", dispatchStationQuery);
      const tokens = dispatchStationQuery.trim().split(" ");
      const descFilter: ModelDispatchStationGroupFilterInput = andSearchWithWildcards("description", tokens);
      const codeFilter: ModelDispatchStationGroupFilterInput = andSearchWithWildcards("dispatchStation", tokens);
      setDispatchStationFilter({
        or: [descFilter, codeFilter]
      });
    } else {
      setDispatchStationFilter(undefined);
    }
  }, [dispatchStationQuery]);

  useEffect(() => {
    if (dispatchStationGroupsResults.error) {
      console.error(
        "DispatchStations selected cannot load Dispatch Stations.",
        dispatchStationGroupsResults.error,
        dispatchStationGroupsResults.error.graphQLErrors
      );
    }

    if (!dispatchStationGroupsResults.loading && dispatchStationGroupsResults.data?.searchDispatchStationGroups) {
      const newDispatchStations: Map<string, DispatchStation> = (
        dispatchStationGroupsResults.data.searchDispatchStationGroups?.items as DispatchStationGroup[]
      ).reduce((currentDispatchStations, dispatchStation: DispatchStationGroup) => {
        let existingDispatchStation = currentDispatchStations.get(dispatchStation.dispatchStation);
        if (!existingDispatchStation) {
          existingDispatchStation = {
            stationId: dispatchStation.dispatchStation,
            description: dispatchStation.description ?? dispatchStation.dispatchStation,
            jobGroups: new Set<number>(),
            driverGroups: new Set<number>()
          } as DispatchStation;
          currentDispatchStations.set(existingDispatchStation.stationId, existingDispatchStation);
        }
        if ("J" === dispatchStation.dispatchType) {
          existingDispatchStation.jobGroups.add(Number(dispatchStation.dispatchGroupId));
        }
        if ("D" === dispatchStation.dispatchType) {
          existingDispatchStation.driverGroups.add(Number(dispatchStation.dispatchGroupId));
        }
        return currentDispatchStations;
      }, new Map<string, DispatchStation>());
      setDispatchStations(newDispatchStations);
      setDispatchStationOptions(
        Array.from(newDispatchStations.values())
          .sort((a, b) => a.description.localeCompare(b.description))
          .map((ds) => {
            return {
              key: ds.stationId,
              value: ds.description
            } as OptionType;
          })
      );
    }
  }, [dispatchStationGroupsResults.data, dispatchStationGroupsResults.error, dispatchStationGroupsResults.loading]);

  const onDispatchStationSelected = (option: OptionType) => {
    const newSelectedDispatchStations = new Map<string, OptionType>(selectedDispatchStations);
    if (newSelectedDispatchStations.has(option.key as string)) {
      newSelectedDispatchStations.delete(option.key as string);
      const dispatchStation = dispatchStations.get(option.key as string);
      if (dispatchStation) {
        console.debug("De-selected Dispatch Station: ", dispatchStation);
        dispatchStationState.removeSelectedDispatchStation(dispatchStation);
      }
    } else {
      newSelectedDispatchStations.set(option.key as string, option);
      const dispatchStation = dispatchStations.get(option.key as string);
      if (dispatchStation) {
        console.debug("Selected Dispatch Station: ", dispatchStation);
        dispatchStationState.addSelectedDispatchStation(dispatchStation);
      }
    }
    setUserPref({
      variables: {
        name: UserPreferences.dispatchStationsGroup,
        input: createJsonPref(Array.from(newSelectedDispatchStations.keys()), true)
      }
    }).catch((error) => {
      console.warn(`Error saving user preference ${error.message}`, error);
    });

    setSelectedDispatchStations(newSelectedDispatchStations);
    mapVisibilityDispatch({type: "SetIsViewGestured", payload: false});
  };

  const onDispatchStationsCleared = () => {
    dispatchStationState.clearSelectedDispatchStations();
    setSelectedDispatchStations(new Map<string, OptionType>());
    mapVisibilityDispatch({type: "SetIsViewGestured", payload: false});
    setUserPref({
      variables: {
        name: UserPreferences.dispatchStationsGroup,
        input: createJsonPref([], true)
      }
    }).catch((error) => {
      console.warn(`Error saving user preference ${error.message}`, error);
    });
  };

  const updateVersion: IntervalFunction = () => {
    determineLoadedVersion()
      .then((updatedVersion) => {
        setCurrentVersion(updatedVersion);
      })
      .catch((error) => {
        console.warn(`Unable to determine loaded version ${error.message}`, error);
      });
  };
  useInterval({callback: updateVersion, delay: env.updateCheckInSeconds * 1000});

  const handleNav = useCallback(
    (location: string) => {
      history.push(location);
    },
    [history]
  );

  const handleHome = useCallback(() => {
    dispatch({
      type: "SetIsAllAssignedStopsOpen",
      payload: false
    });
    handleNav("/jobs");
  }, [dispatch, handleNav]);

  const handleLogout = useCallback(() => {
    authLogout().catch((error) => {
      console.warn(`Error logging out with authentication provider ${error.message}`, error);
    });
  }, [authLogout]);

  const handleTenantSwitch = (tenant: Tenant) => {
    console.log(`Switching to tenant: ${JSON.stringify(tenant)}`);
    mainLoading(true);
    switchTenants(token as string, tenant.id)
      .then(() => {
        oktaAuth.tokenManager
          .renew("accessToken")
          .then(() => {
            refresh();
            AppToaster.show({
              intent: Intent.SUCCESS,
              icon: "tick",
              message: `Switched to tenant: ${tenant.shortName}`
            });
            mainLoading(false);
          })
          .catch((error) => {
            console.warn(`Error renewing access token with Okta${error.message}`, error);
          });
      })
      .catch((e) => {
        AppToaster.show({
          intent: Intent.WARNING,
          icon: "warning-sign",
          message: `Error Switching Tenants: ${e}`
        });
        console.error("Error switching tenants", e);
        mainLoading(false);
      });
  };

  const getAllowedTenants = useCallback(() => {
    if (!loading && !error) {
      if (isSuperUser) {
        return data;
      } else {
        return data?.filter((x) => tenants?.includes(x.shortName));
      }
    } else {
      return [] as Tenant[];
    }
  }, [data, error, isSuperUser, loading, tenants]);

  useEffect(() => {
    determineLoadedVersion()
      .then((version) => {
        setInitialVersion(version);
        setCurrentVersion(version);
      })
      .catch((error) => {
        console.warn(`Unable to determine loaded version ${error.message}`, error);
      });
  }, []);

  useEffect(() => {
    if (!updatePrompt && initialVersion && currentVersion && initialVersion !== currentVersion) {
      setUpdatePrompt(true);
      console.debug(`Version ${initialVersion} is different than ${currentVersion}`);
      AppToaster.show({
        action: {
          text: "Update Now",
          onClick: () => window.location.reload()
        },
        intent: Intent.PRIMARY,
        message:
          "A new version of Dispatch is available.  Click 'Update Now' to update or update any time by clicking your browser's refresh button.",
        onDismiss: () => {
          setUpdatePrompt(false);
        },
        timeout: 0
      });
    }
  }, [updatePrompt, initialVersion, currentVersion]);

  useEffect(() => {
    const dispatchStationGroups = extractJsonPref(userPreferences, UserPreferences.dispatchStationsGroup).value;
    if (
      userPreferences.length > 0 &&
      dispatchStations.size > 0 &&
      initalRender &&
      dispatchStationGroups &&
      dispatchStationGroups.length > 0
    ) {
      const result = new Map<string, DispatchStation>();
      dispatchStationGroups.forEach((dispatchStation: string) => {
        if (dispatchStations.has(dispatchStation)) {
          result.set(dispatchStation, dispatchStations.get(dispatchStation) as DispatchStation);
        }
      });
      setSelectedDispatchStations(
        Array.from(result.keys()).reduce((map, key) => {
          map.set(key, {key: key, value: dispatchStations.get(key)?.description} as OptionType);
          return map;
        }, new Map<string, OptionType>())
      );
      dispatchStationState.initialSelectedDispatchStations(Array.from(result.values()));
      setInitalRender(false);
    }
  }, [dispatchStations, userPreferences, dispatchStationState, initalRender]);

  const openMessaging = () => {
    dispatch({
      type: "SetIsChatOpen",
      payload: true
    });
  };

  const onToggleAllAssignedStops = useCallback(() => {
    handleNav("/jobs");
    dispatch({
      type: "SetIsManifestDetailsOpen",
      payload: false
    });
    dispatch({
      type: "SetIsAllAssignedStopsOpen",
      payload: !appState.isAllAssignedStopsOpen
    });
  }, [appState.isAllAssignedStopsOpen, dispatch, handleNav]);

  const renderTenantMenuItem = (tenant: Tenant) => {
    return (
      <MenuItem
        data-testid={`tenant-menu-item-${tenant.id}`}
        key={`tenant-menu-item-${tenant.id}`}
        text={tenant.shortName}
        onClick={() => handleTenantSwitch(tenant)}
        intent={tenant.id === tenantId ? Intent.PRIMARY : Intent.NONE}
        icon={tenant.id === tenantId ? "tick" : "blank"}
      />
    );
  };

  const onOpenGlobalFind = () => {
    dispatch({type: "SetIsGlobalFindOpen", payload: true});
  };

  const renderTenantSelection = () => {
    const allowedTenants = getAllowedTenants();
    if (allowedTenants?.length === 0 || (allowedTenants?.length === 1 && allowedTenants[0].id === tenantId)) {
      return null;
    } else if (allowedTenants) {
      const currentTenant = allowedTenants.find((x) => x.id === tenantId);
      return (
        <MenuItem data-testid="tenant-switch-menu" icon="briefcase" text="Customer">
          <InputGroup
            autoFocus
            aria-autocomplete="list"
            leftIcon="search"
            placeholder="Filter..."
            onChange={(event) => setTenantFilter((event.target as HTMLInputElement).value)}
            value={tenantFilter}
          />
          <Scrollbars autoHeight autoHeightMax="50vh">
            {currentTenant && renderTenantMenuItem(currentTenant)}
            {allowedTenants
              .filter((x) => (tenantFilter ? x.shortName.toLowerCase().indexOf(tenantFilter.toLowerCase()) >= 0 : true))
              .filter((x) => x.id !== tenantId)
              .sort((x, y) => (x.shortName < y.shortName ? -1 : 1))
              .map((tenant) => {
                return renderTenantMenuItem(tenant);
              })}
          </Scrollbars>
        </MenuItem>
      );
    }
  };

  const unreadMessage = useMemo(() => {
    if (selectedDriverChannelId) {
      return selectedDriverUnreadCount;
    }
    return totalUnreadChannels <= Constants.MAX_UNREAD_MESSAGE_COUNT
      ? totalUnreadChannels
      : Constants.MAX_UNREAD_MESSAGE_COUNT + "+";
  }, [selectedDriverChannelId, selectedDriverUnreadCount, totalUnreadChannels]);

  const menu = (
    <Menu className={Classes.ELEVATION_2}>
      <MenuItem icon="info-sign" text="User Info" onClick={() => handleNav("/whoami")} />
      {renderTenantSelection()}
      {isAdmin && (
        <MenuItem
          data-testid={"settings-menu-item"}
          icon={<FontAwesomeIcon icon={solid("gear")} />}
          text={"Settings"}
          href={"/settings"}
        />
      )}
      <MenuItem icon="log-out" text="Logout" onClick={handleLogout} />
    </Menu>
  );

  return (
    <Fragment>
      {authState?.accessToken && (
        <Navbar data-testid={"navbar-root"}>
          <Navbar.Group align={Alignment.LEFT}>
            <Fragment>
              <Navbar.Heading>
                <FontAwesomeIcon style={{paddingRight: "8px"}} color={"#5F6B7C"} icon={solid("headset")} />
                <span>Dispatch</span>
              </Navbar.Heading>
              <Navbar.Divider />
            </Fragment>
            <span></span>
            <Button className="bp4-minimal" icon="home" text="Home" onClick={handleHome} />
            {allAssignedStopEnabled && (
              <Button
                className="bp4-minimal"
                icon={<Icon icon="list" size={14} color="#5F6B7C" />}
                text="Assigned Stops"
                onClick={onToggleAllAssignedStops}
              />
            )}
            {messagingEnabled && (
              <Button
                className="bp4-minimal"
                icon={<MessagingIcon />}
                text="Messaging"
                onClick={openMessaging}
                style={{position: "relative"}}
              >
                {unreadMessage !== 0 && <MessageNumber>{unreadMessage}</MessageNumber>}
              </Button>
            )}
          </Navbar.Group>

          <Navbar.Group align={Alignment.RIGHT}>
            {globalFindEnabled && (
              <>
                <StyledButton
                  onClick={onOpenGlobalFind}
                  icon={<Icon color="#797979" icon="search" size={14} />}
                  minimal
                  text="Global Find"
                />
                <Navbar.Divider />
              </>
            )}
            {
              <FilterableMultiSelect
                options={dispatchStationOptions}
                testIdPrefix="dispatch-station"
                selectedOptions={Array.from(selectedDispatchStations.values())}
                text={`Dispatch Stations${
                  selectedDispatchStations?.size > 0 ? ` (${selectedDispatchStations?.size})` : ""
                }`}
                placeholder={"Search Dispatch Stations"}
                onOptionSelect={onDispatchStationSelected}
                onClear={onDispatchStationsCleared}
                onQueryChange={setDispatchStationQuery}
                activeBackGroundColor="#14305A"
                defaultTextColor="#161616"
                iconSize={14}
                maxHeight={360}
              />
            }
            <Navbar.Divider />
            <Popover2 content={menu} position={Position.BOTTOM_RIGHT} portalClassName="settings-portal">
              <Button data-testid={"user-avatar-button"} className="bp4-minimal">
                <CustomAvatar name={user?.name} email={user?.email} round={true} size="36" textSizeRatio={2} />
              </Button>
            </Popover2>
          </Navbar.Group>
        </Navbar>
      )}
    </Fragment>
  );
};

export default DispatchNavBar;

export const MessageNumber = styled.div`
  color: white;
  position: absolute;
  left: 15%;
  top: -18%;
  min-width: 18px;
  padding: 2px 6px;
  background: #32a467;
  border: 1px solid #ffffff;
  border-radius: 9px;
  font-size: 10px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
`;

const MessagingIcon = () => (
  <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_227_18775)">
      <path
        d="M2.5 0C1.12109 0 0 1.12109 0 2.5V13.75C0 15.1289 1.12109 16.25 2.5 16.25H6.25V19.375C6.25 19.6133 6.38281 19.8281 6.59375 19.9336C6.80469 20.0391 7.05859 20.0156 7.25 19.875L12.082 16.25H17.5C18.8789 16.25 20 15.1289 20 13.75V2.5C20 1.12109 18.8789 0 17.5 0H2.5Z"
        fill="#5F6B7C"
      />
    </g>
    <defs>
      <clipPath id="clip0_227_18775">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const StyledButton = styled(Button)`
  width: 150px;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px #00000040 !important;
  background: #ffffff !important;
  justify-content: start;

  .bp4-button-text {
    color: #797979;
  }
`;
