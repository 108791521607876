import {ICellRendererParams} from "@ag-grid-community/core";
import {ManifestStop, Stop} from "../../../generated/graphql";
import React from "react";
import styled from "@emotion/styled";
import {Popover2} from "@blueprintjs/popover2";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {EmptyValueStrings} from "../Constants";
import {EmotionJSX} from "@emotion/react/types/jsx-namespace";

const ContentContainer = styled.div``;

const Icon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

const CellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Popover = styled(Popover2)``;

const DetailHeader = styled.span`
  font-size: 12px;
  font-weight: 500;
`;

const DetailContent = styled.span`
  font-size: 12px;
  font-weight: 300;
`;

const contactContent = (manifestStop: ManifestStop | Stop) => {
  const see = manifestStop.see ? (
    <span>
      <br />
      {manifestStop.see}
    </span>
  ) : undefined;
  const phone = manifestStop.phone ? (
    <span>
      <br />
      {manifestStop.phone}
    </span>
  ) : undefined;
  const notSet = !see && !phone ? EmptyValueStrings.notProvided : undefined;
  return (
    <div>
      <DetailHeader>CONTACT: </DetailHeader>
      <DetailContent>
        {notSet}
        {see}
        {phone}
      </DetailContent>
    </div>
  );
};

const notesContent = (notes: string | undefined | null, label: string) => {
  notes = notes ?? EmptyValueStrings.notAvailable;
  return (
    <div>
      <DetailHeader>{label}:</DetailHeader>
      <DetailContent> {notes}</DetailContent>
    </div>
  );
};

const renderEmptyContent = () => {
  return (
    <CellContainer>
      <Icon icon={solid("memo-circle-info")} size={"lg"} color="#9CA3AF" />
    </CellContainer>
  );
};

const renderContent = (
  contactDetails: EmotionJSX.Element,
  orderNotes: EmotionJSX.Element,
  stopNotes: EmotionJSX.Element,
  customerNotes: EmotionJSX.Element
) => {
  return (
    <CellContainer>
      <Popover
        content={
          <ContentContainer>
            {contactDetails}
            <br />
            {orderNotes}
            <br />
            {stopNotes}
            <br />
            {customerNotes}
          </ContentContainer>
        }
        popoverClassName={"bp4-popover2-content-sizing manifest-stop-details-popover"}
      >
        <Icon icon={solid("memo-circle-info")} size={"lg"} color="#9CA3AF" />
      </Popover>
    </CellContainer>
  );
};

export const DetailsCellRenderer = (props: ICellRendererParams<ManifestStop, string | undefined>) => {
  if (!props.data) return renderEmptyContent();

  const contactDetails = contactContent(props.data);
  const orderNotes = notesContent(props.data?.order?.notes, "ORDER NOTES");
  const stopNotes = notesContent(props.data?.note, "STOP NOTES");
  const customerNotes = notesContent(props.data?.order?.customer?.notes, "CUSTOMER NOTES");

  return renderContent(contactDetails, orderNotes, stopNotes, customerNotes);
};
