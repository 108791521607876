import {ApolloError, ApolloQueryResult, useApolloClient} from "@apollo/client";
import {Button, ControlGroup, Icon, Intent, Text} from "@blueprintjs/core";
import styled from "@emotion/styled";
import {
  ColDef,
  ColumnMovedEvent,
  ColumnState,
  ColumnVisibleEvent,
  FilterChangedEvent,
  ICellRendererParams,
  IRowDragItem,
  IRowNode,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  ISetFilter,
  ITextFilterParams,
  ModuleRegistry,
  PaginationChangedEvent,
  RowClassParams,
  RowDragEndEvent,
  RowDragEnterEvent,
  RowDropZoneParams,
  RowSelectedEvent,
  RowStyle,
  SelectionEventSourceType,
  SetFilterValuesFuncParams,
  SideBarDef,
  SortChangedEvent
} from "@ag-grid-community/core";
import {AgGridReact} from "@ag-grid-community/react";
import React, {Dispatch, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import ButtonCellRenderer from "../common/cell-renderers/ButtonCellRenderer";
import doubleFieldCellRenderer, {DoubleFieldCellValue} from "../common/cell-renderers/DoubleFieldCellRenderer";
import {createJobsServerSideDatasource} from "./JobServerSideDataSource";
import serviceTypeCellRenderer from "../common/cell-renderers/ServiceTypeCellRenderer";
import {useAppContext} from "../../ApplicationContext";
import {
  DriverQualification,
  FeeTitle,
  FeeTitleSortableField,
  Job,
  JobAttributeFilter,
  Manifest,
  OrderRequirement,
  SearchableSortDirection,
  useAssignJobsToManifestMutation,
  useGetDistinctValuesLazyQuery,
  useSearchFeeTitlesLazyQuery,
  useSetUserPrefMutation
} from "../../generated/graphql";
import {navigateToCmsOrder} from "../../services/CmsService";
import {deliveryStop, pickupStop} from "../../services/JobStopService";
import {
  getJobStopDate,
  getDateTimeAtSpecificTimeZone,
  getTimeAtSpecificTimeZone,
  getLocalDate,
  getLocalDateTime,
  getLocalTime
} from "../../utils/DateUtils";
import {JobAssignmentSubmissionResult} from "../../utils/JobAssignmentEventHandler";
import {activePendingActions} from "../../utils/PendingAssignment";
import {
  DragAndDropTargetPayload,
  JobAssignmentViewState,
  JobViewActions,
  JobViewActionTypes
} from "../../views/JobAssignmentViewReducer";
import {AuthState, useAuthState} from "../AuthProvider";
import DateFilter from "../common/DateFilter";
import GridSettingsToolPanel from "../common/GridSettingsToolPanel";
import {JobAssignmentActions, JobAssignmentAsyncActions, JobAssignmentState} from "../common/JobAssignmentReducer";
import {useGridRefreshOnInterval} from "../hooks/useGridRefreshOnInterval";
import {Fill, Top} from "react-spaces";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import JobStatusCellRenderer from "../common/cell-renderers/JobStatusCellRenderer";
import {addHours, addMinutes, endOfMinute, startOfMinute, subDays} from "date-fns";
import {
  getTimeOptionFilter,
  placedInLastOptions,
  TimeOption,
  TimePickerFilterPopover,
  TimeQuickFilterSelect
} from "./TimeQuickFilterSelect";
import {getFilterValues} from "../../services/AgGridService";
import AssignmentBar from "./AssignmentBar";
import MapIconCellRenderer from "../common/cell-renderers/MapIconCellRenderer";
import {
  EntityVisibilityLevel,
  MapVisibilityAttributes,
  UnassignedJobsVisibilityTypes,
  useMapVisibilityContext
} from "../map/MapVisibilityContext";
import "./JobPanel.css";
import {DispatchRulesDataContext, DispatchRulesDataState} from "../common/DispatchRulesDataProvider";
import {RuleProperties} from "json-rules-engine";
import {
  createJsonPref,
  extractJsonPref,
  extractSimplePref,
  PreferenceContext,
  PrefValue
} from "../../providers/PreferenceProvider";
import {IndicatorColors} from "../settings/ColorizedIndicators/ColorizedIndicators";
import {UserColor} from "../settings/ColorizedIndicators/types/indicatorLogicType";
import {getHighestRankedColor} from "../manifest/ManifestCardCommon";
import {DispatchGroupDataContext, DispatchGroupDataState} from "../common/DispatchGroupDataProvider";
import MultiSelectDropDown, {MultiSelectDropDownItem} from "../common/MultiSelectDropDown";
import _, {isNumber} from "lodash";
import {FeatureFlagContext} from "../../providers/FeatureFlagProvider";
import {ALLOWED_SAVED_PREFERENCE_SOURCES, TenantPreferences, UserPreferences} from "../common/Constants";
import {DispatchStationDataContext} from "../common/DispatchStationDataProvider";
import AssignmentBarV2 from "./AssignmentBarV2";
import {ManifestDataContext, ManifestDataState} from "../manifest/ManifestDataProvider";
import {arrayContainsArray, getArrayFromField} from "../../utils/General";
import {showQualifiedDriversWarningToast} from "../../utils/qualified_drivers_toaster/QualifiedDriversToaster";
import ActiveFilterDialog, {IActiveFilterDialogProps, IActiveFilterDialogRef} from "../common/ActiveFilterDialog";
import {AssignJobsConfirmation} from "../manifest/details/components/AssignJobsConfirmation";
import {DriverRequirementEnforcementLevel} from "../settings/types/DriverRequirementEnforcementLevel";
import {ServerSideRowModelModule} from "@ag-grid-enterprise/server-side-row-model";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {WarningIcon} from "../manifest/details/components/JobColHeaderRenderer";
import ColorCellRenderer from "../common/cell-renderers/ColorCellRenderer";
import CustomerFilter from "../common/CustomerFilter";
import Search from "../common/Search";

ModuleRegistry.registerModules([ServerSideRowModelModule, SetFilterModule, ColumnsToolPanelModule]);

const PanelContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background: #ededed;

  .ag-pinned-left-header {
    border-right: none;
  }

  .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
    border-right: none;
  }
  .drag-column .ag-drag-handle {
    margin-left: 4px;
    margin-right: 8px;
  }
  .job-number-cell .ag-drag-handle {
    display: none;
  }
`;

const ButtonBar = styled(Top)<{hasWarningMessage: boolean}>`
  padding: ${(props) => (props.hasWarningMessage ? "16px 0 8px 0" : "")};
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.hasWarningMessage ? "space-between" : "center")};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LeftControls = styled.div`
  display: flex;
  margin-right: auto;
  margin-left: 25px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

const RightControls = styled.div`
  display: flex;
  margin-right: 18px;
  align-items: center;
  justify-content: flex-start;
`;

export const WarningText = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-family: "Roboto", sans-serif;
  color: #161616;
  display: grid;
  grid-template-columns: 18px auto;
  align-items: center;
  gap: 5px;
  padding: 0 24px;
`;

const AssignmentBarPinned = styled(AssignmentBar)`
  position: absolute;
  bottom: 95px;
  left: 0;
  right: 0;
  margin: auto;
`;

const AssignmentBarV2Pinned = styled(AssignmentBarV2)`
  position: absolute;
  bottom: 95px;
  left: 0;
  right: 0;
  margin: auto;
`;

const ColorIndicatorDiv = styled.div`
  height: 39px;
  width: 8px;
  padding-left: 7px;
  border-radius: 3px;
  background: ${(props) => props.color};
`;

type JobPanelProps = {
  dragAndDropTargets: DragAndDropTargetPayload[];
  currentDragAndDropTargets: RowDropZoneParams[];
  assignmentDispatch: Dispatch<JobAssignmentActions | JobAssignmentAsyncActions>;
  assignmentViewDispatch: Dispatch<JobViewActions>;
  assignmentState: JobAssignmentState;
  assignmentViewState?: JobAssignmentViewState;
  getGridRef?: (gridRef: AgGridReact<AgJob>) => void;
};

export type GridContext = {
  globalQuery?: string;
  globalFilter?: JobAttributeFilter;
};

type ExtendedAgJob = AgJob & {hide: boolean};

export interface AgJob extends Job {
  map?: MapVisibilityAttributes;
  indicatorColor?: string;
}
export const isCustomOption = (option: TimeOption) => option.title === "custom";

export enum TimeFilterTitle {
  ready = "ready",
  due = "due"
}

export type TTimeFilterCustomValue = Record<TimeFilterTitle, number>;

export const InitialTimeFilterCustomValue: TTimeFilterCustomValue = {
  ready: 0,
  due: 0
};

export const TimeFilterTitles = [TimeFilterTitle.ready, TimeFilterTitle.due];

const timeOptions: TimeOption[] = [
  {
    title: "Now",
    getFilterValue(): any {
      return [startOfMinute(new Date(0)), endOfMinute(new Date())];
    }
  },
  {
    title: "30min",
    getFilterValue(): any {
      return [startOfMinute(new Date(0)), endOfMinute(addMinutes(new Date(), 30))];
    }
  },
  {
    title: "1hr",
    getFilterValue(): any {
      return [startOfMinute(new Date(0)), endOfMinute(addHours(new Date(), 1))];
    }
  },
  {
    title: "1hr 30min",
    getFilterValue(): any {
      return [startOfMinute(new Date(0)), endOfMinute(addMinutes(new Date(), 90))];
    }
  },
  {
    title: "2hr",
    getFilterValue(): any {
      return [startOfMinute(new Date(0)), endOfMinute(addHours(new Date(), 2))];
    }
  },
  {
    title: "2hr 30min",
    getFilterValue(): any {
      return [startOfMinute(new Date(0)), endOfMinute(addMinutes(new Date(), 150))];
    }
  },
  {
    title: "3hr",
    getFilterValue(): any {
      return [startOfMinute(new Date(0)), endOfMinute(addHours(new Date(), 3))];
    }
  },
  {
    title: "custom",
    customValue: 0,
    getFilterValue(customValue: number): any {
      return [startOfMinute(new Date(0)), endOfMinute(addMinutes(new Date(), customValue * 60))];
    }
  }
];

export const TimeFilterOptions: TimeOption[] = TimeFilterTitles.flatMap((title) =>
  timeOptions.map((option) => ({...option, type: title}))
);

export const ColorIndicatorCellRenderer = (props: ICellRendererParams<any, string>) => {
  return (
    <ColorIndicatorDiv
      color={
        props.value && IndicatorColors[props.value as UserColor] ? IndicatorColors[props.value as UserColor].hex : ""
      }
    ></ColorIndicatorDiv>
  );
};

const isManifestMatchRequirements = ({job, manifest}: {job: Job; manifest: Manifest}) => {
  const jobRequirements = job.order?.requirements?.map((r: OrderRequirement) => r.name) ?? [];
  const manifestCapabilities = manifest.driver.qualifications?.map((q: DriverQualification) => q.name) ?? [];
  return arrayContainsArray(manifestCapabilities, jobRequirements);
};

export const getMatchRequirementManifests = ({
  selectedJobs,
  manifests
}: {
  selectedJobs: Job[];
  manifests: Manifest[] | undefined;
}) => {
  if (!manifests || selectedJobs.length === 0) {
    return [];
  }
  const matchManifests: number[] = [];
  manifests.forEach((manifest) => {
    const isMatchAll = selectedJobs.every((job) => isManifestMatchRequirements({job, manifest}));
    if (isMatchAll) {
      matchManifests.push(manifest.manifestDriverId);
    }
  });
  return matchManifests;
};

export const getColorPriority = (colorRules: RuleProperties[] | undefined, color: string | undefined) => {
  return colorRules?.find((rule) => rule.event.params?.color === color)?.priority ?? 0;
};

export enum WARNING_MESSAGES {
  FILTER = "Color filtering is limited to the current page",
  SORT = "Color sorting is limited to the current page",
  FILTER_AND_SORT = "Color filtering/sorting is limited to the current page"
}

export const getWarningMessage = (isSorting: boolean, isFiltering: boolean) => {
  if (isSorting && isFiltering) {
    return WARNING_MESSAGES.FILTER_AND_SORT;
  }
  if (isSorting) {
    return WARNING_MESSAGES.SORT;
  }
  if (isFiltering) {
    return WARNING_MESSAGES.FILTER;
  }
};

const getTimeFilterTitle = (option: TimeOption) => {
  const suffix = isCustomOption(option) ? option.customValue?.toString() + "hr" : option.title;
  const prefix = option.type === "ready" ? "Ready in Next" : "Due in Next";
  return `${prefix} ${suffix}`;
};

const JobPanel = ({
  dragAndDropTargets,
  currentDragAndDropTargets,
  assignmentDispatch,
  assignmentViewDispatch,
  assignmentViewState,
  assignmentState,
  getGridRef
}: JobPanelProps) => {
  const [assignJobsToManifest] = useAssignJobsToManifestMutation();
  const apolloClient = useApolloClient();
  const {appState} = useAppContext();
  const authState = useAuthState();
  const {state: mapVisibilityState, dispatch: mapVisibilityDispatch} = useMapVisibilityContext();
  const {userPreferences, tenantPreferences, userPrefsQueryRefetch} = useContext(PreferenceContext);
  const [driverRequiredSetting, setDriverRequiredSetting] = useState<DriverRequirementEnforcementLevel>(
    DriverRequirementEnforcementLevel.Allow
  );
  const {jobGroups} = useContext<DispatchGroupDataState>(DispatchGroupDataContext);
  const [setUserPref] = useSetUserPrefMutation();
  const [initializing, setInitializing] = useState<boolean>(true);
  const gridRef = useRef<AgGridReact<AgJob>>(null);
  const [gridReady, setGridReady] = useState<boolean>(false);
  const jobDialogRefs = useRef<IActiveFilterDialogRef>(null);
  const [selectedTimeFilter, setSelectedTimeFilter] = useState<TimeOption>();
  const [timeFilterTitle, setTimeFilterTitle] = useState<string>("Ready / Due");
  const [isEnableQualified, setIsEnableQualified] = useState(false);
  const [selectedPlacedInLastTime, setSelectedPlacedInLastTime] = useState<TimeOption>();
  const [datasourceFailed, setDatasourceFailed] = useState<ApolloError | undefined>();
  const ruleContextState = useContext<DispatchRulesDataState>(DispatchRulesDataContext);
  const manifestDataState = useContext<ManifestDataState>(ManifestDataContext);

  const [gridContext, setGridContext] = useState<GridContext>({
    globalQuery: ""
  });
  const [gridHasActiveFilters, setGridHasActiveFilters] = useState<boolean>(false);
  const [warningMessage, setWarningMessage] = useState<WARNING_MESSAGES | undefined>();

  const [currentJobPanePage, setCurrentJobPanePage] = useState<number>();
  const [datasource, setDatasource] = useState<IServerSideDatasource>();
  const [selectedJobGroups, setSelectedJobGroups] = useState<string[]>([]);
  const dispatchStationState = useContext(DispatchStationDataContext);
  const {driverRequirements: driverRequirementsEnabled, colorSort: filterAndSortByColorsEnabled} =
    useContext(FeatureFlagContext);

  const [showAssignJobsConfirm, setShowAssignJobsConfirm] = useState(false);
  useGridRefreshOnInterval("Unassigned Jobs", gridRef, gridReady, datasourceFailed);

  const timezoneOfJobStop = extractSimplePref(tenantPreferences, TenantPreferences.timezoneOfJobStop, false).value;

  const datasourceFailCallback = useCallback((error: ApolloError | undefined) => {
    setDatasourceFailed(error);
  }, []);

  const jobGroupOptions = useMemo(() => {
    const map: Map<number, MultiSelectDropDownItem> = new Map();
    if (jobGroups.length > 0) {
      const groups = [...jobGroups];
      groups.sort((a, b) => a.dispatchGroup!.localeCompare(b.dispatchGroup!));
      groups.forEach((group) => {
        map.set(group.dispatchGroupId!, {
          name: group.dispatchGroup,
          selected: selectedJobGroups.includes(group.dispatchGroup!),
          id: group.dispatchGroupId
        } as MultiSelectDropDownItem);
      });
    }
    return map;
  }, [jobGroups, selectedJobGroups]);

  const updateUserPrefsTimeFilter = useCallback(
    async (key: string, value: any) => {
      await setUserPref({
        variables: {
          name: key,
          input: createJsonPref(value, true)
        }
      }).then(() => {
        userPrefsQueryRefetch?.();
      });
    },
    [setUserPref, userPrefsQueryRefetch]
  );

  const resetViewGestured = useCallback(() => {
    if (mapVisibilityState.unassignedJobVisibilityType !== UnassignedJobsVisibilityTypes.NONE) {
      mapVisibilityDispatch({type: "SetIsViewGestured", payload: false});
    }
  }, [mapVisibilityDispatch, mapVisibilityState.unassignedJobVisibilityType]);

  const onConfirmSelectedTimeFilter = useCallback(
    (option: TimeOption) => {
      setSelectedTimeFilter(option);
      setTimeFilterTitle(getTimeFilterTitle(option));
      if (option.type === "ready") {
        gridRef.current!.api.getFilterInstance("stop.delivery.scheduledDateTime", (filter) => {
          filter?.setModel(null);
          gridRef.current!.api.onFilterChanged();
        });
        gridRef.current!.api.getFilterInstance("stop.pickup.scheduledDateTime", (filter) => {
          filter?.setModel(getTimeOptionFilter(option));
          gridRef.current!.api.onFilterChanged();
        });
      } else {
        gridRef.current!.api.getFilterInstance("stop.pickup.scheduledDateTime", (filter) => {
          filter?.setModel(null);
          gridRef.current!.api.onFilterChanged();
        });
        gridRef.current!.api.getFilterInstance("stop.delivery.scheduledDateTime", (filter) => {
          filter?.setModel(getTimeOptionFilter(option));
          gridRef.current!.api.onFilterChanged();
        });
      }
      resetViewGestured();
      updateUserPrefsTimeFilter(UserPreferences.jobGridFilterReadyDue, option);
      if (isCustomOption(option)) {
        const currentState = extractJsonPref(
          userPreferences,
          UserPreferences.jobGridFilterReadyDueCustomValue,
          InitialTimeFilterCustomValue
        );

        updateUserPrefsTimeFilter(UserPreferences.jobGridFilterReadyDueCustomValue, {
          ...InitialTimeFilterCustomValue,
          ...currentState.value,
          [option.type as string]: option.customValue
        });
      }
    },
    [resetViewGestured, updateUserPrefsTimeFilter, userPreferences]
  );

  const handleSelectedPlacedInLastTime = useCallback(
    (option: TimeOption) => {
      gridRef.current!.api.getFilterInstance("order.createdDateTime", (filter) => {
        setSelectedPlacedInLastTime(option);
        filter?.setModel(getTimeOptionFilter(option));
        gridRef.current!.api.onFilterChanged();
      });
      updateUserPrefsTimeFilter(UserPreferences.jobGridFilterPlaced, option.title);
      resetViewGestured();
    },
    [updateUserPrefsTimeFilter, resetViewGestured]
  );

  useEffect(() => {
    if (dispatchStationState.hasSelectedDispatchStations()) {
      setGridContext({
        ...gridContext,
        globalFilter: {
          or: Array.from(dispatchStationState.selectedDispatchStations.values()).flatMap((ds) => {
            return Array.from(ds.jobGroups).map((jg) => {
              return {
                dispatchGroup_dispatchGroupId: {eq: jg}
              } as JobAttributeFilter;
            });
          })
        }
      });
    } else {
      setGridContext({
        ...gridContext,
        globalFilter: undefined
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchStationState, setGridContext]);

  useEffect(() => {
    if (gridReady) {
      const filter = gridRef.current!.api.getFilterInstance("dispatchGroup.name") as ISetFilter;
      filter.setFilterValues(jobGroups.map((d) => d.dispatchGroup!));
    }
  }, [gridReady, jobGroups]);

  useEffect(() => {
    if (gridReady && userPreferences.length > 0 && initializing && jobGroups.length > 0 && !ruleContextState.loading) {
      const columnState = extractJsonPref(userPreferences, UserPreferences.jobGridColumnState);
      if (columnState.value) {
        gridRef.current!.columnApi.applyColumnState({state: columnState.value, applyOrder: true});
      }
      const filterState = extractJsonPref(userPreferences, UserPreferences.jobGridFilterState);
      const hasFilterState = filterState.value && Object.keys(filterState.value).length > 0;
      if (hasFilterState) {
        gridRef.current!.api.setFilterModel(filterState.value);
        if (Object.keys(filterState.value).includes("dispatchGroup.name")) {
          setSelectedJobGroups(filterState.value["dispatchGroup.name"].values);
        }
      }

      const customValueTimeFilter = extractJsonPref(
        userPreferences,
        UserPreferences.jobGridFilterReadyDueCustomValue,
        InitialTimeFilterCustomValue
      );
      if (customValueTimeFilter.value) {
        Object.keys(customValueTimeFilter.value).forEach((key) => {
          const customOptionIndex = TimeFilterOptions.findIndex((o) => isCustomOption(o) && o.type === key);
          if (customOptionIndex > -1) {
            TimeFilterOptions[customOptionIndex].customValue = customValueTimeFilter.value?.[key];
          }
        });
      }

      const timeFilter = extractJsonPref(userPreferences, UserPreferences.jobGridFilterReadyDue);
      if (timeFilter.value) {
        const title = timeFilter.value?.title;
        const type = timeFilter.value?.type;
        const option = TimeFilterOptions.find((o) => o.title === title && o.type === type);
        if (option) {
          setSelectedTimeFilter(option);
          onConfirmSelectedTimeFilter(option);
        }
      }

      const filterPlaced = extractJsonPref(userPreferences, UserPreferences.jobGridFilterPlaced);
      if (filterPlaced.value) {
        const option = placedInLastOptions.find((o) => o.title === filterPlaced.value);
        if (option) {
          handleSelectedPlacedInLastTime(option);
        }
      }

      const hasFilterJob = hasFilterState | timeFilter.value | filterPlaced.value;
      if (hasFilterJob) {
        jobDialogRefs.current?.onOpen();
      }
      setInitializing(false);
    }
  }, [
    gridReady,
    handleSelectedPlacedInLastTime,
    initializing,
    jobGroups.length,
    onConfirmSelectedTimeFilter,
    ruleContextState.loading,
    userPreferences
  ]);

  const updateColumn = useCallback(
    (e: ColumnMovedEvent<Job> | SortChangedEvent<Job> | ColumnVisibleEvent<Job>) => {
      const {source, api, columnApi} = e;
      const previousColumnState = extractJsonPref(userPreferences, UserPreferences.jobGridColumnState)?.value;

      if (!ALLOWED_SAVED_PREFERENCE_SOURCES.includes(source)) {
        console.debug("reverted visible state");
        const revertVisibleState: ColumnState[] = getArrayFromField(previousColumnState).map(
          ({colId, hide}: ColumnState) => ({
            colId,
            hide
          })
        );
        columnApi.applyColumnState({
          state: revertVisibleState
        });
      }

      const newColumnState = columnApi.getColumnState();
      if (gridReady && !datasourceFailed && !_.isEqual(previousColumnState, newColumnState) && !initializing) {
        setUserPref({
          variables: {
            name: UserPreferences.jobGridColumnState,
            input: createJsonPref(newColumnState, true)
          }
        }).then(() => {
          userPrefsQueryRefetch?.();
        });
        api.refreshServerSide();
      }
    },
    [userPreferences, gridReady, datasourceFailed, initializing, setUserPref, userPrefsQueryRefetch]
  );

  const onColumnMoveChanged = useCallback(
    (e: ColumnMovedEvent<Job>) => {
      if (!e.finished || e.source === "api") return;
      updateColumn(e);
    },
    [updateColumn]
  );

  const onColumnSortChanged = useCallback(
    (e: SortChangedEvent<Job>) => {
      if (e.source === "api") return;
      updateColumn(e);
    },
    [updateColumn]
  );

  const onColumnVisible = useCallback(
    (e: ColumnVisibleEvent<Job>) => {
      if (e.source === "api") return;
      updateColumn(e);
    },
    [updateColumn]
  );

  const onFilterChanged = useCallback(
    (event: FilterChangedEvent<Job>) => {
      setGridHasActiveFilters(Object.keys(event.api.getFilterModel()).length > 0);
      const filterState = extractJsonPref(userPreferences, UserPreferences.jobGridFilterState)?.value;
      const newFilterState = event.api.getFilterModel();
      if (!_.isEqual(filterState, newFilterState) && !initializing)
        try {
          setUserPref({
            variables: {
              name: UserPreferences.jobGridFilterState,
              input: createJsonPref(newFilterState, true)
            }
          }).then(() => {
            userPrefsQueryRefetch?.();
          });
        } catch (error) {
          console.debug(error);
        }

      mapVisibilityDispatch({type: "SetIsViewGestured", payload: false});
    },
    [initializing, mapVisibilityDispatch, setUserPref, userPreferences, userPrefsQueryRefetch]
  );

  const onJobGroupSelected = (key: number) => {
    const name = jobGroupOptions.get(key)?.name;
    if (name && !selectedJobGroups.includes(name)) {
      setSelectedJobGroups([...selectedJobGroups, name]);
      resetViewGestured();
    }
  };

  const onJobGroupUnselected = (key: number) => {
    const name = jobGroupOptions.get(key)?.name;
    if (name && selectedJobGroups.includes(name)) {
      const newJobGroups = selectedJobGroups.filter((n) => n !== name);
      setSelectedJobGroups(newJobGroups);
      resetViewGestured();
    }
  };

  const onJobGroupUnselectedAll = () => {
    setSelectedJobGroups([]);
    resetViewGestured();
  };

  useEffect(() => {
    if (gridReady) {
      gridRef.current!.api.getFilterInstance("dispatchGroup.name", (filter) => {
        const newModel = selectedJobGroups.length === 0 ? null : {filterType: "set", values: selectedJobGroups};
        if (!_.isEqual(filter?.getModel(), newModel)) {
          filter?.setModel(newModel);
          gridRef.current!.api.onFilterChanged();
        }
      });
    }
  }, [gridReady, selectedJobGroups]);

  const getColorIndicator = useCallback(
    async (job: AgJob) => {
      if (!ruleContextState.loading) {
        const colors: string[] = [];

        for (const stop of job.stops) {
          const engine = ruleContextState.getConfiguredEngine(
            {job: job, stop: stop},
            ruleContextState.rules as RuleProperties[]
          );
          if (engine === undefined) return;

          const results = await engine.run();

          if (results.failureEvents.length > 0) {
            console.debug("Rules engine failure events", results.failureEvents, results.failureResults);
          }

          if (results.events.length > 0) {
            colors.push(results.events[0].params?.color);
          }
        }

        job.indicatorColor = ruleContextState.colorRank
          ? getHighestRankedColor(colors, ruleContextState.colorRank)
          : undefined;
        console.debug("Assigning indicator color to row", job.indicatorColor);
      }
    },
    [ruleContextState]
  );

  const buildIndicatorColors = useCallback(
    async (params: IServerSideGetRowsParams, newRows: AgJob[]) => {
      const promises = newRows.map(async (incomingRow) => {
        await getColorIndicator(incomingRow);
      });

      const results = await Promise.allSettled(promises);

      results.forEach((result) => {
        if (result.status === "rejected") {
          console.warn("Error getting color indicator", result.reason);
        }
      });
    },
    [getColorIndicator]
  );

  const sortAndFilterByColors = useCallback(
    async (params: IServerSideGetRowsParams, newRows: AgJob[], res: ApolloQueryResult<any>) => {
      if (filterAndSortByColorsEnabled) {
        const {sortModel, filterModel} = params.request;
        const filterByColors = filterModel?.indicatorColor;
        const sortByColors = sortModel.find((item) => item.colId === "indicatorColor");
        if (filterByColors) {
          filterByColors.values[filterByColors.values.findIndex((item: string) => item === "No Color")] = undefined;
          newRows.forEach((row, index) => {
            if (!filterByColors.values.includes(row.indicatorColor)) {
              (newRows[index] as ExtendedAgJob).hide = true;
            }
          });
        }
        if (sortByColors) {
          const sortDirection = sortByColors.sort === "asc" ? 1 : -1;
          newRows.sort((a, b) => {
            return (
              (getColorPriority(ruleContextState.rules, a.indicatorColor) -
                getColorPriority(ruleContextState.rules, b.indicatorColor)) *
              sortDirection
            );
          });
        }
        const hideRows = newRows.filter((row) => (row as ExtendedAgJob).hide);

        hideRows.forEach((hideRow) => {
          const hideRowIndex = newRows.findIndex((row) => row.jobId === hideRow.jobId);
          newRows.splice(hideRowIndex, 1);
          newRows.push(hideRow);
        });

        if (res.data.searchJobs.total > 100) {
          const warningMsg = getWarningMessage(Boolean(sortByColors), Boolean(filterByColors));
          setWarningMessage(warningMsg);
        } else {
          setWarningMessage(undefined);
        }
      }
    },
    [filterAndSortByColorsEnabled, ruleContextState.rules]
  );

  const transferActiveMapVisibilityToIncoming = async (
    params: IServerSideGetRowsParams,
    newRows: AgJob[]
  ): Promise<void> => {
    const currentJobRowsWithMapVisibilityObject: AgJob[] = [];
    params.api.forEachNode(({data: row}) => {
      if (row?.map) {
        currentJobRowsWithMapVisibilityObject.push(row);
      }
    });

    // if any of the incoming rows intersect with current rows that have a visibility object...
    if (
      currentJobRowsWithMapVisibilityObject.length > 0 &&
      newRows.some((res) =>
        currentJobRowsWithMapVisibilityObject.map((incomingRow) => incomingRow.jobId).includes(res.jobId)
      )
    ) {
      // transfer their existing map visibility objects to their new row
      newRows.forEach((incomingRow) => {
        const index = currentJobRowsWithMapVisibilityObject.findIndex(
          (currentRowWithVisibilityObject) => currentRowWithVisibilityObject.jobId === incomingRow.jobId
        );
        if (index > -1) {
          incomingRow.map = currentJobRowsWithMapVisibilityObject[index].map;
        }
      });
    }
  };

  useEffect(() => {
    if (gridReady) {
      setDatasource(
        createJobsServerSideDatasource({
          client: apolloClient,
          defaultFields: [
            "jobId",
            "jobNumber",
            "order.orderId",
            "stops.jobStopId",
            "stops.sequence",
            "stops.manifestSequence",
            "stops.address",
            "stops.city",
            "stops.state",
            "stops.zip",
            "stops.note",
            "stops.scheduledDateTime",
            "vehicleType.vehicleTypeId",
            "order.customer.customerId",
            "order.priority.orderPriorityId",
            "order.priority.description",
            "order.requirements.name",
            "site.siteId"
          ],
          excludeFields: ["map", "indicatorColor"],
          onDatasourceFail: datasourceFailCallback,
          decorateResults: [buildIndicatorColors, sortAndFilterByColors, transferActiveMapVisibilityToIncoming],
          tokenExpiry: authState.expiresAt ? new Date(authState.expiresAt) : undefined
        })
      );
    }
  }, [
    apolloClient,
    buildIndicatorColors,
    datasourceFailCallback,
    assignmentViewDispatch,
    sortAndFilterByColors,
    authState.expiresAt,
    gridReady
  ]);

  const defaultColumnDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      minWidth: 40,
      initialWidth: 75,
      suppressMenu: true
    } as ColDef;
  }, []);

  const rowStyle: RowStyle = {
    //Prevent select text when Shift
    userSelect: "none",
    fontSize: "12px"
  };

  const [getDistinctValues] = useGetDistinctValuesLazyQuery();
  const [searchOrderRequirementValues] = useSearchFeeTitlesLazyQuery({
    variables: {
      filter: {
        driverRequireFlag: {
          eq: true
        }
      },
      sort: {
        field: FeeTitleSortableField.Name,
        direction: SearchableSortDirection.Asc
      }
    }
  });

  const columnTypes: {[key: string]: ColDef<Job>} = useMemo(() => {
    return {
      filterColumn: {
        suppressMenu: false,
        menuTabs: ["filterMenuTab"],
        filterParams: {
          buttons: ["apply", "reset"],
          closeOnApply: true
        }
      },
      textFilter: {
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "notContains", "equals", "notEqual", "blank", "notBlank"]
        } as ITextFilterParams
      },
      numberFilter: {
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: [
            "equals",
            "notEqual",
            "lessThan",
            "lessThanOrEqual",
            "greaterThan",
            "greaterThanOrEqual",
            "inRange"
          ]
        }
      }
    };
  }, []);

  const colorColDefs: ColDef[] = useMemo(() => {
    return filterAndSortByColorsEnabled
      ? [
          {
            field: "indicatorColor",
            headerName: "Color",
            pinned: "left",
            lockPosition: "left",
            lockPinned: true,
            suppressMovable: true,
            suppressColumnsToolPanel: true,
            sortable: true,
            rowDrag: true,
            resizable: false,
            width: 60,
            cellRenderer: ColorIndicatorCellRenderer,
            type: "filterColumn",
            filter: "agSetColumnFilter",
            filterParams: {
              cellRenderer: ColorCellRenderer,
              values: ["No Color", ...(ruleContextState.rules ?? []).map((rule) => rule.event.params?.color)],
              suppressMiniFilter: true
            },
            cellClass: "drag-column"
          }
        ]
      : [
          {
            field: "indicatorColor",
            headerName: "Color",
            pinned: "left",
            lockPosition: "left",
            lockPinned: true,
            suppressMovable: true,
            sortable: false,
            rowDrag: false,
            resizable: false,
            width: 18,
            minWidth: 18,
            cellRenderer: ColorIndicatorCellRenderer,
            headerComponent: () => ""
          },
          {
            field: "",
            headerName: "",
            pinned: "left",
            lockPosition: "left",
            lockPinned: true,
            suppressMovable: true,
            sortable: false,
            resizable: false,
            rowDrag: true,
            cellStyle: {justifyContent: "center", alignItems: "center", display: "flex"},
            width: 18,
            minWidth: 18,
            suppressColumnsToolPanel: true,
            cellClass: "drag-column"
          }
        ];
  }, [filterAndSortByColorsEnabled, ruleContextState.rules]);

  const columnDefs: ColDef[] = useMemo(
    () => [
      ...colorColDefs,
      {
        field: "jobNumber",
        headerName: "Job #",
        pinned: "left",
        lockPosition: "left",
        sortable: true,
        type: ["filterColumn", "textFilter"],
        rowDrag: false,
        cellClass: "job-number-cell",
        cellRenderer: ButtonCellRenderer,
        valueGetter: (params) => {
          return {
            testIdPrefix: "more-details-button",
            text: params.data.jobNumber,
            minimal: true,
            intent: Intent.PRIMARY,
            onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>, data: any, authState: AuthState) => {
              gridRef.current?.api.showLoadingOverlay();
              navigateToCmsOrder(authState.tenant!, authState.token!, data.order.orderId!, tenantPreferences).finally(
                () => gridRef.current?.api.hideOverlay()
              );
            }
          };
        }
      },
      {
        colId: "order.customer.name",
        field: "order.customer.name",
        headerName: "Customer Account",
        type: "filterColumn",
        sortable: true,
        filter: CustomerFilter
      },
      {
        colId: "stop.pickup.dispatchZone",
        field: "stops.dispatchZone,stops.stopType",
        headerName: "Pickup Zone",
        sortable: true,
        valueGetter: (params) => pickupStop(params.data.stops)?.dispatchZone,
        type: ["filterColumn", "textFilter"]
      },
      {
        colId: "stop.delivery.dispatchZone",
        field: "stops.dispatchZone,stops.stopType",
        headerName: "Delivery Zone",

        sortable: true,
        valueGetter: (params) => deliveryStop(params.data.stops)?.dispatchZone,
        type: ["filterColumn", "textFilter"]
      },
      {
        colId: "stop.pickup.scheduledDateTime",
        field: "stops.scheduledDateTime,stops.timeZone,stops.stopType",
        sortable: true,
        headerName: "Ready",
        type: "filterColumn",
        filter: DateFilter,
        filterParams: {type: "range"},
        cellRenderer: doubleFieldCellRenderer,
        valueGetter: (params) => {
          const scheduledDateTime = pickupStop(params.data.stops)?.scheduledDateTime;
          const timeZone = pickupStop(params.data.stops)?.timeZone;
          const getTimeFnc = timezoneOfJobStop ? getTimeAtSpecificTimeZone : getLocalTime;
          const getDateFnc = timezoneOfJobStop ? getJobStopDate : getLocalDate;

          return {
            upperField: {
              value: getTimeFnc({
                dateStr: scheduledDateTime,
                timeZone,
                isTimeFormat24hr: appState.isTimeFormat24hr,
                options: {
                  includeZone: true
                }
              })
            },
            lowerField: {
              value: getDateFnc(scheduledDateTime, timeZone, "EEE MMM dd yyyy")
            }
          } as DoubleFieldCellValue;
        }
      },
      {
        colId: "stop.delivery.scheduledDateTime",
        field: "stops.scheduledDateTime,stops.timeZone,stops.stopType",
        sortable: true,
        headerName: "Due By",
        type: "filterColumn",
        filter: DateFilter,
        filterParams: {type: "range"},
        cellRenderer: doubleFieldCellRenderer,
        valueGetter: (params) => {
          const scheduledDateTime = deliveryStop(params.data.stops)?.scheduledDateTime;
          const timeZone = deliveryStop(params.data.stops)?.timeZone;
          const getTimeFnc = timezoneOfJobStop ? getTimeAtSpecificTimeZone : getLocalTime;
          const getDateFnc = timezoneOfJobStop ? getJobStopDate : getLocalDate;

          return {
            upperField: {
              value: getTimeFnc({
                dateStr: scheduledDateTime,
                timeZone,
                isTimeFormat24hr: appState.isTimeFormat24hr,
                options: {includeZone: true}
              })
            },
            lowerField: {
              value: getDateFnc(scheduledDateTime, timeZone, "EEE MMM dd yyyy")
            }
          } as DoubleFieldCellValue;
        }
      },
      {
        field: "service",
        headerName: "SVC Type",
        cellRenderer: serviceTypeCellRenderer,
        sortable: true,
        type: "filterColumn",
        filter: "agSetColumnFilter",
        filterParams: {
          cellRenderer: serviceTypeCellRenderer,
          values: (params: SetFilterValuesFuncParams) => {
            getFilterValues(params, getDistinctValues, "jobs", "service");
          }
        }
      },
      {
        field: "vehicleType.description",
        headerName: "Vehicle",
        sortable: true,
        type: "filterColumn",
        filter: "agSetColumnFilter",
        filterParams: {
          values: (params: SetFilterValuesFuncParams) => {
            getFilterValues(params, getDistinctValues, "jobs", "vehicleType.description.keyword");
          }
        }
      },
      {
        colId: "stop.pickup.address",
        field: "stops.address,stops.stopType",
        headerName: "Pickup Address",
        sortable: true,
        valueGetter: (params) => pickupStop(params.data.stops)?.address,
        type: ["filterColumn", "textFilter"]
      },
      {
        colId: "stop.delivery.address",
        field: "stops.address,stops.stopType",
        headerName: "Delivery Address",
        valueGetter: (params) => deliveryStop(params.data.stops)?.address,
        type: ["filterColumn", "textFilter"]
      },
      {field: "pieces", headerName: "Pieces", sortable: true, type: ["filterColumn", "numberFilter"], maxWidth: 67},
      {field: "weight", headerName: "Weight", sortable: true, type: ["filterColumn", "numberFilter"], maxWidth: 70},
      {field: "order.notes", headerName: "Order Notes", type: ["filterColumn", "textFilter"]},

      //------------DESELECT FIELDS------------//
      {
        field: "stops.dispatchZone,stops.stopType",
        headerName: "Zone",
        cellRenderer: doubleFieldCellRenderer,
        hide: true,
        valueGetter: (params) => {
          return {
            upperField: {
              value: pickupStop(params.data.stops)?.dispatchZone,
              tooltip: `Pickup: ${pickupStop(params.data.stops)?.dispatchZone}`
            },
            lowerField: {
              value: deliveryStop(params.data.stops)?.dispatchZone,
              tooltip: `Delivery: ${deliveryStop(params.data.stops)?.dispatchZone}`,
              style: {fontWeight: "lighter"}
            }
          } as DoubleFieldCellValue;
        }
      },
      {
        field: "stops.scheduledDateTime,stops.timeZone,stops.stopType",
        headerName: "Time",
        cellRenderer: doubleFieldCellRenderer,
        hide: true,
        valueGetter: (params) => {
          const pickupScheduledDateTime = pickupStop(params.data.stops)?.scheduledDateTime;
          const pickupTimeZone = pickupStop(params.data.stops)?.timeZone;
          const deliveryScheduledDateTime = deliveryStop(params.data.stops)?.scheduledDateTime;
          const deliveryTimeZone = deliveryStop(params.data.stops)?.timeZone;

          const getTimeFnc = timezoneOfJobStop ? getTimeAtSpecificTimeZone : getLocalTime;
          const getDateFnc = timezoneOfJobStop ? getDateTimeAtSpecificTimeZone : getLocalDateTime;

          return {
            upperField: {
              value: getTimeFnc({
                dateStr: pickupScheduledDateTime,
                timeZone: pickupTimeZone,
                isTimeFormat24hr: appState.isTimeFormat24hr,
                options: {
                  includeZone: true
                }
              }),
              tooltip: `Pickup: ${getDateFnc({
                dateStr: pickupStop(params.data.stops)?.scheduledDateTime,
                timeZone: pickupStop(params.data.stops)?.timeZone,
                isTimeFormat24hr: appState.isTimeFormat24hr
              })}`
            },
            lowerField: {
              value: getTimeFnc({
                dateStr: deliveryScheduledDateTime,
                timeZone: deliveryTimeZone,
                isTimeFormat24hr: appState.isTimeFormat24hr,
                options: {
                  includeZone: true
                }
              }),
              tooltip: `Delivery: ${getDateFnc({
                dateStr: deliveryStop(params.data.stops)?.scheduledDateTime,
                timeZone: deliveryStop(params.data.stops)?.timeZone,
                isTimeFormat24hr: appState.isTimeFormat24hr
              })}`,
              style: {fontWeight: "lighter"}
            }
          } as DoubleFieldCellValue;
        }
      },
      {
        field: "stops.address,stops.stopType",
        headerName: "Address",
        cellRenderer: doubleFieldCellRenderer,
        hide: true,
        valueGetter: (params) => {
          return {
            upperField: {
              value: pickupStop(params.data.stops)?.address,
              tooltip: `Pickup: ${pickupStop(params.data.stops)?.address}`
            },
            lowerField: {
              value: deliveryStop(params.data.stops)?.address,
              tooltip: `Delivery: ${deliveryStop(params.data.stops)?.address}`,
              style: {fontWeight: "lighter"}
            }
          } as DoubleFieldCellValue;
        }
      },
      {
        field: "dispatchGroup.name",
        headerName: "Job Group",
        hide: true,
        sortable: true,
        type: "filterColumn",
        filter: "agSetColumnFilter",
        filterParams: {
          values: jobGroups.map((d) => d.dispatchGroup!)
        }
      },
      {
        field: "jobStatus",
        headerName: "Status",
        sortable: true,
        hide: true,
        suppressColumnsToolPanel: true,
        cellRenderer: JobStatusCellRenderer,
        type: "filterColumn",
        filter: "agSetColumnFilter",
        filterParams: {
          cellRenderer: JobStatusCellRenderer,
          values: (params: SetFilterValuesFuncParams) => {
            getFilterValues(params, getDistinctValues, "jobs", "jobStatus");
          }
        }
      },
      {field: "order.caller", headerName: "Caller", hide: true, type: ["filterColumn", "textFilter"], sortable: true},
      {
        field: "order.callerPhone",
        headerName: "Phone",
        hide: true,
        type: ["filterColumn", "textFilter"],
        sortable: true
      },
      {
        field: "order.customer.alias",
        headerName: "Customer Alias",
        hide: true,
        type: ["filterColumn", "textFilter"],
        sortable: true
      },
      {
        field: "order.customer.customerCode",
        headerName: "Account #",
        hide: true,
        type: ["filterColumn", "textFilter"],
        sortable: true
      },
      {
        field: "order.customer.notes",
        headerName: "Customer Notes",
        hide: true,
        type: ["filterColumn", "textFilter"]
      },
      {
        field: "driver.driverCode",
        headerName: "Driver Code",
        hide: true,
        type: ["filterColumn", "textFilter"],
        sortable: true
      },
      {field: "order.liftGateRequired", headerName: "Lift Gate", hide: true /*TODO: BOOLEAN Filter*/},
      {
        field: "site.code",
        headerName: "Site",
        sortable: true,
        hide: true,
        type: ["filterColumn"],
        filter: "agSetColumnFilter",
        filterParams: {
          values: (params: SetFilterValuesFuncParams) => {
            getFilterValues(params, getDistinctValues, "ng_site", "code.keyword");
          }
        }
      },
      {
        field: "order.alias",
        headerName: "Order Alias",
        hide: true,
        type: ["filterColumn", "textFilter"],
        sortable: true
      },
      {
        field: "order.auth",
        headerName: "Order Auth",
        hide: true,
        type: ["filterColumn", "textFilter"],
        sortable: true
      },
      {
        field: "order.orderId",
        headerName: "Order Id",
        hide: true,
        type: ["filterColumn", "numberFilter"],
        sortable: true
      },
      {
        field: "order.description",
        headerName: "Order Description",
        hide: true,
        type: ["filterColumn", "textFilter"],
        sortable: true
      },
      {
        field: "order.priority.description",
        headerName: "Order Priority",
        hide: true,
        type: ["filterColumn", "textFilter"],
        sortable: true
      },
      {
        field: "routeNumber",
        headerName: "Route#",
        sortable: true,
        hide: true,
        type: ["filterColumn", "textFilter"]
      },
      {
        field: "order.totalMiles",
        headerName: "Total Mileage",
        hide: true,
        type: ["filterColumn", "numberFilter"],
        sortable: true
      },
      {
        field: "order.origin",
        headerName: "Origin",
        hide: true,
        type: ["filterColumn", "textFilter"],
        sortable: true
      },
      {
        field: "order.packageType.description",
        headerName: "Package Type",
        hide: true,
        type: ["filterColumn"],
        sortable: true,
        filter: "agSetColumnFilter",
        filterParams: {
          values: (params: SetFilterValuesFuncParams) => {
            getFilterValues(params, getDistinctValues, "ng_packagetype", "description.keyword");
          }
        }
      },
      {
        field: "order.qualityFlag",
        headerName: "Quality Flag",
        hide: true,
        sortable: true /*TODO: BOOLEAN Filter*/
      },
      {
        colId: "stop.pickup.name",
        field: "stops.name,stops.stopType",
        headerName: "Pickup Name",
        valueGetter: (params) => pickupStop(params.data.stops)?.name,
        hide: true,
        type: ["filterColumn", "textFilter"]
      },
      {
        colId: "stop.pickup.city",
        field: "stops.city,stops.stopType",
        headerName: "Pickup City",
        sortable: true,
        valueGetter: (params) => pickupStop(params.data.stops)?.city,
        hide: true,
        type: ["filterColumn", "textFilter"]
      },
      {
        colId: "stop.pickup.state",
        field: "stops.state,stops.stopType",
        headerName: "Pickup State",
        valueGetter: (params) => pickupStop(params.data.stops)?.state,
        hide: true,
        type: ["filterColumn", "textFilter"]
      },
      {
        colId: "stop.pickup.note",
        field: "stops.note,stops.stopType",
        headerName: "Pickup Notes",
        valueGetter: (params) => pickupStop(params.data.stops)?.note,
        hide: true,
        type: ["filterColumn", "textFilter"]
      },
      {
        colId: "stop.pickup.zip",
        field: "stops.zip,stops.stopType",
        headerName: "Pickup Zip Code",
        valueGetter: (params) => pickupStop(params.data.stops)?.zip,
        hide: true,
        type: ["filterColumn", "textFilter"]
      },
      {
        colId: "stop.delivery.name",
        field: "stops.name,stops.stopType",
        headerName: "Delivery Name",
        valueGetter: (params) => deliveryStop(params.data.stops)?.name,
        hide: true,
        type: ["filterColumn", "textFilter"]
      },
      {
        colId: "stop.delivery.city",
        field: "stops.city,stops.stopType",
        headerName: "Delivery City",
        sortable: true,
        valueGetter: (params) => deliveryStop(params.data.stops)?.city,
        hide: true,
        type: ["filterColumn", "textFilter"]
      },
      {
        colId: "stop.delivery.state",
        field: "stops.state,stops.stopType",
        headerName: "Delivery State",
        valueGetter: (params) => deliveryStop(params.data.stops)?.state,
        hide: true,
        type: ["filterColumn", "textFilter"]
      },
      {
        colId: "stop.delivery.note",
        field: "stops.note,stops.stopType",
        headerName: "Delivery Notes",
        valueGetter: (params) => deliveryStop(params.data.stops)?.note,
        hide: true,
        type: ["filterColumn", "textFilter"]
      },
      {
        colId: "stop.delivery.zip",
        field: "stops.zip,stops.stopType",
        headerName: "Delivery Zip Code",
        valueGetter: (params) => deliveryStop(params.data.stops)?.zip,
        hide: true,
        type: ["filterColumn", "textFilter"]
      },
      {
        field: "stopCount",
        headerName: "Number of Stops",
        sortable: true,
        type: ["filterColumn", "numberFilter"],
        hide: true
      },
      {
        field: "order.requirements.name",
        headerName: "Order Requirements",
        valueGetter: (params) => params.data.order?.requirements?.map((or: OrderRequirement) => or.name).join(", "),
        sortable: false,
        hide: true,
        type: ["filterColumn"],
        filter: "agSetColumnFilter",
        filterParams: {
          values: (params: SetFilterValuesFuncParams) => {
            searchOrderRequirementValues()
              .then((x: ApolloQueryResult<any>) => {
                params.success(x.data.searchFeeTitles.items.map((y: FeeTitle) => y.name));
              })
              .catch((error: Error) => {
                console.error(error);
              });
          }
        }
      },
      {
        field: "order.createdDateTime",
        hide: true,
        sortable: true,
        headerName: "Order Placed",
        type: "filterColumn",
        filter: DateFilter,
        filterParams: {
          type: "range",
          dateRangeShortcuts: [
            {label: "Today", dateRange: [new Date(), new Date()]},
            {label: "Yesterday", dateRange: [subDays(new Date(), 1), subDays(new Date(), 1)]},
            {label: "Last 7 days", dateRange: [subDays(new Date(), 6), new Date()]},
            {label: "Last 14 days", dateRange: [subDays(new Date(), 13), new Date()]}
          ]
        },
        cellRenderer: doubleFieldCellRenderer,
        valueGetter: (params) => {
          return {
            upperField: {
              value: getLocalTime({
                dateStr: params.data.order.createdDateTime,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                isTimeFormat24hr: appState.isTimeFormat24hr,
                options: {
                  includeZone: true
                }
              })
            },
            lowerField: {
              value: getLocalDate(
                params.data.order.createdDateTime,
                Intl.DateTimeFormat().resolvedOptions().timeZone,
                "EEE MMM dd yyyy"
              )
            }
          } as DoubleFieldCellValue;
        }
      },
      {
        field: "order.declaredValue",
        headerName: "Declared Value",
        sortable: true,
        type: ["filterColumn", "numberFilter"],
        initialHide: true
      },
      {field: "jobId", headerName: "JobId", sortable: true, hide: true, suppressColumnsToolPanel: true},
      {
        field: "map",
        hide: false, // map column to remain visible at all times
        headerName: "Map",
        headerComponent: "div",
        suppressColumnsToolPanel: true,
        pinned: "right",
        lockPinned: true,
        resizable: false,
        maxWidth: 35,
        headerClass: "map-icon-header",
        cellClass: "map-icon-cell",
        cellStyle: {display: "flex", alignItems: "center", "justify-content": "center", padding: "2px"},
        cellRenderer: MapIconCellRenderer,
        valueGetter: (params) => params.data.jobId
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [colorColDefs]
  ) as ColDef[];

  const onRestoreDefaults = useCallback(
    (justRestoreFilters?: boolean) => {
      gridRef.current?.api.setFilterModel(null);
      setSelectedTimeFilter(undefined);
      setTimeFilterTitle("Ready / Due");
      setSelectedPlacedInLastTime(undefined);
      setSelectedJobGroups([]);
      resetViewGestured();
      const promises = [
        setUserPref({variables: {name: UserPreferences.jobGridFilterReadyDue, input: createJsonPref(null, true)}}),
        setUserPref({variables: {name: UserPreferences.jobGridFilterPlaced, input: createJsonPref(null, true)}}),
        setUserPref({variables: {name: UserPreferences.jobGridFilterState, input: createJsonPref(null, true)}})
      ];
      if (!justRestoreFilters) {
        promises.push(
          setUserPref({variables: {name: UserPreferences.jobGridColumnState, input: createJsonPref(null, true)}})
        );
      }
      Promise.all(promises).then(() => {
        userPrefsQueryRefetch?.();
      });

      if (!justRestoreFilters) {
        gridRef.current?.columnApi.resetColumnState();
      }

      gridRef.current?.api.onFilterChanged();
    },
    [resetViewGestured, setUserPref, userPrefsQueryRefetch]
  );

  const sideBar = useMemo<SideBarDef | string | string[] | boolean | null>(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressColumnSelectAll: true,
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true
          }
        },
        {
          id: "settings",
          labelDefault: "Settings",
          labelKey: "settings",
          iconKey: "menu",
          toolPanel: GridSettingsToolPanel,
          toolPanelParams: {
            onRestoreDefaults: onRestoreDefaults
          }
        }
      ],
      defaultToolPanel: "customStats"
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGridReady = useCallback(
    (e) => {
      setGridReady(true);
      if (gridRef.current) {
        getGridRef?.(gridRef.current);
      }
      // DP-1305
      // assure that any older saved grid state, where the map column had been previously hidden, is restored to visibility
      e.columnApi.setColumnsVisible(["map"], true);
    },
    [getGridRef]
  );

  useEffect(() => {
    if (gridReady) {
      const redrawRowNodes: IRowNode<Job>[] = [];
      const pendingRowIds: string[] = [];
      assignmentState.pendingActions.forEach((x) => {
        let rowAction: (row: IRowNode<Job>) => void = () => {
          //noop default
        };
        switch (x.status) {
          case JobAssignmentSubmissionResult.PENDING:
            rowAction = (row) => {
              row.setSelected(false, undefined, undefined, "assignment" as SelectionEventSourceType);
              pendingRowIds.push(row.id as string);
            };
            break;

          case JobAssignmentSubmissionResult.SUBMISSION_SUCCESS:
          case JobAssignmentSubmissionResult.ASSIGNMENT_SUCCESS:
            rowAction = (row) => {
              pendingRowIds.push(row.id as string);
            };
            break;
        }
        x.jobIds.forEach((y) => {
          const row = gridRef.current!.api.getRowNode(y.toString());
          if (row) {
            rowAction(row);
            redrawRowNodes.push(row);
          }
        });
      });

      if (redrawRowNodes.length > 0) {
        const jobNumberCol = gridRef.current!.columnApi.getColumn("jobNumber");
        if (jobNumberCol) {
          const jobNumberColDef = jobNumberCol.getColDef()!;
          jobNumberColDef.rowDrag = (params) => !pendingRowIds.includes(params.node.id as string);
          jobNumberCol.setColDef(jobNumberColDef, null);
          gridRef.current!.api.redrawRows({rowNodes: redrawRowNodes});
        }
      }
    }
  }, [assignmentState.pendingActions, gridReady]);

  const onCellClicked = useCallback(() => {
    if (gridRef.current?.api.isToolPanelShowing()) {
      gridRef.current?.api.closeToolPanel();
    }
  }, []);

  const handleSelectedRow = useCallback(
    (event: RowSelectedEvent<Job>) => {
      const notValidSources: SelectionEventSourceType[] = ["assignment" as SelectionEventSourceType, "apiSelectAll"];
      if (notValidSources.includes(event.source)) return;

      assignmentDispatch({type: "SetSelectedJobs", payload: event.data as Job});
      assignmentViewDispatch({
        type: JobViewActionTypes.SET_JOBS_NUMBER_ON_ALL_ASSIGNMENTS,
        payload: event.data?.jobNumber as string
      });
    },
    [assignmentDispatch, assignmentViewDispatch]
  );

  const getRowId = useCallback((params) => params.data.jobId.toString(), []);

  const isRowSelectable = useCallback(
    (row: IRowNode<Job>) => {
      return !activePendingActions(assignmentState.pendingActions)
        .flatMap((x) => x.jobIds)
        .includes(row.data?.jobId as number);
    },
    [assignmentState.pendingActions]
  );

  const getRowStyle = useCallback(
    (params: RowClassParams<AgJob>) => {
      if (
        activePendingActions(assignmentState.pendingActions)
          .flatMap((x) => x.jobIds)
          .includes(params.data?.jobId as number)
      ) {
        return {backgroundColor: "#EDEFF2"};
      }
      return {backgroundColor: params.rowIndex % 2 ? "white" : "#F8F6F6FF"};
    },
    [assignmentState.pendingActions]
  );

  useEffect(() => {
    if (gridReady) {
      //remove existing drop zones
      if (currentDragAndDropTargets) {
        currentDragAndDropTargets.forEach((dropZone: RowDropZoneParams) => {
          gridRef.current!.api.removeRowDropZone(dropZone);
        });
      }

      const dropZoneParamsToSave: RowDropZoneParams[] = [];
      dragAndDropTargets.forEach((x) => {
        const dropZoneParams = {
          getContainer: () => x.element,
          onDragEnter: () =>
            (x.element.style["boxShadow"] = "0 0 0 2px rgb(24 74 144 / 60%), 0 2px 2px rgb(24 74 144 / 100%)"),
          onDragLeave: () => (x.element.style["boxShadow"] = ""),
          onDragStop: (params: RowDragEndEvent) => {
            x.element.style["boxShadow"] = "";
            if (assignmentState.selectedManifest?.manifestDriverId !== x.manifest.manifestDriverId) {
              assignmentDispatch({type: "SetSelectedManifest", payload: x.manifest});
            }
            if (params.nodes.map((job) => job.data.jobId)?.length === 1) {
              assignmentDispatch({
                type: "SetSelectedJob",
                payload: {
                  pk: "",
                  sk: "",
                  ...params.node.data
                } as unknown as Job
              });
            }
            if (driverRequirementsEnabled) {
              const selectedJobs: Job[] = params.nodes.map((node) => node.data);
              handleAssignJobs(selectedJobs, x.manifest);
            } else {
              assign();
            }
          }
        };
        dropZoneParamsToSave.push(dropZoneParams);
        gridRef.current!.api.addRowDropZone(dropZoneParams);
      });
      assignmentViewDispatch({
        type: JobViewActionTypes.SET_CURRENT_DRAG_AND_DROP_TARGETS,
        payload: dropZoneParamsToSave
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    gridReady,
    dragAndDropTargets,
    assignmentDispatch,
    assignmentViewDispatch,
    assignJobsToManifest,
    assignmentState.selectedManifest?.manifestDriverId
  ]);

  const rowDragText = useCallback((params: IRowDragItem, dragItemCount: number) => {
    return dragItemCount === 1 ? `Job #${params.rowNode?.data.jobNumber}` : `${dragItemCount} Jobs`;
  }, []);

  const onRowDragEnter = useCallback((e: RowDragEnterEvent) => {
    e.api.setEnableCellTextSelection(false);
    document.getElementsByTagName("body")[0].style["userSelect"] = "none";
  }, []);

  const onRowDragEnd = useCallback((e: RowDragEndEvent) => {
    e.api.setEnableCellTextSelection(true);
    document.getElementsByTagName("body")[0].style["userSelect"] = "";
  }, []);

  const openOrCloseToolPanel = (key: string) => {
    if (gridReady) {
      if (gridRef.current!.api.getOpenedToolPanel() === key) {
        gridRef.current!.api.closeToolPanel();
      } else {
        gridRef.current!.api.openToolPanel(key);
      }
    }
  };

  const clearTimeFilter = () => {
    if (selectedTimeFilter) {
      clearTimeFilters();
      setUserPref({
        variables: {
          name: UserPreferences.jobGridFilterReadyDue,
          input: createJsonPref(null, true)
        }
      }).then(() => {
        userPrefsQueryRefetch?.();
      });
    }
  };

  const clearPlacedInLastTime = () => {
    if (selectedPlacedInLastTime) {
      gridRef.current!.api.getFilterInstance("order.createdDateTime", (filter) => {
        setSelectedPlacedInLastTime(undefined);
        filter?.setModel(null);
      });
      setUserPref({
        variables: {
          name: UserPreferences.jobGridFilterPlaced,
          input: createJsonPref(null, true)
        }
      }).then(() => {
        userPrefsQueryRefetch?.();
      });
    }
  };

  const clearTimeFilters = () => {
    setTimeFilterTitle("Ready / Due");
    setSelectedTimeFilter(undefined);
    gridRef.current!.api.getFilterInstance("stop.pickup.scheduledDateTime", (filter) => {
      filter?.setModel(null);
    });
    gridRef.current!.api.getFilterInstance("stop.delivery.scheduledDateTime", (filter) => {
      filter?.setModel(null);
    });
  };

  const handleGlobalSearch = (query: string) => {
    if (gridContext.globalQuery != query) {
      setGridContext({...gridContext, globalQuery: query});
      resetViewGestured();
    }
  };

  useEffect(() => {
    if (gridReady) {
      gridRef.current!.api.onFilterChanged();
    }
  }, [gridReady, gridContext]);

  useEffect(() => {
    const prefVal: PrefValue<DriverRequirementEnforcementLevel> = extractSimplePref(
      tenantPreferences,
      TenantPreferences.driverRequirementEnforcement,
      DriverRequirementEnforcementLevel.Allow
    );
    setDriverRequiredSetting(prefVal.value as DriverRequirementEnforcementLevel);
  }, [tenantPreferences]);

  const handleAssignJobs = (dragSelectedJobs?: Job[], selectedManifestByDrop?: Manifest) => {
    let selectedManifest;
    if (selectedManifestByDrop) {
      selectedManifest = [selectedManifestByDrop];
    } else if (assignmentState.selectedManifest) {
      selectedManifest = [assignmentState.selectedManifest];
    } else {
      selectedManifest = undefined;
    }
    const selectedJobs =
      dragSelectedJobs && dragSelectedJobs.length > 0 && dragSelectedJobs[0].jobId
        ? dragSelectedJobs
        : assignmentState.selectedJobs;

    const isMatchRequirementsManifest =
      getMatchRequirementManifests({
        selectedJobs: selectedJobs,
        manifests: selectedManifest
      }).length === 1;

    const isRequirementsEmpty = selectedJobs.filter((job) => job?.order?.requirements).length === 0;

    if (
      isMatchRequirementsManifest ||
      isRequirementsEmpty ||
      driverRequiredSetting === DriverRequirementEnforcementLevel.Allow
    ) {
      assign();
    } else {
      setShowAssignJobsConfirm(true);
    }
  };

  const handleAssignJobsConfirm = () => {
    setShowAssignJobsConfirm(false);
    assign();
  };

  const assign = () => {
    assignmentDispatch({
      type: "AssignJobsToManifest",
      payload: {assignFunction: assignJobsToManifest, authState: authState}
    });
    clearSelection();
  };

  const clearSelection = () => {
    if (gridReady) {
      assignmentDispatch({type: "ClearSelections"});
      gridRef.current!.api.deselectAll();
      assignmentDispatch({type: "SetSelectedManifest", payload: undefined});
      if (driverRequirementsEnabled) {
        assignmentDispatch({type: "SetHighlightManifest", payload: []});
        setIsEnableQualified(false);
      }
    }
  };

  const toggleQualifiedDriversHandler = () => {
    setIsEnableQualified((prev) => {
      if (prev) {
        assignmentDispatch({type: "SetHighlightManifest", payload: []});
      } else {
        const highlightManifests = getMatchRequirementManifests({
          selectedJobs: assignmentState.selectedJobs,
          manifests: manifestDataState.data
        });
        assignmentDispatch({type: "SetHighlightManifest", payload: highlightManifests});
        if (highlightManifests.length === 0) {
          showQualifiedDriversWarningToast();
        }
      }
      return !prev;
    });
  };

  useEffect(() => {
    if (gridReady) {
      mapVisibilityState.jobs.forEach((x) => {
        const row = gridRef.current!.api.getRowNode(x.entityId.toString());
        const isVisible = x.visibilityLevel !== EntityVisibilityLevel.NONE;
        const agJob = row?.data;
        if (agJob) {
          if (agJob.map?.color !== x.routeColor || agJob.map?.visible !== isVisible) {
            row?.setDataValue("map", {color: x.routeColor, visible: isVisible});
          }
        } else {
          mapVisibilityDispatch({type: "ClearJobVisibility", jobId: x.entityId});
        }
      });
    }
  });

  const onPaginationChanged = useCallback(
    (event: PaginationChangedEvent<Job>) => {
      const currentPage = event.api.paginationGetCurrentPage() + 1;
      const pageSize = event.api.paginationGetPageSize();
      const startRow = (currentPage - 1) * pageSize;
      const endRow = currentPage * pageSize - 1;
      const jobIds: number[] = [];
      const jobIdsAndJobNumbers: {jobId: number; jobNumber: string}[] = [];
      event.api.forEachNode((rowNode) => {
        if (
          rowNode.data?.jobId &&
          isNumber(rowNode.rowIndex) &&
          rowNode.rowIndex >= startRow &&
          rowNode.rowIndex <= endRow &&
          !(rowNode.data as ExtendedAgJob).hide
        ) {
          jobIds.push(rowNode.data.jobId);
        }
        if (rowNode.data) {
          jobIdsAndJobNumbers.push({jobId: rowNode.data.jobId, jobNumber: rowNode.data.jobNumber});
        }
      });

      assignmentViewDispatch({
        type: JobViewActionTypes.SET_SHOWING_JOBIDS,
        payload: {
          jobIds,
          total: event.api.paginationGetRowCount()
        }
      });

      if (jobIdsAndJobNumbers.length > 0) {
        assignmentViewDispatch({
          type: JobViewActionTypes.SET_CURRENT_JOBS_IDS_AND_NUMBERS_ON_PAGES,
          payload: jobIdsAndJobNumbers
        });
      }

      if (currentPage !== currentJobPanePage) {
        mapVisibilityDispatch({type: "SetIsViewGestured", payload: false});
        setCurrentJobPanePage(currentPage);
      }
    },
    [assignmentViewDispatch, currentJobPanePage, mapVisibilityDispatch]
  );

  const onFirstDataRendered = useCallback(() => {
    mapVisibilityDispatch({
      type: "SetJobPanelLoaded",
      isLoaded: true
    });
  }, [mapVisibilityDispatch]);

  const gridOptions = {
    getRowClass: (params: RowClassParams) => {
      if ((params.node.data as AgJob & {hide: boolean})?.hide) {
        return "hide-row";
      }
    }
  };

  useEffect(() => {
    if (
      [UnassignedJobsVisibilityTypes.PICK_UP, UnassignedJobsVisibilityTypes.DELIVERIES].includes(
        mapVisibilityState.unassignedJobVisibilityType
      )
    ) {
      mapVisibilityDispatch({
        type: "SetAllUnassignedJobsVisibility",
        jobIds: assignmentViewState?.showingJobIds ?? []
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentViewState?.unassignedJobsPage, assignmentViewState?.showingJobIds]);

  useEffect(() => {
    if (assignmentState.selectedJobs.length === 0 || !isEnableQualified || !driverRequirementsEnabled) return;
    if (!assignmentState.selectedJobs.some((job) => getArrayFromField(job.order?.requirements).length > 0)) {
      setIsEnableQualified(false);
      return assignmentDispatch({type: "SetHighlightManifest", payload: []});
    }
    const highlightManifests = getMatchRequirementManifests({
      selectedJobs: assignmentState.selectedJobs,
      manifests: manifestDataState.data
    });
    return assignmentDispatch({type: "SetHighlightManifest", payload: highlightManifests});
  }, [
    assignmentDispatch,
    assignmentState.selectedJobs,
    manifestDataState.data,
    isEnableQualified,
    driverRequirementsEnabled
  ]);

  const onRemoveFilters = useCallback(() => {
    onRestoreDefaults(true);
  }, [onRestoreDefaults]);

  const activeFilter: IActiveFilterDialogProps = {
    message:
      "To view all unassigned jobs, please clear any active filters in the table header that may be limiting your job list.",
    title: "Active Filters Present",
    icon: <Icon icon="filter" color="green" size={21} />,
    actionButton: {
      label: "Remove Filter(s)",
      action: onRemoveFilters
    }
  };

  return (
    <PanelContainer>
      <ActiveFilterDialog ref={jobDialogRefs} {...activeFilter} />
      <AssignJobsConfirmation
        testIdPrefix={"job-panel-assign-job"}
        isVisible={showAssignJobsConfirm}
        assignmentState={assignmentState}
        driverRequiredSetting={driverRequiredSetting}
        handleConfirm={handleAssignJobsConfirm}
        handleClose={() => setShowAssignJobsConfirm(false)}
      />
      <ButtonBar size={warningMessage ? 86 : 62} hasWarningMessage={Boolean(warningMessage)}>
        <ButtonContainer>
          <LeftControls>
            <MultiSelectDropDown
              testIdPrefix={"job-group-multiselect"}
              text={"Groups"}
              items={jobGroupOptions}
              onSelected={onJobGroupSelected}
              onUnselected={onJobGroupUnselected}
              onUnselectedAll={onJobGroupUnselectedAll}
              clearFilter
            />
            <TimePickerFilterPopover
              title={timeFilterTitle}
              options={TimeFilterOptions}
              selectedOption={selectedTimeFilter}
              onConfirm={onConfirmSelectedTimeFilter}
              onClear={clearTimeFilter}
            />
            <TimeQuickFilterSelect
              title={"Placed in Last"}
              options={placedInLastOptions}
              selectedOption={selectedPlacedInLastTime}
              onSelectedOption={handleSelectedPlacedInLastTime}
              onClearFilter={clearPlacedInLastTime}
            />
          </LeftControls>
          <RightControls>
            <ControlGroup>
              <Search
                idPrefix={"jobs"}
                onSearch={handleGlobalSearch}
                isConstrainedByFilters={gridHasActiveFilters}
                minimal
              />
              <Button
                minimal
                title={"Columns"}
                icon={<FontAwesomeIcon style={{width: "16px", height: "14px"}} icon={solid("line-columns")} />}
                onClick={() => {
                  openOrCloseToolPanel("columns");
                }}
              />
              <Button
                minimal
                title={"Settings"}
                icon={<FontAwesomeIcon style={{width: "16px", height: "15px"}} icon={solid("sliders")} />}
                onClick={() => {
                  openOrCloseToolPanel("settings");
                }}
              />
            </ControlGroup>
          </RightControls>
        </ButtonContainer>
        {warningMessage && (
          <WarningText>
            <WarningIcon />
            <Text ellipsize>{warningMessage}</Text>
          </WarningText>
        )}
      </ButtonBar>
      <Fill className={"ag-theme-alpine"}>
        <AgGridReact<Job>
          className={"jobs-panel-grid"}
          ref={gridRef}
          onGridReady={handleGridReady}
          getRowId={getRowId}
          columnDefs={columnDefs}
          context={gridContext}
          suppressCellFocus
          defaultColDef={defaultColumnDef}
          columnTypes={columnTypes}
          rowSelection={"multiple"}
          rowMultiSelectWithClick
          onRowSelected={handleSelectedRow}
          enableCellTextSelection
          ensureDomOrder
          isRowSelectable={isRowSelectable}
          getRowStyle={getRowStyle}
          rowDragMultiRow
          rowDragText={rowDragText}
          onRowDragEnter={onRowDragEnter}
          onRowDragEnd={onRowDragEnd}
          sideBar={sideBar}
          onColumnMoved={onColumnMoveChanged}
          onSortChanged={onColumnSortChanged}
          onColumnVisible={onColumnVisible}
          onColumnResized={onColumnMoveChanged}
          onFilterChanged={onFilterChanged}
          rowModelType={"serverSide"}
          serverSideDatasource={datasource}
          pagination
          paginationPageSize={100}
          cacheBlockSize={100}
          animateRows
          gridOptions={gridOptions}
          onCellClicked={onCellClicked}
          suppressColumnVirtualisation={true}
          onPaginationChanged={onPaginationChanged}
          onFirstDataRendered={onFirstDataRendered}
          maintainColumnOrder
          rowStyle={rowStyle}
          suppressMultiSort={true}
        ></AgGridReact>
      </Fill>
      {assignmentState.selectedJobs.length > 0 &&
        (driverRequirementsEnabled ? (
          <AssignmentBarV2Pinned
            selectedManifest={assignmentState.selectedManifest}
            selectedJobs={assignmentState.selectedJobs}
            assignText="Assign"
            isEnableQualified={isEnableQualified}
            onAssign={handleAssignJobs}
            onClearSelection={clearSelection}
            onQualify={toggleQualifiedDriversHandler}
          />
        ) : (
          <AssignmentBarPinned
            selectedManifest={assignmentState.selectedManifest}
            selectedJobs={assignmentState.selectedJobs}
            onAssign={assign}
            onClearSelection={clearSelection}
          />
        ))}
    </PanelContainer>
  );
};

export default JobPanel;
