import React, {Dispatch, useCallback, useEffect, useState} from "react";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import "./TimePickerCommon.css";
import styled from "@emotion/styled";
import {combineDateAndTime} from "../../../../utils/General";
import {Button} from "@blueprintjs/core";
import {getTimezoneOffset} from "date-fns-tz";
import {getIANATimezone} from "../../../../utils/DateUtils";
import {addMinutes, isValid} from "date-fns";
import {Popover2} from "@blueprintjs/popover2";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {Constants} from "../../../common/Constants";

export interface TimePickerProps {
  onChange: (date: Date | null, fieldType?: string) => void;
  earliestDate: Date | null;
  timeZoneAddress: string | undefined;
  setIsStartDateTimeEdited: Dispatch<React.SetStateAction<boolean>>;
}
const Disabled_Text = "#777777";
const Active_Text = "#14305A";

const TimePickerCommon: React.FC<TimePickerProps> = ({
  timeZoneAddress,
  onChange,
  earliestDate,
  setIsStartDateTimeEdited
}) => {
  const [timeValue, setTimeValue] = useState<Date | null | undefined>(null);
  const [dateValue, setDateValue] = useState<Date | null | undefined>(null);
  const [openTimePicker, setOpenTimePicker] = useState<boolean>(false);
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);
  const [openInvalidTimePopup, setOpenInvalidTimePopup] = useState<boolean>(false);
  const [openInvalidDatePopup, setOpenInvalidDatePopup] = useState<boolean>(false);

  const handleChangeTime = useCallback(
    (e: Date | null | undefined) => {
      setIsStartDateTimeEdited?.(true);
      if (e) {
        setTimeValue(e);
      }
    },
    [setIsStartDateTimeEdited]
  );

  const handleChangeDate = useCallback(
    (e: unknown) => {
      setIsStartDateTimeEdited?.(true);
      if (e instanceof Date) {
        setDateValue(e);
      }
    },
    [setIsStartDateTimeEdited]
  );

  const handleOpenTimePicker = useCallback(() => {
    setOpenTimePicker(true);
  }, []);

  const handleCloseTimePicker = useCallback(() => {
    setOpenTimePicker(false);
  }, []);

  const handleOpenDatePicker = useCallback(() => {
    setOpenDatePicker(true);
  }, []);

  const handleCloseDatePicker = useCallback(() => {
    setOpenDatePicker(false);
  }, []);

  const handleOk = useCallback(() => {
    if (timeValue && !isValid(timeValue)) {
      setOpenInvalidTimePopup(true);
    }
    setOpenTimePicker(false);
  }, [timeValue]);

  const handleClear = useCallback(() => {
    setOpenTimePicker(false);
    setTimeValue(null);
  }, []);

  const renderActionBar = useCallback(() => {
    return <ActionBar handleClear={handleClear} handleOk={handleOk} active={Boolean(timeValue)} />;
  }, [handleClear, handleOk, timeValue]);

  const handleBlurTimeField = useCallback(() => {
    if (timeValue && !isValid(timeValue)) {
      setOpenInvalidTimePopup(true);
    }
  }, [timeValue]);

  const handleBlurDateField = useCallback(() => {
    if (dateValue && !isValid(dateValue)) {
      setOpenInvalidDatePopup(true);
    }
  }, [dateValue]);

  const handleCloseTimePopOver = useCallback(() => {
    setOpenInvalidTimePopup(false);
  }, []);

  const handleCloseDatePopOver = useCallback(() => {
    setOpenInvalidDatePopup(false);
  }, []);

  useEffect(() => {
    if (earliestDate) {
      setDateValue(earliestDate);
      setTimeValue(earliestDate);
    }
  }, [earliestDate]);

  useEffect(() => {
    if (timeZoneAddress && isValid(timeValue) && isValid(dateValue)) {
      const combinedDateTime = combineDateAndTime(timeValue!, dateValue!);
      const offsetTimeZoneAddress = getTimezoneOffset(getIANATimezone(timeZoneAddress)) / (60 * 1000);
      const offsetBetweenEarliestAndBrowser = Constants.BROWSER_TIMEZONE_OFFSET_MINUTES - offsetTimeZoneAddress;
      onChange(addMinutes(combinedDateTime, offsetBetweenEarliestAndBrowser));
    } else {
      onChange(null);
    }
  }, [timeZoneAddress, timeValue, dateValue, onChange]);

  return (
    <StartDateContainer data-testid="date-picker-container">
      <Popover2
        isOpen={openInvalidDatePopup}
        content={
          <InvalidPopOverContent text={"Please enter a valid start date."} onClosePopOver={handleCloseDatePopOver} />
        }
        onClose={handleCloseDatePopOver}
        interactionKind="click-target"
        hasBackdrop={true}
        placement="top"
      >
        <TimePickerContainer>
          <StyledDatePicker
            onChange={handleChangeDate}
            value={dateValue}
            open={openDatePicker}
            onClose={handleCloseDatePicker}
            closeOnSelect={true}
            slotProps={{
              field: {onBlur: handleBlurDateField},
              textField: {placeholder: "ex. 01/01/2025"}
            }}
          />
          <OpenDatePickerIcon handleOpenDatePicker={handleOpenDatePicker} />
        </TimePickerContainer>
      </Popover2>
      at
      <Popover2
        isOpen={openInvalidTimePopup}
        content={
          <InvalidPopOverContent text={"Please enter a valid start time."} onClosePopOver={handleCloseTimePopOver} />
        }
        onClose={handleCloseTimePopOver}
        interactionKind="click-target"
        hasBackdrop={true}
        placement="top"
      >
        <TimePickerContainer data-testid="time-picker-container">
          <TimePicker
            className="optimization-time-picker"
            data-testid={"time-picker"}
            ampm
            value={timeValue}
            onChange={handleChangeTime}
            open={openTimePicker}
            onClose={handleCloseTimePicker}
            closeOnSelect={false}
            onAccept={handleOk}
            slots={{
              actionBar: renderActionBar,
              openPickerIcon: OpenTimePickerIcon
            }}
            slotProps={{
              field: {onBlur: handleBlurTimeField},
              textField: {placeholder: "hh:mm aa"}
            }}
          />
          <div data-testid="time-picker-right" className="time-picker-right" onClick={handleOpenTimePicker}></div>
        </TimePickerContainer>
      </Popover2>
    </StartDateContainer>
  );
};

export default TimePickerCommon;

interface ActionBarProps {
  handleOk: () => void;
  handleClear: () => void;
  active: boolean;
}

const ActionBar = ({handleClear, handleOk, active}: ActionBarProps) => {
  return (
    <ActionBarContainer data-testid="action-bar">
      <ClearButton data-testid="clear-button" onClick={handleClear} active={active}>
        Clear
      </ClearButton>
      <OKButton data-testid="ok-button" onClick={handleOk} active={active}>
        OK
      </OKButton>
    </ActionBarContainer>
  );
};
ActionBar.displayName = "ActionBar";

interface OpenDatePickerProps {
  handleOpenDatePicker: () => void;
}

const OpenDatePickerIcon = ({handleOpenDatePicker}: OpenDatePickerProps) => {
  return (
    <StyledOpenDatePickerIcon
      data-testid="open-date-picker-icon"
      onClick={handleOpenDatePicker}
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.10625 0.850006C6.52046 0.850006 6.85625 1.18579 6.85625 1.60001V2.45001H10.1563V1.60001C10.1563 1.18579 10.492 0.850006 10.9063 0.850006C11.3205 0.850006 11.6563 1.18579 11.6563 1.60001V2.45001H12.5063C13.8041 2.45001 14.8562 3.50214 14.8562 4.80001V7.20001V12.8C14.8562 14.0979 13.8041 15.15 12.5062 15.15H4.50625C3.20838 15.15 2.15625 14.0979 2.15625 12.8V7.20001V4.8C2.15625 3.50213 3.20838 2.45001 4.50625 2.45001H5.35625V1.60001C5.35625 1.18579 5.69204 0.850006 6.10625 0.850006ZM10.1563 3.95001V4.80001C10.1563 5.21422 10.492 5.55001 10.9063 5.55001C11.3205 5.55001 11.6563 5.21422 11.6563 4.80001V3.95001H12.5063C12.9757 3.95001 13.3562 4.33056 13.3562 4.80001V6.45001H3.65625V4.8C3.65625 4.33056 4.03681 3.95001 4.50625 3.95001H5.35625V4.80001C5.35625 5.21422 5.69204 5.55001 6.10625 5.55001C6.52046 5.55001 6.85625 5.21422 6.85625 4.80001V3.95001H10.1563ZM3.65625 7.95001H13.3562V12.8C13.3562 13.2694 12.9757 13.65 12.5062 13.65H4.50625C4.03681 13.65 3.65625 13.2694 3.65625 12.8V7.95001Z"
        fill="#9CA3AF"
      />
    </StyledOpenDatePickerIcon>
  );
};

const OpenTimePickerIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00413 5.15553V7.99998L10.1375 10.1333M14.4041 7.99998C14.4041 11.5346 11.5387 14.4 8.00413 14.4C4.4695 14.4 1.60413 11.5346 1.60413 7.99998C1.60413 4.46535 4.4695 1.59998 8.00413 1.59998C11.5387 1.59998 14.4041 4.46535 14.4041 7.99998Z"
        stroke="#9CA3AF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const StartDateContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
`;

const TimePickerContainer = styled.div`
  background-color: #fff;
  position: relative;
  width: 185px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #c3c3c36e;
  padding-left: 12px;
`;

const ActionBarContainer = styled.div`
  width: 170px;
  padding: 16px 22px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  background-color: white;
  bottom: -52px;
  left: -1px;
  border-radius: 0px 0px 4px 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
`;
interface ButtonProps {
  active: boolean;
}
const ClearButton = styled.p<ButtonProps>`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: justified;
  margin: 0;
  cursor: pointer;
  color: ${(props) => (props.active ? Active_Text : Disabled_Text)};
`;

export const OKButton = styled.p<ButtonProps>`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: left;
  margin: 0;
  cursor: pointer;
  color: ${(props) => (props.active ? Active_Text : Disabled_Text)};
`;

interface InvalidPopOverContentProps {
  text: string;
  onClosePopOver: () => void;
}

export const InvalidPopOverContent: React.FC<InvalidPopOverContentProps> = ({text, onClosePopOver}) => {
  return (
    <Container>
      <Body>
        <FontAwesomeIcon icon={solid("circle-info")} color={"#FF0000"} size={"lg"} />
        <Text>{text}</Text>
      </Body>

      <Action>
        <CloseButton onClick={onClosePopOver}>Okay</CloseButton>
      </Action>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
`;
const Body = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;
const Text = styled.div``;
const Action = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
const CloseButton = styled(Button)`
  .bp4-button-text {
    color: #ffffff;
  }
  background: linear-gradient(rgb(33, 74, 137) 0%, rgb(20, 48, 90) 100%);
`;

const StyledDatePicker = styled(DatePicker)`
  .MuiInputBase-root {
    height: 32px;
    width: 100%;
    margin: 0;

    * {
      padding: 0 !important;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .MuiSvgIcon-root {
    display: none !important;
  }

  .MuiPickersPopper-root {
    position: relative;
  }

  .MuiDialogActions-root {
    display: none !important;
    height: 0;
    padding: 0;
  }

  [aria-label="Select hours"] li {
    color: #394048;
    font-weight: bold;
  }

  [aria-selected="true"] {
    background: rgba(0, 56, 255, 0.2) !important;
    color: #394048 !important;
  }

  //.Mui-selected:hover {
  //  background-color: red !important;
  //}

  .MuiMenuItem-root:hover {
    background: rgba(0, 56, 255, 0.1) !important;
  }

  .MuiList-root.MuiList-padding.MuiMultiSectionDigitalClock-root {
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d9d9d9;
      border-radius: 5px;
    }
  }
  .MuiPickersLayout-root {
    border-radius: 0;
  }
`;

const StyledOpenDatePickerIcon = styled.svg`
  position: absolute;
  cursor: pointer;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;
