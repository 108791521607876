import styled from "@emotion/styled";
import {Button} from "@blueprintjs/core";

const Container = styled.div`
  position: relative;
  font-family: "Roboto", sans-serif;
  color: #161616;
  font-size: 14px;
  width: fit-content;
  height: fit-content;
  background-color: white;
  box-shadow: 0px 2px 6px 0px #0000001a;
  background-color: #f8f8f8;

  .close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 15px !important;
    height: 15px !important;
    padding: 0;
    cursor: pointer;
    z-index: 2000;
  }
`;

type ManifestModalDialogProps = {
  id: string;
  onClose: () => void;
  children: Element | JSX.Element | null;
};
export const ManifestModalDialog = ({id, onClose, children}: ManifestModalDialogProps) => {
  return (
    <Container data-testid={`${id}-modal`}>
      <Button data-testid={`${id}-close-button`} className="close-button" minimal rightIcon="cross" onClick={onClose} />
      {children}
    </Container>
  );
};
