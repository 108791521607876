import "../../App.css";
import GoogleMapReact from "google-map-react";
import {Dispatch, MutableRefObject, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import StopMarker from "./stop/StopMarker";
import {
  DriverLocation,
  InputMaybe,
  Job,
  JobFilter,
  Manifest,
  ManifestFilter,
  NestedJobStop,
  useMap_SearchJobsQuery,
  useMap_SearchManifestsQuery,
  useSearchDriverLocationQuery
} from "../../generated/graphql";
import {Constants, UserPreferences} from "../common/Constants";

import {JobAssignmentActions, JobAssignmentAsyncActions, JobAssignmentState} from "../common/JobAssignmentReducer";
import {ManifestDataContext, ManifestDataState} from "../manifest/ManifestDataProvider";
import {Cords, createMultiPolyline, createSinglePolyline, getMapBounds, getRoutePaths, MapPath} from "./MappingService";
import {
  EntityVisibilityLevel,
  getRouted,
  getRoutedEntities,
  ManifestVisibilityState,
  MapVisibilityState,
  UnassignedJobsVisibilityTypes,
  useMapVisibilityContext
} from "./MapVisibilityContext";
import _ from "lodash";
import {deliveryStop, isCompleted, pickupStop} from "../../services/JobStopService";
import {StopInfoMetadata} from "./stop/StopInfo";
import {getArrayFromField} from "../../utils/General";
import {useGeoCodeService} from "../hooks/useGeoCodeService";
import MapActionBar, {MapActionBreakPoint} from "./action/MapActionBar";
import {JobAssignmentViewState} from "../../views/JobAssignmentViewReducer";
import MapReframe, {paddingBounds, reZoom} from "./action/MapReframe";
import DriverMarkerV2 from "./driver/DriverMarkerV2";
import useMapRoutes, {RouteInfo} from "./useMapRoutes";
import decodePolyLine from "./DecodePolyLine";
import {jobMapIconColors} from "./LocationMarker";
import {AgGridReact} from "@ag-grid-community/react";
import {AgJob} from "../job/JobPanel";
import {extractJsonPref, PreferenceContext} from "../../providers/PreferenceProvider";
import {DEFAULT_CARD_CONFIGURATIONS_VALUE} from "../manifest/card-configuration/ManifestCardConfigurationDialog";
import {ManifestStopEnhanced} from "../manifest/details/ManifestDetailsV2";
import {DriverCords} from "./driver/DriverMarker";
import {GeoCodeService} from "../../services/GeoCodeService";
import {Address} from "../../domain/geocode-types";
import {TManifestDetailsDrawerOpen} from "../../views/UnassignedJobsView";

export const DEFAULT_ZOOM = 5;

type AssignmentMapViewProps = {
  assignmentState: JobAssignmentState;
  assignmentDispatch: Dispatch<JobAssignmentActions | JobAssignmentAsyncActions>;
  assignmentViewState?: JobAssignmentViewState;
  mapResponsiveWidth: number;
  onManifestDetailsDrawerOpen: TManifestDetailsDrawerOpen;
  gridRef?: MutableRefObject<AgGridReact<AgJob> | undefined>;
  newWindow?: Window;
  usePortal?: boolean;
};

export type MappedStop = {
  stopInfoMetaData: StopInfoMetadata;
} & (NestedJobStop | ManifestStopEnhanced);

export const getDriverCords = (driverId: number, driverLocations: Map<number, DriverLocation>) => {
  if (driverLocations.size > 0) {
    const location = driverLocations.get(driverId);
    if (location) {
      const split = location.coordinates.split(",");
      return {
        ...location,
        lat: parseFloat(split[0]),
        lng: parseFloat(split[1])
      } as DriverCords;
    }
  }
  return undefined;
};

const addressLocations: Record<string /* formatted address */, string /* lat,lng */> = {};
/**
 * gets a stop's lat/lng in string: "lat,lng"
 * @param stop
 * @param geoCodeService
 */
export const getStopLatLng = async (stop: MappedStop, geoCodeService: GeoCodeService): Promise<string | undefined> => {
  const formattedAddress = `${stop.address ?? "missing address"}, ${stop.city ?? "missing city"}, ${
    stop.state ?? "missing state"
  } ${stop.zip ?? "missing zip"}`;
  if (!stop.address || !stop.city || !stop.state || !stop.zip) {
    console.error("bad address: " + formattedAddress);
    return undefined;
  }
  if (addressLocations[formattedAddress]) return addressLocations[formattedAddress];
  const resolvedAddresses = await geoCodeService.geoCodeAddresses([
    new Address(stop.address, stop.city, stop.state, stop.zip)
  ]);
  const [resolvedAddress] = Array.from(resolvedAddresses?.values() ?? []);
  if (!resolvedAddress) {
    console.error(`getGeoCodeService() failure for ${formattedAddress}`);
    return undefined;
  }
  addressLocations[formattedAddress] = `${resolvedAddress.lat},${resolvedAddress.lng}`;
  return addressLocations[formattedAddress];
};

export const getManifestV2 = (manifestDriverId: number, manifestsById: Manifest[]) => {
  if (manifestsById.length > 0) {
    const manifestFoundV2 = manifestsById.find((manifest) => manifest.manifestDriverId === manifestDriverId);
    if (!manifestFoundV2) {
      console.debug("Manifest not found, cannot add to map.", manifestDriverId);
    }
    return manifestFoundV2;
  } else {
    console.debug("manifestsById is empty");
  }
};

export const getJobV2 = (jobId: number, jobsById: Job[]) => {
  if (jobsById.length > 0) {
    //make sure we have a catalog of visible jobs
    const jobFoundV2 = jobsById.find((job) => job.jobId === jobId);
    if (!jobFoundV2) {
      console.debug("Job not found, cannot add to map.", jobId);
    }
    return jobFoundV2;
  } else {
    console.debug("jobsById is empty");
  }
};

export const getStopCords = (
  latLngStops: Record<string, MappedStop[]>,
  __typename: "NestedJobStop" | "ManifestStop" | undefined
) => {
  if (Object.keys(latLngStops).length > 0) {
    return Object.keys(latLngStops)
      .map((key) => {
        const isStop = latLngStops[key].some((job) => job.__typename === __typename);
        if (!isStop) return undefined;
        const [lat, lng] = key.split(",");
        return {lat: Number(lat), lng: Number(lng)};
      })
      .filter((x) => x) as Cords[];
  } else return [];
};

export const getRouterCords = (routeInfo: RouteInfo[] | undefined) => {
  if (routeInfo && routeInfo.length > 0) {
    return [
      ...routeInfo.flatMap((info) =>
        info.points.map((point) => {
          return {lat: point[0], lng: point[1]};
        })
      )
    ];
  } else return [];
};

const getVisibleJobStops = (mapVisibilityState: MapVisibilityState, jobsById: Job[]) => {
  return Array.from(mapVisibilityState.jobs.values()).flatMap(({visibilityLevel, entityId, routeColor}) => {
    const jobV2 = getJobV2(entityId, jobsById);
    if (!jobV2) {
      return [];
    }
    const stops = [...jobV2.stops].sort((a, b) => a.sequence - b.sequence);
    if (visibilityLevel > 0) {
      const key = `Job_${entityId}`;
      const customer = jobV2.order.customer!.name!;
      const jobNumber = jobV2.jobNumber;
      const service = jobV2.service!;
      switch (visibilityLevel) {
        case EntityVisibilityLevel.ROUTE:
          return stops.map((stop, i): MappedStop => {
            const stopInfoMetaData: StopInfoMetadata = {
              visibilityLevel,
              key,
              customer,
              jobNumber,
              service,
              routeColor,
              isFirstStop: i === 0
            };
            return {...stop, stopInfoMetaData: stopInfoMetaData};
          });
        case EntityVisibilityLevel.MARKER:
          return [
            {
              ...([UnassignedJobsVisibilityTypes.PICK_UP, UnassignedJobsVisibilityTypes.NONE].includes(
                mapVisibilityState.unassignedJobVisibilityType
              )
                ? (pickupStop(stops) as NestedJobStop)
                : (deliveryStop(stops) as NestedJobStop)),
              stopInfoMetaData: {
                visibilityLevel,
                key,
                customer,
                jobNumber,
                service,
                isFirstStop: true
              }
            }
          ];
        default:
          return [];
      }
    } else {
      return [];
    }
  });
};

const getVisibleManifestStops = (
  routedVisibleManifests: ManifestVisibilityState[],
  manifestsById: Manifest[],
  isActiveCompletedStops: boolean
) => {
  return routedVisibleManifests.length === 0
    ? []
    : routedVisibleManifests.flatMap(({entityId, visibilityLevel, routeColor}) => {
        const manifestV2 = getManifestV2(entityId, manifestsById);
        if (!manifestV2) {
          return [];
        }
        if (!manifestV2.stops) return [];
        return manifestV2.stops.flatMap((stop, i): MappedStop[] => {
          if (!isActiveCompletedStops && isCompleted(stop)) return [];
          return [
            {
              ...stop,
              stopInfoMetaData: {
                key: `Manifest_${entityId}`,
                customer: stop.order.customer.name!,
                jobNumber: stop.job.jobNumber,
                service: stop.order.service!,
                isFirstStop: i === 0,
                visibilityLevel,
                routeColor
              }
            }
          ];
        });
      });
};

export const defaultCenter = {lat: 37.0902, lng: -95.7129};

const AssignmentMapV2 = ({
  assignmentState,
  assignmentDispatch,
  assignmentViewState,
  mapResponsiveWidth,
  onManifestDetailsDrawerOpen,
  gridRef,
  newWindow,
  usePortal
}: AssignmentMapViewProps) => {
  const [initialCenter, setInitialCenter] = useState<boolean>(false);
  const [layers, setLayers] = useState<string[]>([]);
  const [mapType, setMapType] = useState<string>("roadmap");
  const [apiLoaded, setApiLoaded] = useState<boolean>(false);
  const [map, setMap] = useState<any>();
  const [maps, setMaps] = useState<any>();
  const [latLngStops, setLatLngStops] = useState<Record<string /* lat,lng */, MappedStop[]>>({});
  const [driverLocations, setDriverLocations] = useState<Map<number, DriverLocation>>(new Map());
  const [routePathLines, setRoutePathLines] = useState<MapPath[]>([]);
  const [driverIds, setDriverIds] = useState<number[]>([]);
  const [jobsById, setJobsById] = useState<Job[]>([]);
  const [manifestsById, setManifestsById] = useState<Manifest[]>([]);
  const [isShowVehicles, setIsShowVehicles] = useState<boolean>(true);
  const [isActiveCompletedStops, setIsActiveCompletedStops] = useState(false);
  const [showStopDetails, setShowStopDetails] = useState<boolean>(true);
  const [routeInfo, setRoutes] = useMapRoutes();
  const geoCodeService = useGeoCodeService();
  const manifestDataStateV2 = useContext<ManifestDataState>(ManifestDataContext);
  const {state: mapVisibilityState, dispatch: mapVisibilityDispatch} = useMapVisibilityContext();
  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const {userPreferences} = useContext(PreferenceContext);

  const cardConfigurationState = extractJsonPref(
    userPreferences,
    UserPreferences.manifestCardConfigurations,
    DEFAULT_CARD_CONFIGURATIONS_VALUE
  ).value;

  const isShowCode = useMemo(() => !cardConfigurationState.driverName, [cardConfigurationState.driverName]);

  // Queries
  const buildSearchJobsQueryFilterV2 = useCallback(() => {
    const visibleJobsV2 = Array.from(mapVisibilityState.jobs.values()).filter(
      (e) => e.visibilityLevel !== EntityVisibilityLevel.NONE
    );

    if (visibleJobsV2.length > 0) {
      return {
        or: visibleJobsV2.map((job) => {
          return {jobId: {eq: job.entityId}};
        })
      };
    } else {
      return {
        //For now, just force an empty result when querying.
        or: [{jobId: {eq: -1}}]
      };
    }
  }, [mapVisibilityState.jobs]);

  const buildSearchManifestsQueryFilterV2 = useCallback(() => {
    const visibleManifestsV2 = Array.from(mapVisibilityState.manifests.values()).filter(
      (e) => e.visibilityLevel !== EntityVisibilityLevel.NONE
    );

    if (visibleManifestsV2.length > 0) {
      return {
        or: visibleManifestsV2.map((manifest) => {
          return {manifestDriverId: {eq: manifest.entityId}};
        })
      };
    } else {
      return {
        //For now, just force an empty result when querying.
        or: [{manifestDriverId: {eq: -1}}]
      };
    }
  }, [mapVisibilityState.manifests]);

  const driverLocationResultV2 = useSearchDriverLocationQuery({
    pollInterval: 15000,
    variables: {
      filter: {
        driverIds: driverIds
      },
      limit: Constants.DRIVER_LOCATION_LIMIT
    }
  });

  const searchJobsByIdV2 = useMap_SearchJobsQuery({
    variables: {
      filter: {
        job: buildSearchJobsQueryFilterV2()
      } as InputMaybe<JobFilter>
    },
    onCompleted(data) {
      setJobsById(getArrayFromField(data.searchJobs?.items) as Job[]);
    }
  });
  const searchManifestsByIdV2 = useMap_SearchManifestsQuery({
    variables: {
      filter: {
        manifest: buildSearchManifestsQueryFilterV2()
      } as InputMaybe<ManifestFilter>
    },
    onCompleted(data) {
      setManifestsById(getArrayFromField(data.searchManifests?.items) as Manifest[]);
    }
  });

  // Functions
  const apiIsLoaded = useCallback((maps: {map: any; maps: any; ref: Element}) => {
    setMap(maps.map);
    setMaps(maps.maps);
    setApiLoaded(true);
  }, []);
  const onMapUserGesture = useCallback(() => {
    mapVisibilityState.isViewGestured = true;
  }, [mapVisibilityState]);

  const handleOnChangeMapType = (type: string) => {
    map?.setMapTypeId(type);
    setMapType(type);
  };

  const handleTrafficClicked = () => {
    const updated: string[] = [...layers];
    const trafficLayerIndex = updated.indexOf("TrafficLayer");
    if (trafficLayerIndex !== -1) {
      updated.splice(trafficLayerIndex, 1);
    } else {
      updated.push("TrafficLayer");
    }
    setLayers(updated);
  };

  const handleCompletedStopsClicked = () => {
    setIsActiveCompletedStops(!isActiveCompletedStops);
  };

  const onShowVehiclesToogle = (type?: boolean) => {
    if (type !== undefined) {
      setIsShowVehicles(type);
      return;
    }
    setIsShowVehicles(!isShowVehicles);
  };

  const handleDriverSingleClick = (e: React.MouseEvent<HTMLElement | SVGSVGElement>, manifest: Manifest) => {
    e.preventDefault();
    e.stopPropagation();
    assignmentDispatch({type: "SetSelectedManifest", payload: manifest});
  };

  const handleDriverDoubleClick = (
    e: React.MouseEvent<HTMLElement | SVGSVGElement>,
    manifest: Manifest,
    isSelected: boolean
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isSelected) assignmentDispatch({type: "SetSelectedManifest", payload: manifest});

    // wait for selected manifest scroll into view port
    // and open manifest detail modal
    // scroll not work because modal open will unmount useEffect on ManifestDriverCard
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      onManifestDetailsDrawerOpen(manifest.manifestDriverId, manifest.driver.driverId);
    }, 1000);
  };

  const renderManifestDriversV2 = ({isShowVehicles}: {isShowVehicles: boolean}) => {
    if (mapVisibilityState.manifests && mapVisibilityState.manifests.size > 0) {
      return Array.from(mapVisibilityState.manifests.values())
        .filter((x) => x.visibilityLevel !== EntityVisibilityLevel.NONE)
        .map((x) => renderManifestDriverV2({isShowVehicles, visibilityState: x}));
    }
  };

  const renderManifestDriverV2 = ({
    isShowVehicles,
    visibilityState
  }: {
    isShowVehicles: boolean;
    visibilityState: ManifestVisibilityState;
  }) => {
    const manifest = getManifestV2(visibilityState.entityId, manifestsById);
    if (manifest) {
      const cords = getDriverCords(manifest.driver.driverId, driverLocations);
      return cords ? (
        <DriverMarkerV2
          manifest={manifest}
          key={`manifest-driver-marker-${visibilityState.entityId}`}
          isSelected={assignmentState.selectedManifest?.manifestDriverId === visibilityState.entityId}
          color={visibilityState.routeColor}
          handleDriverSingleClick={handleDriverSingleClick}
          handleDriverDoubleClick={handleDriverDoubleClick}
          cords={cords}
          lat={cords.lat}
          lng={cords.lng}
          isShowVehicles={isShowVehicles}
          isShowCode={isShowCode}
          usePortal={usePortal}
          newWindow={newWindow}
        />
      ) : null;
    }
    return null;
  };

  const jobCords = useMemo(() => {
    return getStopCords(latLngStops, "NestedJobStop");
  }, [latLngStops]);

  const stopCords = useMemo(() => {
    return getStopCords(latLngStops, "ManifestStop");
  }, [latLngStops]);

  const routeCords = useMemo(() => {
    return getRouterCords(routeInfo);
  }, [routeInfo]);

  const driverCords = useMemo(() => {
    if (mapVisibilityState.manifests && mapVisibilityState.manifests.size > 0) {
      return Array.from(mapVisibilityState.manifests.values())
        .filter((x) => x.visibilityLevel !== EntityVisibilityLevel.NONE)
        .map((visibilityState) => {
          const manifest = getManifestV2(visibilityState.entityId, manifestsById);
          if (manifest) {
            return getDriverCords(manifest.driver.driverId, driverLocations);
          } else return null;
        })
        .filter((x) => x) as Cords[];
    } else return [];
  }, [driverLocations, manifestsById, mapVisibilityState.manifests]);

  useEffect(() => {
    if (!driverLocationResultV2.loading) {
      if (driverLocationResultV2.error) {
        console.error("Driver location query resulted in error: ", driverLocationResultV2.error);
      } else {
        const data = driverLocationResultV2.data?.searchDriverLocations?.items as DriverLocation[];
        if (data) {
          const newDriverLocations = new Map<number, DriverLocation>();
          for (const driverLocation of data) {
            newDriverLocations.set(driverLocation.driverId, driverLocation);
          }
          if (!_.isEqual(driverLocations, newDriverLocations)) {
            setDriverLocations(newDriverLocations);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDriverLocations, driverLocationResultV2.loading, driverLocationResultV2.data, driverLocationResultV2.error]);

  useEffect(() => {
    if (mapVisibilityState.jobs) searchJobsByIdV2.refetch();
  }, [mapVisibilityState.jobs, searchJobsByIdV2]);

  useEffect(() => {
    if (mapVisibilityState.manifests) searchManifestsByIdV2.refetch();
  }, [mapVisibilityState.manifests, searchManifestsByIdV2]);

  useEffect(() => {
    /*
     * Convert visible jobs and manifests into a KV map. Keys is lat/lng, values
     * are an array of stopped at key's location
     */
    let stillMounted = true;
    (async () => {
      const visibleJobStops: MappedStop[] = getVisibleJobStops(mapVisibilityState, jobsById);
      const routedVisibleManifests = getRoutedEntities(mapVisibilityState.manifests);
      const visibleManifestStops: MappedStop[] = getVisibleManifestStops(
        routedVisibleManifests,
        manifestsById,
        isActiveCompletedStops
      );

      const locationsV2 = await Promise.all(
        [...visibleJobStops, ...visibleManifestStops].map((stop) =>
          getStopLatLng(stop, geoCodeService).then((latLngPair) => {
            return {latLngPair, stop};
          })
        )
      );
      const latLngStopsV2: Record<string, MappedStop[]> = {};
      for (const {latLngPair, stop} of locationsV2) {
        /* skip addresses that fail to produce a lat/lng */
        if (latLngPair) {
          if (!latLngStopsV2[latLngPair]) latLngStopsV2[latLngPair] = [];
          latLngStopsV2[latLngPair].push(stop);
        }
      }
      if (stillMounted) {
        setLatLngStops(latLngStopsV2);
      }
    })();
    return () => {
      stillMounted = false;
    };
  }, [geoCodeService, isActiveCompletedStops, jobsById, manifestsById, mapVisibilityState]);

  // Set center on initial load
  useEffect(() => {
    console.debug(`Fit bounds, user gesture: ${mapVisibilityState.isViewGestured}`);
    if (
      !initialCenter &&
      apiLoaded &&
      !manifestDataStateV2?.loading &&
      Object.keys(driverLocations).length > 0 &&
      !mapVisibilityState.isViewGestured
    ) {
      if (manifestDataStateV2.data && manifestDataStateV2.data?.length > 0) {
        const cordArr = manifestDataStateV2.data
          .map((x) => getDriverCords(x.driver.driverId, driverLocations))
          .filter((x) => x !== undefined) as Cords[];
        if (cordArr && cordArr.length > 0) {
          const bounds = getMapBounds(maps, cordArr);
          map.fitBounds(bounds);
          setInitialCenter(true);
        }
      }
    }
  }, [
    manifestDataStateV2?.loading,
    manifestDataStateV2.data,
    driverLocations,
    initialCenter,
    apiLoaded,
    map,
    maps,
    mapVisibilityState.isViewGestured
  ]);

  useEffect(() => {
    // set driver id from manifest data

    if (!manifestDataStateV2.loading && manifestDataStateV2.data) {
      const newDriverIds = _.uniq(manifestDataStateV2.data.map((m) => m.driver.driverId));
      if (!_.isEqual(newDriverIds, driverIds)) {
        setDriverIds(newDriverIds);
      }
      // set visible manifests to those that have a driver location
      const manifestsWithLocation = manifestDataStateV2.data.filter((x) => driverLocations.has(x.driver.driverId));
      mapVisibilityDispatch({
        type: "SetManifestsWithLocation",
        manifests: manifestsWithLocation
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manifestDataStateV2.loading, manifestDataStateV2.data, driverLocations]);

  // Set center & clear route on new manifest or job selection
  useEffect(() => {
    console.debug(`Fit bounds, user gesture: ${mapVisibilityState.isViewGestured}`);
    const fitBoundsAndZoom = (markerArr: {lat: number; lng: number}[]) => {
      if (apiLoaded && !mapVisibilityState.isViewGestured) {
        const bounds = getMapBounds(maps, markerArr);
        map.fitBounds(bounds, paddingBounds);
        reZoom(map);
      }
    };
    if ((mapVisibilityState.jobs || mapVisibilityState.manifests) && routeInfo && routeInfo.length > 0) {
      fitBoundsAndZoom(routeCords);
      // fitBounds and zoom when have 1 stop on map
    } else if (Object.keys(latLngStops).length >= 1 || driverCords.length > 1) {
      const bounds = [...jobCords, ...driverCords, ...stopCords, ...routeCords].filter((cord) => cord);
      fitBoundsAndZoom(bounds);
    }
    // We don't need routePathLines added as a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, maps, apiLoaded, routeInfo, latLngStops, driverCords]);

  //Setting stop gps points for routing path
  useEffect(() => {
    const routedEntitieVisibilities = getRouted(mapVisibilityState);
    if (map && (routedEntitieVisibilities.jobs.length > 0 || routedEntitieVisibilities.manifests.length > 0)) {
      // stop locations array, keyed by unique entity id (manifest or nested job id)
      for (const key of Object.keys(latLngStops)) {
        const mappedStops = latLngStops[key];
        if (mappedStops) {
          for (const mappedStop of mappedStops) {
            const entityId = mappedStop.stopInfoMetaData.key;
            const x = map?.get(entityId) ?? [];
            x.push(mappedStop);
            map.set(entityId, x);
          }
        }
      }
      const paths = getRoutePaths(
        latLngStops,
        routedEntitieVisibilities,
        driverLocations,
        false,
        isActiveCompletedStops
      );
      setRoutes(paths);
    } else {
      setRoutes([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latLngStops, map]);

  const getStopList = (route: RouteInfo) => {
    const manifestId = route.key.split("_")[1];
    const manifest = getManifestV2(parseInt(manifestId), manifestsById);
    const manifestStopIds = manifest?.stops?.map((stop) => stop.jobStopId);
    let driverCords = "";
    if (manifest) {
      const cords = getDriverCords(manifest.driver.driverId, driverLocations);
      driverCords = `${cords?.lat},${cords?.lng}`;
    }
    // should map only latLngStops belong to this route
    const stopList: boolean[] = [];
    route.points.forEach((point) => {
      const key = `${point[0]},${point[1]}`;
      const stop = latLngStops[key]?.find(
        (stop) => stop.stopInfoMetaData.key === route.key && manifestStopIds && manifestStopIds.includes(stop.jobStopId)
      );

      const isCompletedStop = stop?.jobStopStatus === "C" || driverCords === key;
      stopList.push(isCompletedStop);
    });
    return stopList;
  };

  const getPolyline = (route: RouteInfo, stopList: boolean[]) => {
    const points = decodePolyLine(route.paths[0].points?.encodedLine as string);
    if (isActiveCompletedStops) {
      const snapped_waypoints = decodePolyLine(route.paths[0].snappedWaypoints?.encodedLine as string);
      const slicePointsBySnappedWaypoints = [];
      let start = 0;
      for (let i = 0; i < snapped_waypoints.length - 1; i++) {
        const next_snapped_waypoint = snapped_waypoints[i + 1];
        const findWaypoint = points.findIndex(
          (p, i) => i >= start && p.lat === next_snapped_waypoint.lat && p.lng === next_snapped_waypoint.lng
        );
        const sliced_points = points.slice(start, findWaypoint + 1);
        start = findWaypoint;
        slicePointsBySnappedWaypoints.push(sliced_points);
      }

      return createMultiPolyline(route, map, slicePointsBySnappedWaypoints, stopList);
    } else {
      return createSinglePolyline(route, map);
    }
  };

  useEffect(() => {
    routePathLines.forEach((x) => {
      if (Array.isArray(x.polyline)) {
        x.polyline.forEach((polyline) => {
          polyline.setMap(null);
        });
      } else {
        x.polyline.setMap(null);
      }
    });
    if (routeInfo && routeInfo.length > 0) {
      const mapPathArr: MapPath[] = [];
      routeInfo.forEach((route) => {
        // avoid draw polyline when stop have 1 point
        if (route.points?.length > 1) {
          const stopList = getStopList(route);
          const polyline: google.maps.Polyline[] | google.maps.Polyline = getPolyline(route, stopList);
          mapPathArr.push({
            route,
            polyline
          });
        }
      });
      setRoutePathLines(mapPathArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, routeInfo]);

  //variables
  const visibleManifests = useMemo(
    () => Array.from(mapVisibilityState.manifests.values()).filter((e) => e.isRouted()),
    [mapVisibilityState]
  );

  const visibleJobs = useMemo(
    () => Array.from(mapVisibilityState.jobs.values()).filter((e) => e.isVisible()),
    [mapVisibilityState]
  );

  // reset isActiveCompletedStops when don't have visibleManifests
  useEffect(() => {
    if (visibleManifests.length === 0) {
      setIsActiveCompletedStops(false);
      setShowStopDetails(true);
    }
  }, [visibleManifests]);

  const onZoom = useCallback(() => {
    mapVisibilityState.isViewGestured = true;
  }, [mapVisibilityState]);

  return (
    <div
      className={`${map ? "google-map" : ""}`}
      data-testid={"assignment-map"}
      style={{height: "100%"}}
      onWheel={onMapUserGesture}
    >
      {mapResponsiveWidth >= MapActionBreakPoint.HIDE_MENU && (
        <MapActionBar
          apiLoaded={apiLoaded}
          newWindow={newWindow}
          usePortal={usePortal}
          mapType={mapType}
          onChangeMapType={handleOnChangeMapType}
          jobIds={assignmentViewState?.showingJobIds ?? []}
          totalJobCount={assignmentViewState?.totalJobIds ?? 0}
          visibleManifests={visibleManifests}
          totalDriverCount={
            manifestDataStateV2.data?.filter((manifest) => driverLocations.get(manifest.driver.driverId)).length ?? 0
          }
          visibleDriverCount={
            [...mapVisibilityState.manifests].filter(
              ([, v]: [number, ManifestVisibilityState]) => v.isVisible() && v.hasLocationData
            ).length
          }
          visibleJobs={visibleJobs}
          isShowVehicles={isShowVehicles}
          mapResponsiveWidth={mapResponsiveWidth}
          mapVisibilityDispatch={mapVisibilityDispatch}
          onTrafficClicked={handleTrafficClicked}
          onShowVehiclesToogle={onShowVehiclesToogle}
          mapVisibilityState={mapVisibilityState}
          isActiveCompletedStops={isActiveCompletedStops}
          onCompletedStopsClicked={handleCompletedStopsClicked}
          visibleStopDetails={showStopDetails}
          onStopDetailsClick={setShowStopDetails}
          isJobPanelLoaded={mapVisibilityState.isJobPanelLoaded}
        />
      )}
      <MapReframe
        newWindow={newWindow}
        usePortal={usePortal}
        map={map}
        maps={maps}
        driverCords={driverCords}
        jobCords={jobCords}
        stopCords={stopCords}
        routeCords={routeCords}
        onZoomIn={onZoom}
        onZoomOut={onZoom}
      />
      <GoogleMapReact
        bootstrapURLKeys={{key: Constants.GOOGLE_API_KEY}}
        defaultCenter={defaultCenter}
        defaultZoom={DEFAULT_ZOOM}
        yesIWantToUseGoogleMapApiInternals
        layerTypes={layers}
        options={{
          disableDefaultUI: true,
          zoomControl: false,
          clickableIcons: true,
          rotateControl: false,
          fullscreenControl: false
        }}
        shouldUnregisterMapOnUnmount={false}
        onGoogleApiLoaded={apiIsLoaded}
        onDragEnd={onMapUserGesture}
        onChildClick={onMapUserGesture}
      >
        {renderManifestDriversV2({isShowVehicles: isShowVehicles})}
        {Object.keys(latLngStops).length !== 0 &&
          Object.keys(latLngStops).map((key) => {
            const mappedLocationArr = latLngStops[key];
            const [lat, lng] = key.split(",");
            return (
              <StopMarker
                usePortal={usePortal}
                newWindow={newWindow}
                key={key}
                lat={Number(lat)}
                lng={Number(lng)}
                mappedStops={mappedLocationArr}
                minimal={!showStopDetails}
                assignmentViewState={assignmentViewState}
                gridRef={gridRef}
                alwaysShowJobNumber={getRouted(mapVisibilityState).jobs.length > jobMapIconColors.length}
              />
            );
          })}
      </GoogleMapReact>
    </div>
  );
};
export default AssignmentMapV2;
