import {CustomerFilterOperators} from "../components/common/CustomerFilter";

export const createCondition = (
  column: string,
  filter: any,
  columnKey: string,
  customOperatorFields: Map<string | undefined, string>
): any => {
  if (filter.operator) {
    return {
      [filter.operator.toLowerCase()]: [
        createCondition(column, filter.condition1, columnKey, customOperatorFields),
        createCondition(column, filter.condition2, columnKey, customOperatorFields)
      ]
    };
  }
  if (filter.filterType === "set") {
    if (filter.values.length === 0) {
      return {[column]: {match: ""}};
    }
    return {
      or: filter.values.map((x: string) => {
        return {[column]: {matchPhrase: x}};
      })
    };
  }
  if (filter.filterType === "setNumber") {
    if (filter.values.length === 0) {
      return {
        [column]: {eq: -1}
      };
    }
    if (filter?.customerFilterOperator === CustomerFilterOperators.NotEqual) {
      return {
        and: filter.values.map((x: number) => {
          return {[column]: {ne: x}};
        })
      };
    }
    return {
      or: filter.values.map((x: number) => {
        return {[column]: {eq: x}};
      })
    };
  }
  const customTextOperator = customOperatorFields.has(columnKey) ? customOperatorFields.get(columnKey)! : "wildcard";

  switch (filter.type) {
    case "equals":
      if (filter.filterType === "number") {
        return {[column]: {eq: filter.filter}};
      }
      return {[column]: {matchPhrase: filter.filter}};
    case "notEqual":
      return {[column]: {ne: filter.filter}};
    case "lessThan":
      return {[column]: {lt: filter.filter}};
    case "lessThanOrEqual":
      return {[column]: {lte: filter.filter}};
    case "greaterThan":
      return {[column]: {gt: filter.filter}};
    case "greaterThanOrEqual":
      return {[column]: {gte: filter.filter}};
    case "inRange":
      return {[column]: {range: [filter.filter, filter.filterTo]}};
    case "contains":
      return {[column]: {[customTextOperator]: `*${filter.filter}*`}};
    case "notContains":
      return {not: {[column]: {[customTextOperator]: `*${filter.filter}*`}}};
    case "startsWith":
      return {[column]: {wildcard: `${filter.filter}*`}};
    case "endsWith":
      return {[column]: {wildcard: `*${filter.filter}`}};
    case "blank":
      return {[column]: {match: filter.filterType === "number" ? null : ""}};
    case "notBlank":
      return {not: {[column]: {match: filter.filterType === "number" ? null : ""}}};
  }
};
