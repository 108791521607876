import {useCallback, useContext} from "react";
import {Manifest, SearchableSortDirection} from "../../generated/graphql";
import {JobAssignment, JobAssignmentViewContext, CardType} from "../../views/JobAssignmentView";
import {DragAndDropTargetPayload, JobViewActionTypes} from "../../views/JobAssignmentViewReducer";
import {useMapVisibilityContext} from "../map/MapVisibilityContext";
import ManifestDriverListV2 from "./list/ManifestDriverListV2";
import DispatchRulesDataProvider from "../common/DispatchRulesDataProvider";
import {JobTypes} from "../settings/ColorizedIndicators/types/jobTypeSelectorValues";
import {TManifestDetailsDrawerOpen} from "../../views/UnassignedJobsView";

type ManifestViewSelectorProps = {
  viewType: CardType;
  selectedManifest?: Manifest;
  disabledManifestIds: number[];
  data: Manifest[];
  isManifestDetailsDrawerOpen: boolean;
  onManifestDetailsDrawerOpen: TManifestDetailsDrawerOpen;
  sortByColor?: {
    enable: boolean;
    direction: SearchableSortDirection;
  };
};

const ManifestViewSelector = ({
  viewType,
  selectedManifest,
  disabledManifestIds,
  data,
  isManifestDetailsDrawerOpen,
  onManifestDetailsDrawerOpen,
  sortByColor
}: ManifestViewSelectorProps) => {
  const jobAssignmentContext = useContext(JobAssignment);
  const jobAssignmentViewContext = useContext(JobAssignmentViewContext);
  const {state: mapVisibilityState, dispatch: mapVisibilityDispatch} = useMapVisibilityContext();

  const handleOnSelectedManifest = useCallback((manifest: Manifest) => {
    jobAssignmentContext?.updateState({type: "SetSelectedManifest", payload: manifest});
    // Adding the dependency here will cause the child card to redraw always
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateDropTargets = useCallback((dragAndDropPayload: DragAndDropTargetPayload[]) => {
    jobAssignmentViewContext?.updateState({
      type: JobViewActionTypes.SET_DRAG_AND_DROP_TARGETS,
      payload: dragAndDropPayload
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnDrillDownManifest = useCallback(
    (manifest: Manifest) => {
      onManifestDetailsDrawerOpen(manifest.manifestDriverId, manifest.driver.driverId);
    },
    [onManifestDetailsDrawerOpen]
  );

  const handleLocationMarkerClicked = useCallback((manifest: Manifest) => {
    mapVisibilityDispatch({type: "IncrementManifestVisibility", manifest: manifest});
    mapVisibilityDispatch({type: "SetIsViewGestured", payload: false});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <DispatchRulesDataProvider jobType={JobTypes.AssignedStops}>
      <ManifestDriverListV2
        isManifestDetailsDrawerOpen={isManifestDetailsDrawerOpen}
        data={data}
        cardType={viewType}
        selectedManifest={selectedManifest}
        disabledManifestIds={disabledManifestIds}
        onSelectedManifest={handleOnSelectedManifest}
        onDrillDownManifest={handleOnDrillDownManifest}
        handleUpdateDropTargets={handleUpdateDropTargets}
        pendingActions={jobAssignmentContext?.pendingActions}
        visibleManifests={mapVisibilityState.manifests}
        onLocationMarkerClick={handleLocationMarkerClicked}
        sortByColor={sortByColor}
      />
    </DispatchRulesDataProvider>
  );
};

export default ManifestViewSelector;
