import {Manifest, ManifestStop} from "../../../generated/graphql";
import {isCompleted} from "../../../services/JobStopService";
import {getTimezoneOffset} from "date-fns-tz";
import {getIANATimezone} from "../../../utils/DateUtils";
import {addMinutes} from "date-fns";
import {Constants} from "../../common/Constants";
import {getStopTimeForType} from "../../../utils/StopTime";
import {Maybe} from "graphql/jsutils/Maybe";

export const optimizeManifest = (manifest: Manifest) => {
  console.debug("Optimizing %s", manifest.manifestDriverId);
};

export const findNotCompletedStops = (manifestStops: ManifestStop[] | undefined | null) => {
  if (!manifestStops) {
    return [];
  }
  return manifestStops.filter((stop) => !isCompleted(stop));
};

export const findEarliestStopDateV2 = (
  manifestStops: ManifestStop[] | undefined | null,
  options: {timezoneOfStartLocation?: string}
): Date | null => {
  const notCompletedStops = findNotCompletedStops(manifestStops);

  if (notCompletedStops.length === 0) return null;

  const equalCurrentDateStops = notCompletedStops
    .filter((stop) => isSameDayMonthYearWithCurrentDate(getStopTimeForType(stop, stop.stopType)))
    .map((stop) => ({
      stopDateTime: getStopTimeForType(stop, stop.stopType)!,
      timeZone: stop.timeZone!
    }))
    .sort((a, b) => new Date(a.stopDateTime).getTime() - new Date(b.stopDateTime).getTime());

  if (equalCurrentDateStops.length === 0) {
    return null;
  }

  const {timezoneOfStartLocation} = options;

  const targetTimeZone = timezoneOfStartLocation || equalCurrentDateStops[0].timeZone;
  return convertTimeZoneByOffSetInCurrentTime(targetTimeZone);
};

export const isSameDayMonthYearWithCurrentDate = (isoString: Maybe<string> | undefined): boolean => {
  if (!isoString) return false;
  const comparedDate = new Date(isoString);
  const currentDate = new Date();

  return (
    comparedDate.getFullYear() === currentDate.getFullYear() &&
    comparedDate.getMonth() === currentDate.getMonth() &&
    comparedDate.getDate() === currentDate.getDate()
  );
};

export const convertTimeZoneByOffSetInCurrentTime = (timeZone: string) => {
  const offsetBetweenTimeZoneAndUTC = getTimezoneOffset(getIANATimezone(timeZone)) / 1000 / 60;
  const offsetBetweenBrowserTimeZoneAndUTC = Constants.BROWSER_TIMEZONE_OFFSET_MINUTES;
  const diffBetweenTimeZoneAndBrowser = offsetBetweenTimeZoneAndUTC - offsetBetweenBrowserTimeZoneAndUTC;
  return addMinutes(new Date(), diffBetweenTimeZoneAndBrowser);
};
export enum GRAPHHOPPER_INVALID_MSG {
  ADDRESS = "Route optimization request contains invalid addresses."
}
