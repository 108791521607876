import {Intent, Position, Toaster} from "@blueprintjs/core";
import {Job, Manifest, ManifestStop} from "../generated/graphql";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import styled from "@emotion/styled";
import "./toaster.css";
import {navigateToCmsOrder} from "../services/CmsService";
import React, {Dispatch, useContext, useState} from "react";
import {AuthState} from "../components/AuthProvider";
import {LoadingSpinner} from "../components/common/LoadingSpinner";
import {PreferenceContext} from "../providers/PreferenceProvider";
import {v4 as uuidv4} from "uuid";
import {JobViewActions, JobViewActionTypes} from "../views/JobAssignmentViewReducer";
export const AppToaster = Toaster.create({
  position: Position.TOP,
  maxToasts: 3
});

export const AppToasterTopLeft = Toaster.create({
  position: Position.TOP_LEFT,
  maxToasts: 3,
  className: "top-left-toast"
});

const AssignmentToastContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const AssignmentHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const AssignmentTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
`;
const AssignmentBody = styled.div``;
const AssignmentFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const AssignmentLink = styled.span`
  color: #137cbd;
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
const CloseButton = styled.button`
  padding: 6px 14px;
  background: #fff;
  border-radius: 4px;
  font-size: 14px;
  box-shadow: 0 2px 2px 0 #00000040;
  border: none;
`;
type ReassignmentToastProps = {
  jobStops: ManifestStop[];
  manifest: Manifest;
  authState: AuthState;
};

type AssignmentToastProps = {
  jobs: Job[];
  manifest: Manifest;
  authState: AuthState;
  selectedOrderId?: number;
};
const AssignmentToast = ({jobs, manifest, authState, selectedOrderId}: AssignmentToastProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const {tenantPreferences} = useContext(PreferenceContext);

  const handleJobClicked = (e: React.MouseEvent<HTMLAnchorElement>, job: Job) => {
    e.preventDefault();
    setLoading(true);
    navigateToCmsOrder(
      authState.tenant!,
      authState.token!,
      selectedOrderId ?? job.order.orderId,
      tenantPreferences
    ).finally(() => setLoading(false));
  };

  return (
    <div>
      <LoadingSpinner loading={loading}>
        <AssignmentToastContainer>
          <AssignmentHeader>
            <FontAwesomeIcon size={"xl"} color={"#32A467"} icon={solid("circle-check")} />
            <AssignmentTitle>Assignment Submitted</AssignmentTitle>
          </AssignmentHeader>
          <div>
            Job{jobs.length > 1 ? "s" : ""}{" "}
            <span>
              {jobs.map((x, index) => {
                return (
                  <a key={`link-${x.jobId}`} href={"./"} onClick={(e) => handleJobClicked(e, x)}>
                    {x.jobNumber}
                    {index === jobs.length - 1 ? " " : ", "}
                  </a>
                );
              })}
            </span>{" "}
            submitted to {manifest.driver.name} and assignment is processing. We&apos;ll notify you of any issues.
          </div>
        </AssignmentToastContainer>
      </LoadingSpinner>
    </div>
  );
};

export const showAssignmentToast = (
  jobs: Job[],
  manifest: Manifest,
  authState: AuthState,
  selectedOrderId?: number
) => {
  AppToasterTopLeft.show({
    intent: Intent.SUCCESS,
    message: (
      <AssignmentToast jobs={jobs} manifest={manifest} authState={authState} selectedOrderId={selectedOrderId} />
    ),
    className: "toast",
    timeout: 5000
  });
};

const ReassignmentToastForReassignment = ({jobStops, manifest, authState}: ReassignmentToastProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const {tenantPreferences} = useContext(PreferenceContext);

  const handleJobClicked = (e: React.MouseEvent<HTMLAnchorElement>, orderId: number) => {
    e.preventDefault();
    setLoading(true);
    navigateToCmsOrder(authState.tenant!, authState.token!, orderId, tenantPreferences).finally(() =>
      setLoading(false)
    );
  };
  const uniqueJobNumbers = new Set();

  return (
    <div>
      <LoadingSpinner loading={loading}>
        <AssignmentToastContainer>
          <AssignmentHeader>
            <FontAwesomeIcon size={"xl"} color={"#32A467"} icon={solid("circle-check")} />
            <AssignmentTitle>Reassignment Submitted</AssignmentTitle>
          </AssignmentHeader>
          <div>
            Job{jobStops.length > 1 ? "s" : ""}{" "}
            <span>
              {jobStops.map((x, index) => {
                if (!uniqueJobNumbers.has(x.job.jobNumber)) {
                  uniqueJobNumbers.add(x.job.jobNumber);
                  return (
                    <a key={`link-${x.job.jobId}`} href={"./"} onClick={(e) => handleJobClicked(e, x.order.orderId)}>
                      {x.job.jobNumber}
                      {index === jobStops.length - 1 ? " " : ", "}
                    </a>
                  );
                } else {
                  return null;
                }
              })}
            </span>{" "}
            submitted to {manifest.driver.name} and reassignment is processing. We&apos;ll notify you of any issues.
          </div>
        </AssignmentToastContainer>
      </LoadingSpinner>
    </div>
  );
};
type ReassignmentErrorToastProps = {
  manifest: Manifest | undefined;
  onClose: () => void;
  handleOpenManifestDetailWhenClicked: () => void;
};
export const ReassignmentErrorToast: React.FC<ReassignmentErrorToastProps> = ({
  manifest,
  onClose,
  handleOpenManifestDetailWhenClicked
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigateToManifest = () => {
    setLoading(true);
    handleOpenManifestDetailWhenClicked();
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };
  return (
    <div>
      <LoadingSpinner loading={loading}>
        <AssignmentToastContainer>
          <AssignmentHeader>
            <FontAwesomeIcon size={"xl"} color={"#FA545E"} icon={solid("triangle-exclamation")} />
            <AssignmentTitle>Reassignment Issue</AssignmentTitle>
          </AssignmentHeader>
          <AssignmentBody>
            Some job(s) failed to reassign. Please check{" "}
            <AssignmentLink onClick={navigateToManifest}>{manifest?.driver.name}</AssignmentLink> manifest, and the
            unassigned job grid, to analyze any further action needed.
          </AssignmentBody>
          <AssignmentFooter>
            <CloseButton onClick={onClose}>Close</CloseButton>
          </AssignmentFooter>
        </AssignmentToastContainer>
      </LoadingSpinner>
    </div>
  );
};
export const showAssignmentToastForReassignment = (
  jobStops: ManifestStop[],
  manifest: Manifest,
  authState: AuthState
) => {
  AppToasterTopLeft.show({
    intent: Intent.SUCCESS,
    message: <ReassignmentToastForReassignment jobStops={jobStops} manifest={manifest} authState={authState} />,
    className: "toast",
    timeout: 5000
  });
};

export const showReassignmentErrorToast = (
  manifest: Manifest | undefined,
  assignmentViewStateDispatch: Dispatch<JobViewActions>
) => {
  const toastId = uuidv4();
  const handleToastClose = () => {
    AppToasterTopLeft.dismiss(toastId);
  };
  const handleOpenManifestDetailWhenClicked = () => {
    const manifestDriverId = manifest?.manifestDriverId;
    assignmentViewStateDispatch({
      type: JobViewActionTypes.SET_MANIFEST_DETAILS_DRAWER_OPEN,
      payload: {
        manifestDriverId: manifestDriverId,
        driverId: manifest?.driver.driverId
      }
    });
    setTimeout(() => {
      handleToastClose();
    }, 500);
  };
  AppToasterTopLeft.show(
    {
      intent: Intent.WARNING,
      message: (
        <ReassignmentErrorToast
          manifest={manifest}
          onClose={handleToastClose}
          handleOpenManifestDetailWhenClicked={handleOpenManifestDetailWhenClicked}
        />
      ),
      className: "toast-error",
      timeout: 5000
    },
    toastId
  );
};
