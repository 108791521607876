import {Dispatch, Reducer} from "react";
import {AsyncActionHandlers} from "use-reducer-async";
import {
  AssignJobsToManifestMutationFn,
  JobAssignmentUpdate,
  Job,
  Manifest,
  ReassignJobStopsMutationFn,
  ManifestStop
} from "../../generated/graphql";
import {isTerminalResult, JobAssignmentSubmissionResult} from "../../utils/JobAssignmentEventHandler";
import {
  createAssignmentFromUpdate,
  findAssignmentFromUpdate,
  findAssignmentFromUpdatePredicate,
  PendingAssignment
} from "../../utils/PendingAssignment";
import {showReassignmentErrorToast, showAssignmentToastForReassignment, showAssignmentToast} from "../../utils/toaster";
import {JobViewActions, PollingOptions} from "../../views/JobAssignmentViewReducer";
import {arrayDeleteElsePush} from "../../utils/General";
import {AuthState} from "../AuthProvider";

type JobAssignmentState = {
  selectedJobs: Job[];
  pendingActions: PendingAssignment[];
  selectedManifest: Manifest | undefined;
  originManifest: Manifest | undefined;
  selectedStops: ManifestStop[];
  pollingOptions: PollingOptions;
  highlightManifests: number[] | undefined;
};

type JobAssignmentPayload = {
  assignFunction: AssignJobsToManifestMutationFn;
  authState: AuthState;
  isFromGlobalFind?: boolean;
  selectedManifest?: Manifest;
  selectedJobs?: Job[];
};

type ReassignJobStopsPayload = {
  reassignFunction: ReassignJobStopsMutationFn;
  jobStopIds: number | number[];
  fromManifestDriverId: number;
  authState: AuthState;
  assignmentViewStateDispatch: Dispatch<JobViewActions>;
};

type JobAssignmentActions =
  | {type: "SetSelectedJobs"; payload: Job}
  | {type: "SetSelectedJob"; payload: Job}
  | {type: "SetSelectedStops"; payload: ManifestStop[]}
  | {type: "SetSelectedManifest"; payload: Manifest | undefined}
  | {type: "SetOriginManifest"; payload: Manifest | undefined}
  | {type: "SetDisabledManifestIds"; payload: number[]}
  | {type: "SetHighlightManifest"; payload: number[]}
  | {type: "SetPendingAction"; payload: PendingAssignment}
  | {type: "PruneTerminalPendingActions"}
  | {type: "UpdatePendingActionsStatus"; payload: JobAssignmentUpdate}
  | {type: "ClearSelections"}
  | {type: "ClearAllDetailState"};

type JobAssignmentAsyncActions =
  | {type: "AssignJobsToManifest"; payload: JobAssignmentPayload}
  | {type: "ReassignJobStops"; payload: ReassignJobStopsPayload};

const JobAssignmentReducer = (state: JobAssignmentState, action: JobAssignmentActions): JobAssignmentState => {
  switch (action.type) {
    case "SetSelectedJobs": {
      return {
        ...state,
        selectedJobs: updateSelectedJob(state, action.payload)
      };
    }
    case "SetSelectedJob": {
      return {
        ...state,
        selectedJobs: [action.payload]
      };
    }
    case "SetSelectedStops": {
      return {
        ...state,
        selectedStops: action.payload
      };
    }
    case "SetOriginManifest": {
      return {
        ...state,
        originManifest: action.payload
      };
    }
    case "SetSelectedManifest": {
      const alreadySelected =
        state.selectedManifest && state.selectedManifest.manifestDriverId === action.payload?.manifestDriverId;
      if (alreadySelected) {
        return {
          ...state,
          selectedManifest: undefined
        };
      } else {
        return {
          ...state,
          selectedManifest: action.payload
        };
      }
    }
    case "SetHighlightManifest": {
      return {
        ...state,
        highlightManifests: action.payload
      };
    }
    case "SetPendingAction": {
      return {
        ...state,
        pendingActions: [...state.pendingActions, action.payload]
      };
    }
    case "UpdatePendingActionsStatus": {
      const update = action.payload;
      const updatedActions = [...state.pendingActions];
      const updateStatus =
        JobAssignmentSubmissionResult[update.submissionResult as keyof typeof JobAssignmentSubmissionResult];
      const existing = findAssignmentFromUpdate(state.pendingActions, update);
      if (existing) {
        const foundIndex = updatedActions.findIndex((x) => findAssignmentFromUpdatePredicate(x, update));
        existing.status = updateStatus;
        updatedActions[foundIndex] = existing;
      } else {
        updatedActions.push(createAssignmentFromUpdate(update));
      }

      return {
        ...state,
        pendingActions: updatedActions
      };
    }
    case "ClearSelections": {
      return {
        ...state,
        selectedJobs: [],
        selectedStops: []
      };
    }

    case "ClearAllDetailState": {
      return {
        ...state,
        selectedJobs: [],
        selectedStops: [],
        highlightManifests: [],
        selectedManifest: undefined,
        originManifest: undefined
      };
    }

    case "PruneTerminalPendingActions": {
      const terminal: PendingAssignment[] = [];
      const remaining: PendingAssignment[] = [];
      state.pendingActions.forEach((pa) => {
        if (isTerminalResult(pa.status)) {
          terminal.push(pa);
        } else {
          remaining.push(pa);
        }
      });
      console.debug("Pruning terminal actions", terminal);
      return {
        ...state,
        pendingActions: remaining
      };
    }
    default:
      return state;
  }
};

const asyncActionHandlers: AsyncActionHandlers<
  Reducer<JobAssignmentState, JobAssignmentActions>,
  JobAssignmentAsyncActions
> = {
  AssignJobsToManifest:
    ({dispatch, getState}) =>
    async (action) => {
      let state: JobAssignmentState;
      if (action.payload.isFromGlobalFind) {
        const selectedManifest = action.payload?.selectedManifest;
        const selectedJobs = action.payload?.selectedJobs ?? [];
        state = {
          ...getState(),
          selectedJobs: selectedJobs,
          selectedManifest: selectedManifest
        };
      } else {
        state = getState();
      }
      if (!state.selectedManifest || !state.selectedJobs) return;

      dispatch({
        type: "SetPendingAction",
        payload: buildPendingAction(state, "assign")
      });
      startPolling(state);

      await action.payload.assignFunction({
        variables: {
          manifestDriverId: state.selectedManifest.manifestDriverId,
          jobIds: state.selectedJobs.flatMap((job) => job.jobId)
        },
        onError: (error) => console.error(error)
      });

      showAssignmentToast(state.selectedJobs, state.selectedManifest, action.payload.authState);
      dispatch({type: "ClearSelections"});
    },
  ReassignJobStops:
    ({dispatch, getState}) =>
    async (action) => {
      const state = getState();
      const selectedManifestDriver = state.selectedManifest;

      dispatch({
        type: "SetPendingAction",
        payload: buildPendingAction(state, "reassign")
      });
      startPolling(state);

      await action.payload.reassignFunction({
        variables: {
          manifestDriverId: state.selectedManifest?.manifestDriverId as number,
          fromManifestDriverId: action.payload.fromManifestDriverId,
          jobStopIds: action.payload.jobStopIds
        },
        onError: (error) => {
          console.error(error);
          if (state.selectedStops?.length ?? 0 > 1) {
            showReassignmentErrorToast(state.originManifest, action.payload.assignmentViewStateDispatch);
          }
        }
      });
      showAssignmentToastForReassignment(state.selectedStops!, selectedManifestDriver!, action.payload.authState);
      dispatch({type: "ClearSelections"});
    }
};

export const buildPendingAction = (state: JobAssignmentState, mode: "assign" | "reassign"): PendingAssignment => ({
  jobIds: mode === "assign" ? state.selectedJobs.map((x) => x.jobId) : [],
  jobStopIds: mode === "reassign" ? state.selectedStops.map((x) => x.jobStopId) : [],
  manifestDriverId: state.selectedManifest?.manifestDriverId as number,
  user: "",
  assignmentDateTime: Date.now(),
  status: JobAssignmentSubmissionResult.PENDING
});

const updateSelectedJob = (state: JobAssignmentState, job: Job) => {
  const selection = arrayDeleteElsePush(state.selectedJobs, job, (item) => item.jobId === job.jobId);

  if (selection.length > 0) {
    stopPolling(state);
  } else {
    startPolling(state);
  }

  return selection;
};

const startPolling = (state: JobAssignmentState) => {
  state.pollingOptions.startPolling(state.pollingOptions.pollingInterval);
};

const stopPolling = (state: JobAssignmentState) => {
  state.pollingOptions.stopPolling();
};

export default JobAssignmentReducer;
export {asyncActionHandlers};
export type {JobAssignmentActions, JobAssignmentState, JobAssignmentAsyncActions, PendingAssignment};
