import styled from "@emotion/styled";
import React, {forwardRef, ReactElement, useCallback, useImperativeHandle, useState} from "react";
import {CloseIcon} from "../manifest/details/components/OptimizeDialog";
import {Dialog} from "@blueprintjs/core";
interface ActionButton {
  label: string;
  action: () => void;
}
export interface IActiveFilterDialogProps {
  message: string;
  icon: ReactElement<any, any>;
  title: string;
  actionButton?: ActionButton;
}
export interface IActiveFilterDialogRef {
  onOpen: () => void;
  onClose: () => void;
}
const ActiveFilterDialog = forwardRef<IActiveFilterDialogRef, IActiveFilterDialogProps>((props, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);
  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClickAction = useCallback(() => {
    setIsOpen(false);
    props.actionButton?.action();
  }, [props.actionButton]);

  useImperativeHandle(ref, () => ({
    onClose,
    onOpen
  }));
  if (isOpen)
    return (
      <DialogContainer hasBackdrop={true} backdropClassName="message-backdrop" isOpen={isOpen} onClose={onClose}>
        <DialogHeader>
          <button
            style={{cursor: "pointer", padding: "0", background: "#fff", border: "none", outline: "none"}}
            onClick={onClose}
            role="close-icon"
          >
            <CloseIcon />
          </button>
        </DialogHeader>
        <DialogBody>
          <DialogTitle>
            {props.icon}
            <DialogTitleText role="dialog-header">{props.title}</DialogTitleText>
          </DialogTitle>
          <DialogText role="dialog-message">{props.message}</DialogText>
        </DialogBody>
        <DialogFooter>
          <DialogCloseButton role="close-button" onClick={onClose}>
            Close
          </DialogCloseButton>
          {props.actionButton && (
            <DialogActionButton data-testid="action-button" onClick={handleClickAction}>
              {props.actionButton.label}
            </DialogActionButton>
          )}
        </DialogFooter>
      </DialogContainer>
    );
  else return null;
});

export default ActiveFilterDialog;

ActiveFilterDialog.displayName = "ActiveFilterDialog";

const DialogContainer = styled(Dialog)`
  position: absolute;
  top: 45px;
  left: 25px;
  width: 390px;
  height: fit-content;

  background-color: white;
  border-radius: 4px;
`;
const DialogHeader = styled.div`
  width: 100%;
  padding-right: 10px;
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
const DialogTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
`;
const DialogTitleText = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
`;
const DialogBody = styled.div`
  width: 100%;
  padding: 0 17px 0 29px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
const DialogText = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
  line-height: 16.41px;
`;

const DialogFooter = styled.div`
  width: 100%;
  padding: 0 29px 17px 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
`;
const DialogCloseButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 6px 14px;
  border-radius: 4px;
  box-shadow: 0px 2px 2px 0px #00000040;
`;

const DialogActionButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 6px 14px;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 #00000040;
  color: #ffffff;
  background: linear-gradient(180deg, #214a89 0%, #14305a 100%);
`;
