import {AgGridReact, AgGridReactProps} from "@ag-grid-community/react";
import {Button, Text} from "@blueprintjs/core";
import styled from "@emotion/styled";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ManifestStopEnhanced} from "./ManifestDetailsV2";
import {Tooltip2} from "@blueprintjs/popover2";
import {css} from "@emotion/react";

export const ManifestDriverDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export const ManifestDriverDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 30px);
  margin: 15px;
  flex: 6;
`;

export const ManifestDriverDetailsToolsContainer = styled.div`
  flex: 4;
`;

export const ButtonsHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const GridHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 30px;
  max-height: fit-content;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 7px 10px;
  padding-right: 10px;
`;

export const PipeIcon = styled(FontAwesomeIcon)`
  padding: 0 8px;
  color: #777777;
`;

export const DriverCode = styled.div`
  margin-top: 6px;
  margin-left: 10px;

  .driver-code-text {
    font-size: 12px;
    font-weight: 600;
    font-family: "Roboto", sans-serifs;
    color: #161616;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: normal;
    width: 100%;
    word-wrap: break-word;
  }
`;

export const ToolbarButtonsContainer = styled.div`
  display: grid;
  align-items: center;
  column-gap: 8px;
  grid-template-columns: 1fr 1fr auto;
`;

export const WindowButtonsContainer = styled.div`
  display: flex;
  gap: 12px;
`;

export const ManifestToolbarButton = styled(Button)`
  width: 40px !important;
`;

export const ManifestToolbarActionButton = styled(Button)`
  height: 32px;
  min-width: 160px;
  padding: 7px 14px;

  background: #f8f8f8;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  font-family: "Roboto", serif;
  font-size: 16px;
  color: #161616;
`;

export const CloseManifestToolTip = styled(Tooltip2<any>)``;

export const ManifestCardItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 16px;
  align-items: center;
`;

export const GridContainer = styled.div`
  flex: 1;
  font-family: Roboto;
  .ag-side-bar .ag-side-buttons {
    display: none;
  }

  .manifest-detail-header-cell {
    color: #4b5563;
    padding: 0 12px;
  }

  .manifest-detail-body-cell {
    font-size: 14px;
    padding: 0 12px;
    color: #111827;
  }

  .zone-body-cell,
  .sequence-body-cell {
    font-size: 14px;
  }

  .color-header-cell {
    padding: 0 !important;
    width: 20px !important;
  }

  .color-body-cell {
    padding: 0 !important;
    width: 10px !important;
  }

  .drag-header-cell {
    padding: 0 !important;
    width: 10px !important;
  }

  .drag-body-cell {
    padding: 0 !important;
    width: 10px !important;
    transform: translateX(-10px);
    display: flex;
    justify-content: center;
    margin: auto;

    .ag-icon-grip {
      width: 9px;
      height: 15px;
      display: inline-block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      ::before {
        display: none;
      }
    }

    .bp4-popover2-target {
      width: 9px;
    }
  }

  .ag-header-cell-text {
    font-size: 12px;
    text-transform: uppercase;
  }

  .ag-drag-handle.ag-row-drag {
    margin: auto;
  }

  .ag-pinned-left-cols-container,
  .ag-pinned-left-header {
    border-right: none;
  }

  .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
    border: none;
  }
`;
interface StopsGridProps extends AgGridReactProps {
  isShowFloatFilter?: boolean;
}
export const StopsGrid = styled(AgGridReact<ManifestStopEnhanced>)<StopsGridProps>`
  ${(props) =>
    props.isShowFloatFilter &&
    css`
      // ditch the filter panel's search input control
      .ag-set-filter .ag-text-field-input {
        display: none;
      }
      // ditch the floating filter input control (displayed in row below header)
      .ag-floating-filter {
        display: flex;
        justify-content: center;
        .ag-floating-filter-body {
          display: none;
        }
        .ag-floating-filter-button {
          margin: auto;
        }
      }
    `}
`;

export const ManifestDateContainer = styled.span`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-self: center;
  text-align: right;
  color: #777777;
`;

export const ManifestDateContainerChangesPending = styled(ManifestDateContainer)`
  padding-right: 1em;
`;

export const SaveChangesContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
`;

export const SaveChangesButton = styled.button`
  border-radius: 4px;
  min-width: 148px;
  max-height: 32px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  background: linear-gradient(180deg, #214a89 0%, #14305a 100%);
  color: var(--white, #fff);
  font-size: 16px;
  padding: 6px 14px;
  gap: 7px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 1;

  cursor: pointer;
`;

export const CancelButton = styled(Button)`
  display: flex;
  height: 32px;
  align-items: center;
  flex-shrink: 0;
`;

export const CancelReassignmentButton = styled(Button)`
  color: rgba(20, 48, 90, 1);
  font-size: 16px;
`;

export const CancelButtonText = styled(Text)`
  color: #14305a;
  font-size: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ColorIndicatorDiv = styled.div`
  height: 48px;
  width: 4px;
  border-radius: 2px;
  background: ${(props) => props.color};
`;

export const StopTypeIconDiv = styled.div`
  display: flex;
  align-items: center;
  width: 16px;
  height: 16px;
`;

export const DetailButton = styled(Button)<{cutomMinimal?: boolean}>`
  border-radius: 6px;
  color: ${(props) => props.color ?? "#797979"} !important;
  padding: 0 4px;
  font-size: ${(props) => (props.cutomMinimal ? "14px" : "16px")};
  font-weight: ${(props) => (props.cutomMinimal ? "500" : "400")};
  width: ${(props) => (props.cutomMinimal ? "35px" : "unset")};
  display: flex;
  justify-content: space-between;

  * {
    margin-right: ${(props) => (props.cutomMinimal ? "0" : "7px")};
  }
`;

export const ClickHereButton = styled.span`
  font-weight: 500;
  color: #406aff;
  opacity: 0.9;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

export const ManifestDateAndConfigButton = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: flex-end;
`;
