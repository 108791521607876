import React, {KeyboardEvent, useCallback, useEffect, useRef, useState} from "react";
import {Manifest} from "../../../generated/graphql";
import styled from "@emotion/styled";
import userIcon from "../assets/user.svg";
import assignIcon from "../assets/assign.svg";
import groupIcon from "../assets/group.svg";
import {Icon, InputGroup, Spinner, Tag, Text} from "@blueprintjs/core";
import {EmptyValueStrings} from "../../common/Constants";
import {getStatusIcon, getStatusText} from "../../manifest/ManifestStatusSeletor";
import {ManifestStatusTypes} from "../../settings/ColorizedIndicators/types/manifestAttributes";
import {JobErrorMessage} from "../GlobalFind";

export type TOnAssigedJobFunction = (
  manifest: Manifest,
  jobNumber: string
) => Promise<{success: boolean; message: string}>;
interface IManifestItemProps {
  item: Manifest;
  isSelected: boolean;
  onSelect: (id: number) => void;
  onAssigedJob: TOnAssigedJobFunction;
}

const getStatusBackgroundColor = (manifestStatusType: string | undefined) => {
  switch (manifestStatusType) {
    case ManifestStatusTypes.Active:
      return "#D7F2DE";
    case ManifestStatusTypes.Future:
      return "#DBEAFE";
    case ManifestStatusTypes.Inactive:
      return "#F3F4F6";
    default:
      return "";
  }
};

const ManifestItem = ({item, isSelected, onSelect, onAssigedJob}: IManifestItemProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [assignedJobNumbers, setAssignedJobNumbers] = useState<string[]>([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [isAssigning, setIsAssigning] = useState(false);

  const onAssignClick = useCallback(async () => {
    const jobNumber = inputRef.current?.value.trim();
    if (jobNumber) {
      setErrorMsg("");
      setIsAssigning(true);
      const {success, message} = await onAssigedJob(item, jobNumber);
      if (success) {
        setAssignedJobNumbers((prev) => {
          if (!prev.includes(jobNumber)) {
            return [...prev, jobNumber];
          }
          return prev;
        });
        if (inputRef.current) inputRef.current.value = "";
      } else {
        setErrorMsg(message);
      }
      setIsAssigning(false);
    } else {
      setErrorMsg(JobErrorMessage.RequireInput);
    }
  }, [item, onAssigedJob]);

  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        onAssignClick();
      }
    },
    [onAssignClick]
  );

  const onInputChanged = useCallback(() => {
    if (errorMsg) setErrorMsg("");
  }, [errorMsg]);

  useEffect(() => {
    if (!isSelected) {
      setErrorMsg("");
    }
  }, [isSelected]);

  return (
    <Container
      key={item.manifestDriverId}
      data-testid="manifest-item"
      onClick={() => onSelect(item.manifestDriverId)}
      isSelected={isSelected}
    >
      <ManifestContainer>
        <Text ellipsize className="manifest-id">
          {item.manifestDriverId}
        </Text>
        <CenterDiv>
          <div className="manifest-status" style={{backgroundColor: getStatusBackgroundColor(item.manifestStatus)}}>
            {getStatusIcon(item.manifestStatus)}
            {getStatusText(item.manifestStatus, false)}
          </div>
        </CenterDiv>
        <div className="driver">
          <img src={userIcon} alt="user-icon" />
          <Text ellipsize>{item.driver.code}</Text>
          <Text className="driver-name" ellipsize>
            {item.driver.name ?? EmptyValueStrings.notProvided}
          </Text>
        </div>
        <div className="driver-group">
          <img src={groupIcon} alt="group-icon" /> <Text ellipsize>{item.dispatchGroup?.name}</Text>
        </div>
        {!isSelected && (
          <button className="assign-btn">
            <span>Assign</span>
            <img src={assignIcon} alt="assign-icon" />
          </button>
        )}
      </ManifestContainer>
      {isSelected && (
        <AssignContainer>
          <StyledInput
            data-testid="search-job-input"
            autoFocus
            onKeyDown={onKeyDown}
            placeholder="Job number"
            inputRef={inputRef}
            rightElement={
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              <button className="assign-btn" onClick={onAssignClick} data-testid="assign-button">
                {isAssigning && <Spinner size={12} />}
                <span>Assign</span>
                <img src={assignIcon} alt="assign-icon" />
              </button>
            }
            onChange={onInputChanged}
          />
          {errorMsg && <ErrorText data-testid="error-message">{errorMsg}</ErrorText>}
          {assignedJobNumbers.length > 0 && (
            <JobTagContainer>
              {assignedJobNumbers.map((item) => (
                <StyledTag data-testid="assigned-jobs" key={item} rightIcon={<Icon size={12} icon="tick" />}>
                  {item}
                </StyledTag>
              ))}
            </JobTagContainer>
          )}
        </AssignContainer>
      )}
    </Container>
  );
};

export default ManifestItem;

const Container = styled.li<{isSelected: boolean}>`
  width: 100%;
  padding: 16px;
  font-size: 14px;
  border-radius: 6px;
  cursor: pointer;
  background-color: ${(props) => (props.isSelected ? "#f3f4f6" : "")};

  .assign-btn {
    display: none;
    width: fit-content;
    height: 24px;
    padding: 4px 8px;
    border-radius: 8px;
    gap: 4px;
    color: #ffffff;
    background-color: #3b82f6;
    border: none;
    align-items: center;
    cursor: pointer;

    :hover {
      opacity: 0.8;
    }

    :active {
      opacity: 1;
    }

    .bp4-spinner .bp4-spinner-head {
      stroke: #ffffff;
    }
  }

  :hover {
    background-color: #f3f4f6;

    .assign-btn {
      display: flex;
    }
  }
`;

const ManifestContainer = styled.div`
  display: grid;
  grid-template-columns: 0.7fr 1.1fr 2fr 1.6fr 85px;
  gap: 12px;
  height: 28px;
  align-items: center;

  cursor: pointer;

  .manifest-id {
    font-weight: 500;
  }

  .manifest-status {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    border-radius: 24px;
    padding: 3px 8px;
  }

  .driver {
    display: grid;
    grid-template-columns: 16px minmax(0, 50px) auto;
    gap: 4px;
    align-items: center;

    .driver-name {
      color: #797979;
    }
  }

  .driver-group {
    display: grid;
    grid-template-columns: 16px auto;
    gap: 4px;
    align-items: center;
  }
`;

const AssignContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .assign-btn {
    display: flex !important;
  }
`;

const ErrorText = styled(Text)`
  color: #fa545e;
  font-size: 12px;
`;

const JobTagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const StyledTag = styled(Tag)`
  border-radius: 24px;
  height: 24px;
  color: #02751b;
  background: #d7f2de;
  padding: 4px 6px;
`;

const CenterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledInput = styled(InputGroup)`
  flex: 1;
  align-items: center;
  justify-content: center;

  .bp4-input {
    border: none;
    box-shadow: none;
    border-radius: 12px;
    padding: 8px;
    height: 40px;
  }
  .bp4-input-action {
    height: 100%;
    display: flex;
    align-items: center;
    right: 8px !important;
  }
`;
