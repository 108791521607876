import {Text} from "@blueprintjs/core";
import React, {useCallback} from "react";
import {ICellRendererParams} from "@ag-grid-community/core";
import styled from "@emotion/styled";

const LinkText = styled(Text)<{color?: string; fontSize?: number}>`
  width: fit-content;
  max-width: 100%;
  font-weight: 500;
  font-size: ${(props) => props.fontSize ?? "12"}px;
  color: ${(props) => props.color ?? "#1f2937"};
  cursor: pointer;
`;

export interface LinkCellRendererParams<T> extends ICellRendererParams<T, string> {
  minimal?: boolean;
  fontSize?: number;
  color?: string;
  onClick?: (data?: T) => void;
}

export const LinkCellRenderer = <T,>(props: LinkCellRendererParams<T>) => {
  const {fontSize, color, data, value, onClick} = props;

  const onClicked = useCallback(() => {
    return onClick?.(data);
  }, [onClick, data]);

  return (
    <LinkText data-testid="link-text" onClick={onClicked} fontSize={fontSize} color={color} ellipsize>
      {value}
    </LinkText>
  );
};
