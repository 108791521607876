import {JobStopStatus, jobStopStatusToString} from "../../../services/ManifestStopService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {duotone, solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Popover2, Tooltip2} from "@blueprintjs/popover2";
import {PopoverPosition} from "@blueprintjs/core";
import React from "react";
import {ICellRendererParams} from "@ag-grid-community/core";

const StopStatusRenderer = (props: ICellRendererParams) => {
  const {value: jobStopStatus} = props;
  // queued = hourglass, completed = checkmark, active = nothing
  let icon: JSX.Element = <div style={{visibility: "hidden"}}>{"Active"}</div>;
  // assume status is active...
  let tooltip: string = jobStopStatusToString(JobStopStatus.ACTIVE);

  if (jobStopStatus === JobStopStatus.QUEUED) {
    icon = (
      <FontAwesomeIcon
        data-testid={"job-stop-status-hourglass-icon"}
        size={"1x"}
        color={"#161616"}
        icon={duotone("hourglass-start")}
      />
    );
    tooltip = jobStopStatusToString(JobStopStatus.QUEUED);
  } else if (jobStopStatus === JobStopStatus.COMPLETED) {
    icon = (
      <FontAwesomeIcon data-testid={"job-stop-status-check-icon"} size={"1x"} color={"#161616"} icon={solid("check")} />
    );
    tooltip = jobStopStatusToString(JobStopStatus.COMPLETED);
  }
  return (
    <Popover2
      className=""
      placement="top"
      renderTarget={() => (
        <Tooltip2
          content={<div data-testid={"job-stop-status-tooltip-content"}>{tooltip}</div>}
          openOnTargetFocus={false}
          position={PopoverPosition.TOP}
          usePortal={true}
          intent={"primary"}
        >
          {icon}
        </Tooltip2>
      )}
    ></Popover2>
  );
};
export default StopStatusRenderer;
