import {ManifestStop, NestedJobStop, Stop} from "../generated/graphql";
import {isCompleted} from "../services/JobStopService";

function getStopTimeByStopType(stop: NestedJobStop | ManifestStop | Stop, stopType: string | null | undefined) {
  let stopTime;
  if (isCompleted(stop)) {
    const manifestStop = stop as ManifestStop;
    if (stopType === "D") {
      stopTime = manifestStop.podDateTime ?? manifestStop.arriveDateTime ?? stop.scheduledDateTime ?? stop.lateDateTime;
    } else {
      stopTime = manifestStop.arriveDateTime ?? stop.earlyDateTime ?? stop.scheduledDateTime;
    }
  } else if (stop.earlyDateTime && (stopType === "P" || stopType === "B")) {
    stopTime = stop.earlyDateTime;
  } else {
    stopTime = stop.scheduledDateTime;
  }
  return stopTime;
}

function getStopPreposition(stop: NestedJobStop | ManifestStop): string | null {
  return getStopPrepositionForType(stop, stop.stopType);
}

function getStopPrepositionForType(
  stop: NestedJobStop | ManifestStop,
  stopType: string | undefined | null
): string | null {
  if (isCompleted(stop)) {
    return null;
  }

  switch (stopType) {
    case "D":
      return "by";
    case "P":
    case "B":
      return "at";
  }

  return null;
}

export {getStopTimeByStopType as getStopTimeForType, getStopPreposition, getStopPrepositionForType};
